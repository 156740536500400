import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Image } from 'react-bootstrap';
import './EventCategories.css';

const API_URL = process.env.REACT_APP_API_URL;

function EventCities() {
    // State management
    const [cities, setCities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentCity, setCurrentCity] = useState({
        city_name: '',
        city_description: '',
        city_slug: '',
        banner_image: null,
        city_logo: null,
        lat: null,
        lng: null
    });
    const [isEditing, setIsEditing] = useState(false);
    const [previewUrls, setPreviewUrls] = useState({
        banner_image: null,
        city_logo: null
    });

    // Fetch cities
    const fetchCategories = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}/event/getAllEventCities`);
            setCities(response.data.data);
        } catch (error) {
            console.log(error);
            // alert(`Failed to fetch cities: ${error.response?.data?.message || 'An error occurred'}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Initialize fetch on component mount
    useEffect(() => {
        fetchCategories();
    }, []);

    // Handle input changes
    const generateSlug = (input) => {
        return input
            .toLowerCase()           // Convert to lowercase
            .trim()                  // Remove leading/trailing whitespace
            .replace(/[^\w\s-]/g, '') // Remove special characters except spaces and hyphens
            .replace(/[\s_]+/g, '-') // Replace spaces and underscores with a single hyphen
            .replace(/-+/g, '-')     // Replace multiple hyphens with a single hyphen
            .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'city_name'
            //  && !isEditing
        ) {
            // Automatically generate slug when editing category name
            const slug = generateSlug(value);

            setCurrentCity(prev => ({
                ...prev,
                city_name: value,
                city_slug: slug
            }));
        }
        else {
            // For other fields, use standard input handling
            setCurrentCity(prev => ({
                ...prev,
                [name]: name === 'city_slug' ? generateSlug(value) : value
            }));
        }
    };

    // Handle file input changes
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        setCurrentCity(prev => ({
            ...prev,
            [name]: file
        }));

        // Create preview URL
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrls(prev => ({
                    ...prev,
                    [name]: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    // Submit form (Create/Update)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Create FormData for file upload
        const formData = new FormData();
        Object.keys(currentCity).forEach(key => {
            if (currentCity[key] !== null) {
                formData.append(key, currentCity[key]);
            }
        });

        try {
            if (isEditing) {
                // Update existing category
                await axios.put(`${API_URL}/event/evetcity/${currentCity._id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                alert('City updated successfully');
            } else {
                // Create new category
                await axios.post(`${API_URL}/event/createEventCity`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                alert('City created successfully');
            }

            // Reset form and fetch updated list
            resetForm();
            fetchCategories();
            setIsModalOpen(false);
        } catch (error) {
            alert(isEditing
                ? `Failed to update city: ${error.response?.data?.message || 'An error occurred'}`
                : `Failed to create city: ${error.response?.data?.message || 'An error occurred'}`
            );
        } finally {
            setIsLoading(false);
        }
    };

    // Edit category
    const handleEdit = (category) => {
        setCurrentCity(category);
        setPreviewUrls({
            banner_image: `${API_URL}/eventcity/${category.banner_image}` || null,
            city_logo: `${API_URL}/eventcity/${category.city_logo}` || null
        });
        setIsEditing(true);
        setIsModalOpen(true);
    };

    // Delete category
    const handleDelete = async (slug) => {
        if (!window.confirm('Are you sure you want to delete this city?')) return;

        setIsLoading(true);
        try {
            await axios.delete(`${API_URL}/event/evetcity/${slug}`);
            alert('City deleted successfully');
            fetchCategories();
        } catch (error) {
            alert(`Failed to delete city: ${error.response?.data?.message || 'An error occurred'}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Reset form to initial state
    const resetForm = () => {
        setCurrentCity({
            city_name: '',
            city_description: '',
            city_slug: '',
            banner_image: null,
            city_logo: null,
            lat: null,
            lng: null
        });
        setPreviewUrls({
            banner_image: null,
            city_logo: null
        });
        setIsEditing(false);
    };

    // Open modal for new category
    const openNewCategoryModal = () => {
        resetForm();
        setIsModalOpen(true);
    };

    return (
        <div className='main-container'>
            <div className="container mt-4">
                <div className="row">
                    <   div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h3 className="mb-0">Event Cities</h3>
                                <Button variant="primary" onClick={openNewCategoryModal}>
                                    Add City
                                </Button>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Slug</th>
                                                <th>Description</th>
                                                <th>Banner Image</th>
                                                <th>City Logo</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cities.map(city => (
                                                <tr key={city.city_slug}>
                                                    <td>{city.city_name}</td>
                                                    <td>{city.city_slug}</td>
                                                    <td>{city.city_description}</td>
                                                    <td>
                                                        {city.banner_image && (
                                                            <Image
                                                                src={`${API_URL}/eventcity/${city.banner_image}`}
                                                                thumbnail
                                                                style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        {city.city_logo && (
                                                            <Image
                                                                src={`${API_URL}/eventcity/${city.city_logo}`}
                                                                thumbnail
                                                                style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="btn-group" role="group">
                                                            <Button
                                                                variant="warning"
                                                                size="sm"
                                                                onClick={() => handleEdit(city)}
                                                            >
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                size="sm"
                                                                onClick={() => handleDelete(city.city_slug)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Right-side Modal */}
                        <Modal
                            show={isModalOpen}
                            onHide={() => setIsModalOpen(false)}
                        // dialogClassName="modal-right"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {isEditing ? 'Edit Category' : 'Create New Category'}
                                </Modal.Title>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Group className="mb-3">
                                        <Form.Label>City Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="city_name"
                                            value={currentCity.city_name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>City Slug</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="city_slug"
                                            value={currentCity.city_slug}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Latitude</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lat"
                                            value={currentCity.lat}
                                            onChange={handleInputChange}

                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Longitude</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lng"
                                            value={currentCity.lng}
                                            onChange={handleInputChange}

                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="city_description"
                                            value={currentCity.city_description}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Banner Image</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="banner_image"
                                            onChange={handleFileChange}
                                            accept="image/*"
                                        />
                                        {previewUrls.banner_image && (
                                            <img
                                                src={previewUrls.banner_image}
                                                alt="Banner Preview"
                                                className="img-thumbnail mt-2"
                                                style={{ maxHeight: '200px' }}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>City Logo</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="city_logo"
                                            onChange={handleFileChange}
                                            accept="image/*"
                                        />
                                        {previewUrls.city_logo && (
                                            <img
                                                src={previewUrls.city_logo}
                                                alt="Logo Preview"
                                                className="img-thumbnail mt-2"
                                                style={{ maxHeight: '200px' }}
                                            />
                                        )}
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                                        Close
                                    </Button>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        {isEditing ? 'Update City' : 'Create City'}
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        {/* Loading Overlay */}
                        {isLoading && (
                            <div className="loading-overlay">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventCities;