import React from "react";

function EventAgenta({ data }) {
    let dataList = data.agendaItems;

    return (
        <div style={{
            backgroundColor: "transparent",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "none",
            maxWidth: "600px",
            margin: "0 auto", // Centering the container
            color: "white"
        }}>
            <h3 style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#fff",
                marginBottom: "10px"
            }}>
                {data.headding}
            </h3>

            <p style={{
                fontSize: "16px",
                fontWeight: "normal",
                color: "white",
                margin: "0",
            }}>
                <strong style={{ color: "#007BFF" }}>{dataList.time}</strong> -
                <div dangerouslySetInnerHTML={{ __html: dataList.description }} />
            </p>
        </div>
    );
}

export default EventAgenta;
