import React,{ useContext, useEffect, useState } from 'react'
import { MdLocalOffer } from 'react-icons/md';
import { tagContext } from '../../contexts/context/tagContext';



const RectangularTag = ({tag_id}) => {
    const { tagData } = useContext(tagContext);

    const [tagName,setTagName]=useState("")
    const [tagColor,setTagColor]=useState("blue")

    useEffect(()=>{

        let name = tagData.tags.find((tag)=>tag._id === tag_id)?.tag_name;
        let color = tagData.tags.find((tag)=>tag._id === tag_id)?.tag_color;

        // console.log("the colorwa",color)

        setTagName(name)
        setTagColor(color)


        



    }, [tagData,tag_id])

    return (
        <div
            style={{
                position: 'absolute',
                top: '-30px',
                left: '-10px',
                backgroundColor: `${tagColor}`,
                color: 'white',
                padding: '5px 10px',
                borderRadius: '0 4px 4px 0',
                display: 'flex',
                alignItems: 'center',
                zIndex: 10,
                boxShadow: '2px 2px 5px rgba(0,0,0,0.2)'
            }}
        >
            <MdLocalOffer style={{ marginRight: '5px' }} />
            {tagName}
        </div>
    )
}

export default RectangularTag