import React, { createContext, useContext, useState } from "react";

const BuilderContext = createContext();

export function BuilderContextProvider({ children }) {
  const [myComponents, setMyComponents] = useState([]);

  const [smartlinkeditdata, setSmartlinkEditData] = useState();
  const [qrdata, setQrData] = useState('');

  const [brand_Id, setBrand_Id] = useState('');

  const [templateId, setTemplateId] = useState('');

  const [color, setColor] = useState('color_beige');
  const [buttonColor, setButtonColor] = useState('28c76f');
  const [fontFamily, setFontFamily] = useState('Roboto, sans-serif');
  const [addBgclass, setBgclass] = useState('');

  const [smartlink_url, setSmartlink_url] = useState('');
  const [qrImage, setQrImage] = useState('');

  const [backgroundType, setBackgroundType] = useState("1");
  const [BackgroundColor, setBackgroundColor] = useState('#3713ed');
  const [backgroundColor2, setBackgroundColor2] = useState('#f01a45');
  const [colorPosition, setColorPosition] = useState('leftright');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imagePosition, setImagePosition] = useState('cover');
  const [singleImagePosition, setSingleImagePosition] = useState('cover');
  const [company_slug,setCompain_slug] = useState('');
  const [brand_name,setBrand_name] = useState('');
  const [brand_img,setBrand_img] = useState(null);
  const [showPoweredBy, setShowPoweredBy] = useState(true);

  const changePoweredStatus = () => {
    setShowPoweredBy(!showPoweredBy)
  }

  const addComponent = (type, properties,selectedValue) => {
    setMyComponents((myComponents) => [...myComponents, { type: type, properties: properties, showStatus: true,selectedValue:selectedValue }]);
  };

  const removeComponent = (id) => {
    const index = myComponents.filter((c, ind) => ind != id);
    setMyComponents(index);
  };

  const hideShow = (index, status) => {

    const updatedComponents = [...myComponents];

    updatedComponents[index].showStatus = status;

    setMyComponents(updatedComponents);

  };



  const rearrangeComponents = (startIndex, endIndex) => {
    const componentsCopy = [...myComponents];
    const [removedComponent] = componentsCopy.splice(startIndex, 1);
    componentsCopy.splice(endIndex, 0, removedComponent);
    setMyComponents(componentsCopy);
  };

  const editComponent = (index, type, properties, status,selectedValue) => {

    console.log("previous data", myComponents[index]);

    setMyComponents((myComponents) => {
      const updatedComponents = [...myComponents];
      updatedComponents[index] = { type: type, properties: properties, showStatus: status,selectedValue:selectedValue };

      console.log("updated data", updatedComponents[index]);


      return updatedComponents;
    });

  };

  const setMinipage = (data) => {
    setMyComponents(data);
  };



  return (
    <BuilderContext.Provider value={{
      addComponent, myComponents, editComponent, removeComponent, hideShow, rearrangeComponents, setMinipage, smartlinkeditdata,
      setSmartlinkEditData, qrdata, setQrData,
      color, setColor, buttonColor, setButtonColor, fontFamily, setFontFamily, addBgclass, setBgclass, smartlink_url, setSmartlink_url,
      qrImage, setQrImage, brand_Id, setBrand_Id, templateId, setTemplateId, backgroundType, setBackgroundType, BackgroundColor, setBackgroundColor, backgroundColor2, setBackgroundColor2, colorPosition, setColorPosition,
      title, setTitle, setDescription, description, imagePosition, setImagePosition, company_slug, setCompain_slug, singleImagePosition, setSingleImagePosition,
      setShowPoweredBy, showPoweredBy, changePoweredStatus,
      brand_name, setBrand_name, brand_img, setBrand_img
    }}>
      {children}
    </BuilderContext.Provider>
  );
}

export function useBuilderContext() {
  return useContext(BuilderContext);
}
