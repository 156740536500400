import { useState, useEffect } from "react";

const useLocationInfo = () => {
    const [location, setLocation] = useState({
        city: null,
        state: null,
        error: null,
    });

    const getCityAndState = () => {
        if (!navigator.geolocation) {
            setLocation((prev) => ({
                ...prev,
                error: "Geolocation is not supported by this browser.",
            }));
            return;
        }

        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;

                try {
                    const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;
                    const response = await fetch(url);
                    const data = await response.json();
                    const address = data.address || {};
                    const city = address.city || address.town || address.village || null;
                    const state = address.state || null;

                    setLocation({ city, state, error: null });
                } catch (error) {
                    setLocation((prev) => ({
                        ...prev,
                        error: "Error fetching location information.",
                    }));
                    console.error("Error fetching location information:", error);
                }
            },
            (error) => {
                setLocation((prev) => ({
                    ...prev,
                    error: "Unable to retrieve your location.",
                }));
                console.error("Geolocation error:", error);
            }
        );
    };

    useEffect(() => {
        getCityAndState();
    }, []);

    return location;
};

export default useLocationInfo;
