import React from 'react'
import { useContext } from 'react';
import { scheduledPostsContext } from "../context/scheduledPostsContext";
import { brandSelectionContext } from "../../contexts/context/brandSelectionContext";

const API_URL = process.env.REACT_APP_API_URL;


const useScheduleDataActions = () => {
    const { sidebarBrands } = useContext(brandSelectionContext);

    const { scheduledPostsdispatch } = useContext(scheduledPostsContext);

    // Your logic here...
    const user = JSON.parse(localStorage.getItem("user"))
    const ownerid = user.owner_id;
    const createdby = user._id;

    const getscheduleddata = async () => {

        try {
            const data = {
                owner_id: ownerid,
                created_by: createdby,
                brand_id: sidebarBrands.selected
            }

            const response = await fetch(`${API_URL}/socialposts/getscheduledposts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();

            if (result.status) {

                console.log("scheduled posts data", result.data);

                scheduledPostsdispatch({ type: "SET_SCHEDULED_POSTS", payload: result.data })

            }

        }
        catch (err) {
            console.log(err);
        }

        // setscheduledfacebookdata(result.data.data);
    }

    const getfbscheduleddata = async (payload) => {

        const data = {
            page_id: payload.page_id,
            owner_id: user.owner_id,

        }

        const response = await fetch(`${API_URL}/socialposts/getfbscheduleposts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        if (result.status) {


            return {
                status: true,
                data: result.data
            }
        }

    }

    const getScheduledPostsByBrand = async (brand_id) => {

        try {
            const data = {
                brand_id: brand_id
            }

            const response = await fetch(`${API_URL}/socialposts/getscheduledposts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();

            if (result.status) {

                console.log("scheduled posts data", result.data);

                scheduledPostsdispatch({ type: "SET_SCHEDULED_POSTS", payload: result.data })

            }

        }
        catch (err) {
            console.log(err);
        }

    }


    return { getfbscheduleddata, getscheduleddata, getScheduledPostsByBrand }; // Return an object with the functions you want to expose
};

export default useScheduleDataActions;

