import { Form } from "react-bootstrap";
import { useBuilderContext } from "../../builderContext";
import { useEffect, useState } from "react";
import { AdvancedMotionGraphicsBackground, AdvancedMotionGraphicsBackgroundspam, AnimatedBackground, DynamicBackground, GalaxySpiral, GlitterBackground, ProfessionalMotionBackground, TailStarsAnimation } from "../Components/demo";
import "./../Components/demo.css"
const Apperance = ({ }) => {

    const { setBackgroundColor, BackgroundColor, setColor, color, setFontFamily, } = useBuilderContext();


    const changeFontFamily = (value) => {
        setFontFamily(value);
    };
    useEffect(() => {
        if (color) {
            setColor("transparent");
        }
        if (BackgroundColor == undefined || BackgroundColor == null) {
            setBackgroundColor("GalaxySpiral");
        }

    }, [color, BackgroundColor]);

    const backgroundOptions = [
        { name: "AnimatedBackground", component: <AnimatedBackground /> },
        {
            name: "AdvancedMotionGraphicsBackground",
            component: <AdvancedMotionGraphicsBackground particleCount={75} backgroundColor="#0f172a" colorPalette={['#3b82f6', '#10b981', '#f43f5e']} />
        },
        { name: "GlitterBackground", component: <GlitterBackground /> },
        { name: "ProfessionalMotionBackground", component: <ProfessionalMotionBackground /> },
        { name: "AdvancedMotionGraphicsBackgroundspam", component: <AdvancedMotionGraphicsBackgroundspam /> },
        { name: "DynamicBackground", component: <DynamicBackground /> },
        { name: "TailStarsAnimation", component: <TailStarsAnimation /> },
        { name: "GalaxySpiral", component: <GalaxySpiral /> },
    ];

    return (
        <>
            <div class="styles__TabContent themes_sec">
                <div className="styles__TabContent__Header themes_sec_header">
                    <h3>Themes</h3>
                </div>
                <div className="templates_list" >
                    <div className="theme-gallery-event" >
                        {backgroundOptions.map((option, index) => (
                            <div role="button"
                                key={index}
                                className="templates_list_item"
                                style={{
                                    position: "relative",
                                    width: "80px",
                                    height: "100px",
                                    border: "2px solid #ddd",
                                    borderRadius: "10px",
                                    backgroundColor: "#222",
                                    overflow: "hidden",
                                    cursor: "pointer",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onClick={() => setBackgroundColor(option.name)}
                            >
                                {option.component}
                            </div>
                        ))}
                    </div>
                </div>




                <div className="font_family_content">
                    <div class="section-inner-title">
                        <h3>Font Packs</h3>
                    </div>
                    <div className="select-font">
                        <div className="select-font-list">
                            <div className="select-font-list-item">
                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Roboto, sans-serif' }}
                                    onClick={() => {
                                        changeFontFamily('Roboto, sans-serif');
                                    }
                                    }
                                >
                                    <div className="select-font-list-item-inner-text">
                                        <p className="select-font-list-item-inner-text-title">Roboto</p>
                                    </div>
                                </button>
                            </div>
                            <div className="select-font-list-item">
                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Playfair Display, serif' }}
                                    onClick={() => {
                                        changeFontFamily('Playfair Display, serif');
                                    }
                                    }
                                >
                                    <div className="select-font-list-item-inner-text">
                                        <p className="select-font-list-item-inner-text-title">Playfair Display</p>
                                    </div>
                                </button>
                            </div>
                            <div className="select-font-list-item">
                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Oswald, sans-serif' }}
                                    onClick={() => {
                                        changeFontFamily('Oswald, sans-serif');
                                    }
                                    }
                                >
                                    <div className="select-font-list-item-inner-text">
                                        <p className="select-font-list-item-inner-text-title">Oswald</p>
                                    </div>
                                </button>
                            </div>
                            <div className="select-font-list-item">
                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Libre Baskerville, serif' }}
                                    onClick={() => {
                                        changeFontFamily('Libre Baskerville, serif');
                                    }
                                    }
                                >
                                    <div className="select-font-list-item-inner-text">
                                        <p className="select-font-list-item-inner-text-title">Libre Baskerville</p>
                                    </div>
                                </button>
                            </div>
                            <div className="select-font-list-item">
                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Lugrasimo, cursive' }}
                                    onClick={() => {
                                        changeFontFamily('Lugrasimo, cursive');
                                    }
                                    }
                                >
                                    <div className="select-font-list-item-inner-text">
                                        <p className="select-font-list-item-inner-text-title">Lugrasimo</p>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default Apperance;   