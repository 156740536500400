import React, { useEffect, useState } from 'react';
import "./Ai.css";
import { TbSmartHome } from "react-icons/tb";
import Loader2 from '../../Components/Loader/Loader2';
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { API_URL } from "../../BackendApi/Api/config";

function AiPreamble() {

    const [loading, setLoading] = useState(true);
    const [ai_preamble, setAi_preamble] = useState(""); // Directly use ai_preamble for textValue
    const [preambleData, setAi_preambleData] = useState([]);

    useEffect(() => {
        setLoading(false); // Set loading to false after the component has loaded
        getAiPreamble();
    }, []);

    const updatePreamble = async (item) => {
        try {
            const result = await fetch(`${API_URL}user/AiPreambleUpdate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    AiPreamble_id: item._id,
                    ai_preamble: ai_preamble // Directly use ai_preamble state
                })
            });
            const res = await result.json();
            if (res.status) {
                PNotify.success({
                    title: 'Success',
                    text: "Preamble updated successfully",
                });
                getAiPreamble()
            } else {
                PNotify.error({
                    title: 'Oh No!',
                    text: res.data.message,
                });
                console.log('Failed to update preamble');
            }
        } catch (error) {
            PNotify.error({
                title: 'Oh No!',
                text: error.message,
            });
        }
    };

    const getAiPreamble = async () => {
        try {
            const result = await fetch(`${API_URL}user/getAiPreamble`);
            const res = await result.json();
            if (res.status) {
                setAi_preambleData(res.data);
            } else {
                PNotify.error({
                    title: 'Oh No!',
                    text: res.data.message,
                });
                console.log('Failed to fetch preamble data');
            }
        } catch (error) {
            PNotify.error({
                title: 'Oh No!',
                text: error.message,
            });
        }
    };

    const handleTextChange = (e) => {
        setAi_preamble(e.target.value); // Update ai_preamble state
    };

    return (
        <div>
            {loading ? <Loader2 /> : null}
            <div className="main-container">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container" style={{ marginLeft: 100 }}>
                            <div className="preamble-title">AI Preamble</div>
                            {Array.isArray(preambleData) && preambleData.map((item, index) => (
                                <div key={index}>
                                    <div className="text-area-container">
                                        <textarea
                                            value={ai_preamble === "" ? item.ai_preamble : ai_preamble} // Show current ai_preamble state or preamble data
                                            onChange={handleTextChange}
                                            placeholder="Type here..."
                                            rows="30"
                                            cols="130"
                                            className="text-area"
                                        />
                                    </div>
                                    <button onClick={() => updatePreamble(item)} className="update-button">
                                        Update Preamble
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AiPreamble;
