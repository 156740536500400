import React from "react";
import { Form } from "react-bootstrap";
// import "./AnalyticsForm.css"; // Optional for custom styling

const AnalyticsForm = () => {
    return (
        <div className="styles__TabContent themes_sec" style={{ margin: "0px 20px" }}>
            <div className="content-body-form">
                <Form.Group
                    className="did-floating-label-content"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Control
                        className="did-floating-input"
                        type="text"
                        value=""
                        placeholder=" "
                    />
                    <Form.Label className="did-floating-label">
                        Google Analytics
                    </Form.Label>
                </Form.Group>

                <Form.Group
                    className="did-floating-label-content"
                    controlId="exampleForm.ControlInput2"
                >
                    <Form.Control
                        className="did-floating-input"
                        type="text"
                        value=""
                        placeholder=" "
                    />
                    <Form.Label className="did-floating-label">
                        Google Tag Manager
                    </Form.Label>
                </Form.Group>

                <Form.Group
                    className="did-floating-label-content"
                    controlId="exampleForm.ControlInput3"
                >
                    <Form.Control
                        className="did-floating-input"
                        type="text"
                        value=""
                        placeholder=" "
                    />
                    <Form.Label className="did-floating-label">
                        Meta Pixel ID (Facebook)
                    </Form.Label>
                </Form.Group>

                <Form.Group
                    className="did-floating-label-content"
                    controlId="exampleForm.ControlInput4"
                >
                    <Form.Control
                        className="did-floating-input"
                        type="text"
                        value=""
                        placeholder=" "
                    />
                    <Form.Label className="did-floating-label">
                        AdRoll Pixel ID
                    </Form.Label>
                </Form.Group>
            </div>
        </div>
    );
};

export default AnalyticsForm;
