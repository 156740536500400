
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../BackendApi/Api/config";
import "./../../../pages/PublicFlexpages/flexComponents/ButtonCustomizer.css";
import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";

function BuilderButton(props) {
    const {
        buttonShape,
        buttonStyle,
        backgroundColor,
        size,
        thumbnailPosition,
        thumbnail,
        label,
        jumpToAnchor,
        link,
        emojis,
        hoverAnimation,
        animation,
        subtext
    } = props.data;

    const [isShaking, setIsShaking] = useState(false);
    console.log("animation", hoverAnimation);
    // Shake every 5 seconds if 'shake' is selected
    useEffect(() => {
        let interval;
        if (animation === 'shake') {
            interval = setInterval(() => {
                setIsShaking(true);
                setTimeout(() => setIsShaking(false), 500);
            }, 5000);
        }
        return () => clearInterval(interval);
    }, [animation]);

    const hoverClass =
        hoverAnimation === "fill-empty" ? "animation-fill-empty" :
            hoverAnimation === "grow-shrink" ? "animation-grow-shrink" :
                hoverAnimation === "shake" ? "animation-shaking" : "";

    const animationClass =
        animation === "pulsing" ? "animation-pulsing" :
            animation === "shake" && isShaking ? "animation-shaking" : "";

    const handleAnchorClick = (e, targetId) => {
        e.preventDefault();
        if (!targetId) return;

        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const saveClicks = (smartlink_id, unique_id) => {
        saveComponentsClicks({
            unique_id: unique_id,
            smartlink_id: smartlink_id,

        });
    }

    return (
        <div className="button_content_box">
            <a href={link} style={{ textDecoration: "none", color: "black" }}>
                <div className={`button-preview ${hoverClass} ${animationClass}`}
                    onClick={() => { saveClicks(props.smartlink_id, props.data.unique_id) }}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: buttonShape === "rounded" ? "30px" : buttonShape === "curvedRectangle" ? "20px" : "10px",
                        flexDirection: thumbnailPosition === "right" ? "row" : "row-reverse",
                        backgroundColor: backgroundColor,
                        padding: "10px 10px",
                        width: "100%",
                        height: "100%",
                        border: buttonStyle === "outline" ? "1px solid #ddd" : "none",
                        boxShadow: buttonStyle === "shadow" ? "0px 4px 58px rgba(0,0,0,0.1)" : "none"
                    }}
                >
                    <div style={{ width: "100%", alignItems: "center", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <div>
                            <p className="btn-text" style={{ textAlign: "center", fontSize: 20 }}>
                                {label}
                            </p>
                            <p
                                className="btn-text"
                                style={{
                                    textAlign: "center",
                                    fontSize: size === "standard" ? '14px' : '18px',
                                    marginTop: size === "standard" ? '-10px' : '5px',
                                    fontWeight: size === "standard" ? 'normal' : 'bold',
                                    lineHeight: size === "standard" ? '1.2' : '1.5'
                                }}
                            >
                                {subtext}
                            </p>
                        </div>
                        {jumpToAnchor?.type && (
                            <p className="btn-text" style={{ textAlign: "center", marginTop: 16, marginLeft: 10 }}
                                onClick={(e) => handleAnchorClick(e, jumpToAnchor.type === "TESTIMONIAL" ? jumpToAnchor.properties[0].unique_id : jumpToAnchor.properties.unique_id)}
                            >
                                {jumpToAnchor.type}
                            </p>
                        )}
                    </div>

                    {!emojis ? (
                        <img
                            src={thumbnail?.startsWith("http://") || thumbnail?.startsWith("https://") ? thumbnail : API_URL + "flowpage/" + thumbnail}
                            alt={label}
                            style={{
                                width: size === "large" ? "60px" : "40px",
                                height: size === "large" ? "60px" : "40px",
                                borderRadius: buttonShape === "rounded" ? "50%" : buttonShape === "curvedRectangle" ? "20px" : "10px",
                                resizeMode: "cover",
                                marginRight: thumbnailPosition === "left" ? "8px" : "0px",
                                marginLeft: thumbnailPosition === "right" ? "8px" : "0px"
                            }}
                        />
                    ) : (
                        <div style={{
                            borderRadius: buttonShape === "rounded" ? "50%" : buttonShape === "curvedRectangle" ? "20px" : "10px",
                            marginRight: thumbnailPosition === "left" ? "8px" : "0px",
                            marginLeft: thumbnailPosition === "right" ? "8px" : "0px",
                            border: buttonStyle === "outline" ? "1px solid #ddd" : "none",
                            backgroundColor: "transparent",
                            padding: '0px 0px'
                        }}>
                            <span style={{
                                fontSize: size === "large" ? "40px" : "30px",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {emojis}
                            </span>
                        </div>
                    )}
                </div>
            </a>
        </div>
    );
}

export default BuilderButton;
