import React from 'react'

function EventsNearbyMapNav() {
    return (
        <div className="sticky-top">
            <nav className="navbar navbar-expand-lg sticky-top" style={{ backdropFilter: 'blur(16px)', color: 'hsla(0, 0%, 100%, .5)' }}>
                <div style={{ background: 'linear-gradient(rgba(93, 225, 255, 0.2) 0%, rgba(92, 255, 144, 0.1) 50%, rgba(194, 255, 95, 0) 100%);', position: 'fixed', pointerEvents: 'none', opacity: '1', top: '0', left: '0', width: '100%', height: '180px', zIndex: '1' }}></div>
                <div className="container-fluid">
                    <div className="d-flex text-white" id="navbarNav">
                        <div className="d-flex me-2" style={{ width:'24px', height:'24px', borderRadius:'3px', overflow:'hidden' }}>
                            <img src="https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=%23a89925,quality=75,width=30,height=30/discovery/bangalore-icon.png" alt="" />
                        </div>
                        Bengaluru
                    </div>
                    <a className="navbar-brand light-fcolor" href="#">
                        MyBrand
                    </a>
                </div>
            </nav>
        </div>
    )
}

export default EventsNearbyMapNav