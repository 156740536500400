import React, { useState } from "react";
import { API_URL } from "../../../BackendApi/Api/config";

const EventsGalleryList = (props) => {
    const images = props.data?.imagefile || [];
    const videos = props.data?.videoFile || [];
    const [isPlaying, setIsPlaying] = useState(false);

    // Toggle play/pause on video click
    const togglePlayPause = (videoId) => {
        const videoElement = document.getElementById(videoId);
        if (videoElement.paused) {
            videoElement.play();
        } else {
            videoElement.pause();
        }
    };

    return (
        <div className="mb-3" id={props.data.unique_id}>
            {/* Render both images and videos */}
            <div>
                <div
                    style={{
                        display: "flex",
                        margin: "20px",
                        flexWrap: "wrap",
                        gap: "1px",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                    }}
                >
                    {/* Render images */}
                    {images.length > 0 && images.map((imageData, index) => (
                        <div key={index} style={{ margin: "5px" }}>
                            <img
                                src={
                                    imageData?.startsWith("http://") ||
                                        imageData?.startsWith("https://")
                                        ? imageData
                                        : `${API_URL}flowpage/${imageData}`
                                }
                                alt={`Uploaded image ${index + 1}`}
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                }}
                            />
                        </div>
                    ))}

                    {/* Render videos */}
                    {videos.length > 0 && videos.map((videoData, index) => {
                        const videoId = `videoPlayer-${props.data.unique_id}-${index}`;
                        return (
                            <div key={index} style={{ margin: "5px" }}>
                                <video
                                    autoPlay
                                    muted
                                    id={videoId}
                                    crossOrigin="anonymous"
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                        borderRadius: "8px",
                                    }}
                                    controls
                                    onClick={() => togglePlayPause(videoId)} // This toggles play/pause on click
                                    onPlay={() => setIsPlaying(true)}
                                    onPause={() => setIsPlaying(false)}
                                >
                                    <source
                                        src={
                                            videoData?.startsWith("http://") ||
                                                videoData?.startsWith("https://")
                                                ? videoData
                                                : `${API_URL}flowpage/${videoData}`
                                        }
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default EventsGalleryList;
