import React, { useContext, useState, useRef, useEffect } from "react";
import QRCodeStyling from 'qr-code-styling';
import { Button } from "react-bootstrap";
import "./SmartLinks.css";
import 'react-notifications/lib/notifications.css';
import { Modal, Form } from "react-bootstrap";
import { TbChartBar, TbComponents, TbTrash, TbEdit, TbHandClick } from "react-icons/tb";
import { BsCheckCircleFill, BsXCircleFill, BsLink45Deg, BsFillImageFill } from "react-icons/bs";
import { MdOutlineCalendarToday, MdLockOutline, MdLocalOffer } from "react-icons/md";
import { FiCopy } from "react-icons/fi";
import { FaSearch } from "react-icons/fa"
import { Link } from "react-router-dom";
import EditQr from "../Components/EditQr/EditQr";
import Header from "../Components/Header/Header";
import "./Mydomain.css";
import { addsmartlink, getsmartLinksVisits } from "../BackendApi/Api/smartsLinkApi";
import { nanoid } from 'nanoid';
import "./SmartLinks.css";
import { getsmartlink, verifybackhalf } from "../BackendApi/Api/smartsLinkApi";
import Urlimg from "../assets/images/url.png";
import { API_URL } from "../BackendApi/Api/config";
import { getSmartlinkApi } from "../BackendApi/Api/smartsLinkApi";
import { SITE_URL } from "../BackendApi/Api/config";
import { deletesmartlinkApi } from "../BackendApi/Api/smartsLinkApi";
import { getsession } from "../BackendApi/Api/sessionApi";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { sessionContext } from "../sessionContext";
import { toJpeg } from 'html-to-image';
import { getMetaApi } from "../BackendApi/Api/metaApi";
import { Tooltip } from 'react-tooltip'
import { getDomainDataApi } from "../BackendApi/Api/domainApi";
import { TRACKING_URL } from '../BackendApi/Api/config.js';
import { downloadImage } from "../Helpers/imageDownload";
import { brandSmartCampApi } from "../BackendApi/Api/brandApi";
import Loader from "../Components/Loader/Loader";
import { handleCopy } from "../Helpers/copyHelper";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import Loader2 from "../Components/Loader/Loader2";
import "../assets/Css/custom.css"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyCat from "../Components/CopyCat/CopyCat";

import { UserContext } from "../UserContext.js";
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from "react-router-dom";
import { brandSelectionContext } from "../contexts/context/brandSelectionContext.jsx";
import { flexLinkContext } from "../contexts/context/flexLinkContext.jsx";
import { all } from "axios";
import SpinnerLoader from "../Components/SpinnerLoader/SpinnerLoader.jsx";
import { SubscribsationBackhalfValid } from "../Helpers/SubscribsationBackhalfValid";
import { tagContext } from "../contexts/context/tagContext.jsx";
import Dropdown from 'react-bootstrap/Dropdown';
import { Tags } from "lucide-react";
import RectangularTag from "../Components/Tags/RectangularTag.jsx";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const DEFAULT_FRONTHALF = process.env.REACT_APP_API_URL;


function Qrtemplates() {

    const history = useHistory();
    const { sidebarBrands } = useContext(brandSelectionContext);
    const { flexLinkdispatch } = useContext(flexLinkContext);
    const { subscriptionStatus, subscriptionDetails } = useContext(UserContext);
    const { tagData } = useContext(tagContext);

    const [tags, setTags] = useState([]);

    const [BrandId, setBrandId] = useState("");


    const [selectedTag, setSelectedTag] = useState("");

    const [selectedBrandOwnerTags, setSelectedBrandOwnerTags] = useState([]);

    useEffect(() => {

        if (tagData.tags && tagData.tags.length > 0) {

            if (sidebarBrands.selected !== "all") {

                const brandOwnerId = sidebarBrands.brands.find((brand) => brand._id == sidebarBrands.selected).owner_id;
                console.log("de silva wala brandOwnerId: ", brandOwnerId);

                const filteredTags = tagData.tags.filter((tag) => tag.owner_id === brandOwnerId);

                setTags(filteredTags);

            }
            else {

                setTags(tagData.tags);
            }

        }

    }, [sidebarBrands, tagData]);


    useEffect(() => {

        if (BrandId !== "" && tagData.tags.length > 0) {

            // console.log("mahaverwa wala BrandId:", BrandId, tagData.tags.length,sidebarBrands.brands.length);



            const brandOwnerId = sidebarBrands.brands.find((brand) => brand._id == BrandId).owner_id;
            // console.log("mahaverwa wala brandOwnerId: ", brandOwnerId);
            // console.log("mahaverwa wala BrandId:", BrandId, tagData.tags.length,sidebarBrands.brands.length,brandOwnerId);


            const filteredTags = tagData.tags.filter((tag) => tag.owner_id === brandOwnerId);
            setSelectedTag("");

            setSelectedBrandOwnerTags(filteredTags);

        }

    }, [tagData, BrandId])





    const userdata = JSON.parse(localStorage.getItem("user"));

    const role = userdata.role;


    const { sessionName, setSessionName, sessionDescription, setSessionDescription, SessionData, setSessionData, godZilla, setGodZilla } = useContext(sessionContext);
    const qrCodeRef = useRef(null);
    const divRef = useRef(null);

    const [backhalfVerfied, setBackhalfVerfied] = useState(false);

    const [ImageName, setImageName] = useState("")

    const [brandData, setBrandData] = useState([]);
    const [templateData, setTemplateData] = useState([]);

    const [show, setShow] = useState(false);

    const defaultimage = "default.png";

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show03, setShow03] = useState(false);

    const handleClose03 = () => { setShow03(false); }
    const handleShow03 = () => setShow03(true);

    const [show04, setShow04] = useState(false);

    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);

    const [show05, setShow05] = useState(false);

    const handleClose05 = () => setShow05(false);
    const handleShow05 = () => setShow05(true);


    const [show06, setShow06] = useState(false);

    const handleClose06 = () => setShow06(false);
    const handleShow06 = () => setShow06(true);


    const [showClicksScan, setShowClicksScan] = useState({});
    const [currentSmartlinkId, setCurrentSmartlinkId] = useState("");
    const [templateImage, settemplateImage] = useState("");

    const [templateImagefront, settemplateImageFront] = useState("");

    const [campaignName, setCampaignName] = useState("");
    const [campaignDescription, setCampaignDescription] = useState("");
    const [selectTemp, setSelectTemp] = useState("");
    const [brandsOwner_id, setBrandsOwner_id] = useState("");
    const [uniqueId, setUniqueId] = useState('123');
    const [frontHalf, setFrontHalf] = useState(DEFAULT_FRONTHALF);
    const [loading, setLoading] = useState(false);

    const [destinationType, setDestinationType] = useState("url");

    const defaultqrjson = {
        "width": 300,
        "height": 300,
        "data": frontHalf + "/" + uniqueId + "?method=scan",
        "margin": 0,
        "qrOptions": {
            "typeNumber": "0",
            "mode": "Byte",
            "errorCorrectionLevel": "Q"
        },
        "imageOptions": {
            "hideBackgroundDots": true,
            "imageSize": 0.4,
            "margin": 0
        },
        "dotsOptions": {
            "type": "extra-rounded",
            "color": "#6a1a4c"
        },
        "backgroundOptions": {
            "color": "#ffffff"
        },
        "image": null,
        "dotsOptionsHelper": {
            "colorType": {
                "single": true,
                "gradient": false
            },
            "gradient": {
                "linear": true,
                "radial": false,
                "color1": "#6a1a4c",
                "color2": "#6a1a4c",
                "rotation": "0"
            }
        },
        "cornersSquareOptions": {
            "type": "extra-rounded",
            "color": "#000000"
        },
        "cornersSquareOptionsHelper": {
            "colorType": {
                "single": true,
                "gradient": false
            },
            "gradient": {
                "linear": true,
                "radial": false,
                "color1": "#000000",
                "color2": "#000000",
                "rotation": "0"
            }
        },
        "cornersDotOptions": {
            "type": "",
            "color": "#000000"
        },
        "cornersDotOptionsHelper": {
            "colorType": {
                "single": true,
                "gradient": false
            },
            "gradient": {
                "linear": true,
                "radial": false,
                "color1": "#000000",
                "color2": "#000000",
                "rotation": "0"
            }
        },
        "backgroundOptionsHelper": {
            "colorType": {
                "single": true,
                "gradient": false
            },
            "gradient": {
                "linear": true,
                "radial": false,
                "color1": "#ffffff",
                "color2": "#ffffff",
                "rotation": "0"
            }
        },
        "frameOptions": {
            "type": "qr-box"
        }
    };

    const [currentStyling, setCurrentStyling] = useState(defaultqrjson);
    const [frame, setFrame] = useState(defaultqrjson.frameOptions.type);



    useEffect(() => {
        setFrame(currentStyling.frameOptions.type);
    }, [currentStyling]);


    const [Templatedataoption, setTemplatedataoption] = useState([]);

    const [longUrl, setLongUrl] = useState('');
    const [destinationFlexpage, setDestinationFlexpage] = useState('');
    const [destinationPhone, setDestinationPhone] = useState('');
    const [destinationEmail, setDestinationEmail] = useState('');
    const [destinationData, setDestinationData] = useState('');
    const [title, setTitle] = useState('Enter Title');
    const [description, setDescription] = useState('');
    const [favicon, setFavicon] = useState('');
    const [UrlType, setUrlType] = useState("simple");
    const [templateid, setTemplateId] = useState('');
    const [smartlinkdata, setSmartlinkdata] = useState('');

    const [flexPageData, setFlexPageData] = useState([]);

    const [filteredSmartlinkData, setFilteredSmartlinkData] = useState([]);
    const [smartlinkSearch, setSmartlinkSearch] = useState("");

    const [domainData, setDomainData] = useState([]);



    const imgRef = useRef(null);

    const handleDomainSelect = (e) => {
        setFrontHalf(e.target.value);

    };


    const handleSmartlinkSearch = (e) => {
        const searchValue = e.target.value;
        setSmartlinkSearch(searchValue);
        const newFilter = smartlinkdata.filter((value) => {
            return value.title.toLowerCase().includes(smartlinkSearch.toLowerCase());
        });

        if (searchValue === "" || searchValue.trim() === "") {
            setFilteredSmartlinkData(smartlinkdata);
        }
        else {
            setFilteredSmartlinkData(newFilter);
        }
    }

    const handleTagFilter = (tag) => {

        if (tag === "All") {
            setFilteredSmartlinkData(smartlinkdata);
        }
        else {
            const filteredData = smartlinkdata.filter((smart) => smart?.tag_id === tag);
            setFilteredSmartlinkData(filteredData);
        }

    }




    const generateUniqueId = () => {
        const id = nanoid(8);
        setUniqueId(id);
    };

    const getBrandData = () => {
        setLoading(true);
        const user = JSON.parse(localStorage.getItem('user'));
        const data = {
            owner_id: user.owner_id,
        };

        brandSmartCampApi(data)
            .then((res) => {

                if (res.code === 200) {

                    if (res.data.status == true && res.data.data.length > 0) {

                        if (sidebarBrands.selected === "all") {
                            setBrandData(res.data.data);
                            //setSelectTemp(res.data.data);
                            setBrandId(res.data.data[0].brand_id);

                        }
                        else {

                            const filterData = res.data.data.filter((item) => item.brand_id === sidebarBrands.selected);
                            setBrandData(filterData);
                            //setSelectTemp(filterData);
                            setBrandId(filterData[0].brand_id);
                        }


                    }

                } else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong',
                    })
                }
            })
            .catch((err) => {
                console.log("err", err);
                PNotify.error({
                    title: 'Error',
                    text: 'Error fetching brand data',
                })
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getBrandData();
    }, [])

    useEffect(() => {

        handleSelectBrand()

    }, [BrandId, selectTemp, brandsOwner_id])

    useEffect(() => {
        console.log("data currentStyling", currentStyling.data);
        currentStyling.data = frontHalf + '/' + uniqueId + '?method=scan';
        console.log("data currentStyling", currentStyling);



        const qrCode = new QRCodeStyling(currentStyling);
        qrCode.append(qrCodeRef.current);
        qrCode.update();
    }, [uniqueId, currentStyling]);

    const validateUrl = (url) => {

        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };
    const [smartLoding, setSmartLoding] = useState(false)
    const savesmartlikndata = () => {
        setSmartLoding(true);
        const validationResult = SubscribsationBackhalfValid(uniqueId, subscriptionDetails);

        if (!validationResult.isValid) {
            PNotify.error({
                title: 'Error',
                text: validationResult.message,
            });
            setSmartLoding(false);
            return false;
        }

        if ((subscriptionStatus === "incomplete" || subscriptionStatus === "incomplete_expired" || subscriptionStatus === "past_due"
            || subscriptionStatus === "canceled" || subscriptionStatus === "unpaid" || subscriptionStatus === "paused") && smartlinkdata.length >= 1) {
            PNotify.error({
                title: 'Error',
                text: 'Please upgrade your plan to continue',
            });
            setSmartLoding(false);
            return false;
        } else if (!BrandId) {
            PNotify.error({
                title: 'Error',
                text: 'Please select brand',
            });
            setSmartLoding(false);
            return false;
        } else if (!frontHalf) {
            PNotify.error({
                title: 'Error',
                text: 'Please select domain',
            });
            setSmartLoding(false);
            return false;
        } else if (!longUrl && destinationType === "url") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter URL',
            });
            setSmartLoding(false);
            return false;
        } else if (!validateUrl(longUrl) && destinationType === "url") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter a valid URL',
            });
            setSmartLoding(false);
            return false;
        } else if (!title) {
            PNotify.error({
                title: 'Error',
                text: 'Please enter title',
            });
            setSmartLoding(false);
            return false;
        } else if (!description) {
            PNotify.error({
                title: 'Error',
                text: 'Please enter description',
            });
            setSmartLoding(false);
            return false;
        }

        // Process to create formData and send request
        const shortUrl = API_URL;
        const backhalf = uniqueId;
        const styling = currentStyling;

        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const owner_id = user.owner_id;
        const created_by = user._id;

        if (!templateid) {
            settemplateImage(defaultimage);
            setTemplateId(0);
        }

        toJpeg(divRef.current, { quality: 0.95 }).then(dataUrl => {
            const base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, '');
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/jpeg' });

            const full_short_url = `${frontHalf}/${backhalf}`;
            const formData = new FormData();
            formData.append('full_short_url', full_short_url);
            formData.append('domain_name', frontHalf);
            formData.append('image', blob);
            formData.append('templateImage', templateImage);
            formData.append('templateid', templateid);
            formData.append('urlType', UrlType);
            formData.append('styling', JSON.stringify(styling));
            formData.append('backhalf', backhalf);
            formData.append('shortUrl', shortUrl);
            formData.append('title', title);
            formData.append('description', description);
            formData.append('created_by', created_by);
            formData.append('updated_by', created_by);
            formData.append('owner_id', brandsOwner_id);
            formData.append("favicon", favicon);
            formData.append("caption", title);
            formData.append("brand_id", BrandId);
            formData.append("type", 0);
            formData.append("creator_image", user.profile_image);
            formData.append("creator_name", `${user.fname} ${user.lname}`);
            formData.append("destinationType", destinationType);

            if (selectedTag !== "") {
                formData.append("tag_id", selectedTag);
            }

            // Append data based on destinationType
            switch (destinationType) {
                case "url":
                    formData.append('longUrl', longUrl);
                    break;
                case "flexpage":
                    formData.append('destinationFlexpage', destinationFlexpage);
                    break;
                case "phone":
                    formData.append('destinationPhone', destinationPhone);
                    break;
                case "email":
                    formData.append('destinationEmail', destinationEmail);
                    break;
                case "data":
                    formData.append('destinationData', destinationData);
                    break;
                default:
                    break;
            }

            addsmartlink(formData).then((res) => {
                if (res.code === 200 && res.data.status === true) {
                    PNotify.success({
                        title: 'Success',
                        text: 'Smartlink created successfully',
                    });
                    getsmartlinkdata();
                    setSmartLoding(false);
                    handleClose03();
                } else {
                    PNotify.error({
                        title: 'Error',
                        text: res.data.message,
                    });
                    setSmartLoding(false);
                }
            }).catch((err) => {
                console.log("err", err);
                PNotify.error({
                    title: 'Error',
                    text: err.message,
                });
                setSmartLoding(false);
            });
        });
    };


    const getsmartlinkdata = () => {
        setLoading(true);
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);

        const data = {
            owner_id: user.owner_id,
            created_by: user._id,
            role: user.role,
            type: "0",
            creation_method: "smartlink"
        }

        getSmartlinkApi(data).then((res) => {

            setLoading(true);
            if (res.code === 200) {
                if (res.data.status === true) {
                    const ApiData = res.data.data;
                    if (sidebarBrands.selected == "all") {
                        setSmartlinkdata(ApiData);
                        setFilteredSmartlinkData(ApiData);
                        flexLinkdispatch({ type: "SET_FLEXLINK_DATA", payload: ApiData })

                    } else {
                        const filterData = ApiData.filter((item) => item.brand_id === sidebarBrands.selected);
                        setSmartlinkdata(filterData);
                        setFilteredSmartlinkData(filterData);
                        flexLinkdispatch({ type: "SET_FLEXLINK_DATA", payload: filterData })
                    }
                    // setSmartlinkdata(ApiData);
                    // setFilteredSmartlinkData(ApiData);


                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Data not found',
                    })

                }

            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong',
                })

            }
        }).catch((err) => {
            console.log("err", err);

        }).finally(() => {
            setLoading(false);
        });
    }

    const deletesmartlink = () => {

        handleClose04();

        const data = {
            id: currentSmartlinkId
        }

        deletesmartlinkApi(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    PNotify.success({
                        title: 'Success',
                        text: 'Smartlink deleted successfully',
                    })
                    getsmartlinkdata();
                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong',
                    })
                }

            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong',
                })
            }
        }).catch((err) => {
            console.log("err", err);
        });

    }


    const handleSesionName = (sname, sdes) => {

        // const sData = SessionData.filter((item) => item._id === data);

        setSessionName(sname);
        setSessionDescription(sdes);
    }
    const [loadingMeta, setLodingMeta] = useState(false)

    const getMetaData = () => {
        setLodingMeta(true);
        const data = {
            url: longUrl
        }

        if (!validateUrl(longUrl)) {
            // URL is invalid, handle error
            alert('Please enter a valid URL');
            setLodingMeta(false);
        }
        else {
            setLodingMeta(true);
            getMetaApi(data).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === true) {
                        const title = res.data.data.title;
                        const description = res.data.data.description;
                        const icon = res.data.data.icon;

                        setTitle(title);
                        setDescription(description);
                        setFavicon(icon);


                    }
                    else {
                        PNotify.error({
                            title: 'Error',
                            text: 'Data not found',
                        })

                    }

                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong',
                    })

                }
            }).catch((err) => {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong',
                })

            }).finally(() => {
                setLodingMeta(false);
            }
            );
        }
    }


    const handleVerifyBackhalf = (e) => {
        const data = {
            backhalf: uniqueId
        }

        verifybackhalf(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    setBackhalfVerfied(true);
                }
                else {
                    setBackhalfVerfied(false);
                }

            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong',
                })
            }
        }
        ).catch((err) => {
            console.log("err", err);
        }
        );

    }

    const getFlexPageData = async () => {

        setLoading(true);
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const created_by = user._id;

        const data = {
            id: created_by,
        }

        const response = await fetch(`${API_URL}smartlink/getallflexpagesforcampaign`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        const result = await response.json();
        console.log("result", result);
        if (result.status) {
            if (sidebarBrands.selected === "all") {

                setFlexPageData(result.data);
            } else {

                const newdata = result.data.filter((value) => {
                    return value.brand_id === sidebarBrands.selected
                })
                setFlexPageData(newdata);
            }
        }


    }

    console.log("default fronthalf============>", flexPageData);

    useEffect(() => {
        getsmartlinkdata();
        getFlexPageData();

    }, []);



    const handelTemplate = (e) => {

        setCurrentStyling(JSON.parse(e.target.selectedOptions[0].getAttribute('data-styling')));
        settemplateImage(e.target.selectedOptions[0].getAttribute('data-templateimage'));
        setTemplateId(e.target.value);
        setFrame(JSON.parse(e.target.selectedOptions[0].getAttribute('Styling')));
    }



    const handleSelectBrand = () => {
        if (BrandId !== "") {
            console.log("aminesh Inside BrandId", BrandId);
            console.log("aminesh Inside selectTemp", brandData);

            const selectedOption = brandData.filter((item) => item.brand_id === BrandId);
            const templateData = selectedOption[0].template;

            // domainData comes from selectedOption
            const domainData = selectedOption[0].domain || [];

            // Get domain URLs from the .env variable
            const domainUrls = process.env.REACT_APP_DOMAIN_URLS.split(',');

            // Create an array by mapping through domainUrls
            const newArray = domainUrls.map((url) => {
                return { domain_name: url };
            });

            // Combine newArray with domainData from the brandData
            const finalArray = [...newArray, ...domainData];

            console.log("finalArray (domain data)", finalArray);
            setDomainData(finalArray);
            console.log("domain==================>", finalArray[0].domain_name);
            setFrontHalf(finalArray[0].domain_name);

            console.log("templateData", templateData);
            setTemplateData(templateData);
            console.log("template============>", templateData[0].thumbnail);
            settemplateImage(templateData[0].thumbnail);
            setCurrentStyling(JSON.parse(templateData[0].styling));
            setTemplateId(templateData[0]._id);
            setBrandsOwner_id(selectedOption[0].owner_id);
        }
    };



    return (
        <>
            {loading && <Loader2 />}
            {/* QR Code modal start here */}
            <Modal show={show} onHide={handleClose} centered className="qr-code-modal"  >
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <img ref={imgRef} src={API_URL + `qrcodes/` + templateImagefront} alt="" className="img-fluid popup-image" />
                        <div className="qr-btn-list">
                            <button className="btn qr-modal-btn me-2 " onClick={handleClose}>Share Link</button>
                            <button className="btn qr-modal-btn" onClick={() => {
                                handleClose();
                                downloadImage('png', imgRef, ImageName)
                            }}>Download QR</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal end here */}


            {/* new domain modal start here */}
            <Modal show={show05} onHide={handleClose05} className="sidebar-edit-qr Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="main-card-title">Edit Qr Design</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <EditQr templateId={currentSmartlinkId} />
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={handleClose05}> Confirm </button>
                </Modal.Footer>
            </Modal>
            {/* new domain modal end here */}
            {/* Delete Modal Start From Here */}
            <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose04}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={deletesmartlink}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={show06} onHide={handleClose06} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Subscription not active !</Modal.Title>
                </Modal.Header>
                {role == "0" ? (<>
                    <Modal.Body>Please Upgrade to  an active plan to continue using this feature</Modal.Body>
                    <Modal.Footer>
                        <Button className='delete-Cancel-bttn' onClick={() => { history.push("/setting/price") }}>
                            Upgrade
                        </Button>
                    </Modal.Footer>
                </>) : (
                    <Modal.Body>Please contact ypur admin and ask them to upgrade to  an active plan.</Modal.Body>
                )}
            </Modal>
            {/* Delete Modal End Here */}

            {/* new domain modal start here */}
            <Modal show={show03} onHide={handleClose03} className="Sidebar-Modal-end" >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbComponents />New FlexLink</Modal.Title>
                    {/* <Modal.Title>Create New FlexLink</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div >

                        <div className="d-flex justify-content-end mb-3">
                            {smartLoding ? <SpinnerLoader /> :
                                <button disabled={(subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
                                    || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") && smartlinkdata.length >= 1 ? true : false} className="Active-bttn btn" onClick={savesmartlikndata} >
                                    Create FlexLink
                                </button>}
                        </div>

                        <div className="d-flex mb-2">
                            <h4>{frontHalf}/{uniqueId}</h4>
                        </div>



                        <Form.Label >Destination</Form.Label>

                        <Form>
                            {['radio'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                        inline
                                        label="URL"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-1`}
                                        onClick={() => { setDestinationType("url") }}
                                        defaultChecked // Set the first option as default
                                    >
                                    </Form.Check>
                                    <Form.Check
                                        inline
                                        label="FlexPage"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-2`}
                                        onClick={() => { setDestinationType("flexpage") }}
                                    // defaultChecked // Set the first option as default
                                    />
                                    <Form.Check
                                        inline
                                        label="Phone"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-3`}
                                        onClick={() => { setDestinationType("phone") }}
                                    />

                                    <Form.Check
                                        inline
                                        label="Email"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-4`}
                                        onClick={() => { setDestinationType("email") }}
                                    />

                                    <Form.Check
                                        inline
                                        label="Data"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-5`}
                                        onClick={() => { setDestinationType("data") }}
                                    />
                                </div>
                            ))}
                        </Form>

                        {destinationType == "url" &&
                            <div className="did-floating-label-content  w-100">
                                <div className="d-flex">
                                    <input className="did-floating-input" type="text" placeholder=" "
                                        value={longUrl} onChange={
                                            (e) => {
                                                setLongUrl(e.target.value);
                                            }
                                        } />
                                    <label className="did-floating-label">Destination / Long URL</label>

                                    <button className="btn btn-primary form-inline-btn" onClick={getMetaData}>{loadingMeta ? (<Spinner />) : ("Get Meta")}</button>
                                </div>
                            </div>
                        }


                        {/* FlexPage Input */}
                        {destinationType === "flexpage" && (
                            <>
                                <div className="did-floating-label-content w-100">
                                    {/* <input
                                    className="did-floating-input"
                                    type="text"
                                    placeholder=" "
                                    value={destinationFlexpage}
                                    onChange={(e) => setDestinationFlexpage(e.target.value)}
                                /> */}
                                    <select
                                        className="did-floating-select"
                                        aria-label="Default select example"
                                        value={destinationFlexpage}
                                        onChange={(e) => setDestinationFlexpage(e.target.value)}
                                    >
                                        <option value="" disabled defaultValue>
                                            Please Select a FlexPage
                                        </option>
                                        {
                                            flexPageData && flexPageData.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                    {item.backhalf + "-" + item.title}
                                                </option>
                                            ))
                                        }
                                    </select>

                                </div>

                            </>
                        )}

                        {/* Phone Input */}
                        {destinationType === "phone" && (
                            // <div className="did-floating-label-content w-100">
                            //     <input
                            //         className="did-floating-input"
                            //         type="tel"
                            //         placeholder=" "
                            //         value={destinationPhone}
                            //         onChange={(e) => setDestinationPhone(e.target.value)}
                            //     />
                            //     <label className="did-floating-label">Phone Number</label>
                            // </div>

                            <PhoneInput
                                defaultCountry="us"
                                className='z-phone'
                                value={destinationPhone}
                                onChange={(value) => setDestinationPhone(value)}
                                placeholder="Mobile No. "
                                style={{
                                    "--react-international-phone-height": "39.8px",
                                    "--react-international-phone-border-radius": "0",
                                    "--react-international-phone-border-color": "transparent",
                                    "--react-international-phone-text-color": "#000000"
                                }}
                                inputStyle={{
                                    fontSize: "16px",
                                    border: "none",
                                    color: "#000000"
                                }}
                            />
                        )}

                        {/* Email Input */}
                        {destinationType === "email" && (
                            <div className="did-floating-label-content w-100">
                                <input
                                    className="did-floating-input"
                                    type="email"
                                    placeholder=" "
                                    value={destinationEmail}
                                    onChange={(e) => setDestinationEmail(e.target.value)}
                                />
                                <label className="did-floating-label">Email Address</label>
                            </div>
                        )}

                        {/* Data Input */}
                        {destinationType === "data" && (
                            <div className="did-floating-label-content w-100">
                                <textarea
                                    className="did-floating-input"
                                    type="text"
                                    placeholder=" "
                                    value={destinationData}
                                    onChange={(e) => setDestinationData(e.target.value)}
                                />
                                <label className="did-floating-label">Data</label>
                            </div>
                        )}




                        <div className="col-md-12">
                            <Form.Group className="did-floating-label-content" controlId="formBasic">
                                <Form.Select className="did-floating-select" aria-label="Default select example" onChange={
                                    (e) => {

                                        const value = e.target.value;
                                        setBrandId(value);
                                        const selectedOption = e.target.selectedOptions[0];
                                        const Brandsowner_id = selectedOption.getAttribute('Brandsowner_id');
                                        setBrandsOwner_id(Brandsowner_id)

                                    }
                                }>

                                    {brandData && brandData.map((item) => {
                                        return (
                                            <option selected={BrandId === item.brand_id} value={item.brand_id} Brandsowner_id={item.owner_id} templateData={JSON.stringify(item.template)} domainData={JSON.stringify(item.domain)}>{item.brand_name}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Label className="did-floating-label">Brand</Form.Label>
                            </Form.Group>
                        </div>


                        <div className="form-group">
                            {/* <label id="input-field--label" className="input-field--label">Update Vanity Tag</label> */}
                            <div className="input-field--CUSTOM2 error">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        <Form.Select aria-label="Default select example" className="custom-drop-downbtn" onChange={handleDomainSelect}>


                                            {domainData.map((item, index) => {

                                                return (
                                                    <option value={item.domain_name}>{item.domain_name}</option>
                                                )
                                            })
                                            }
                                        </Form.Select>



                                        <div className="input-field--input-wrapper">
                                            <input aria-labelledby="input-field--label" type="text" className="input-field--input2" value={uniqueId}
                                                onChange={(e) => setUniqueId(e.target.value)}
                                                // onFocus={handleVerifyBackhalf}
                                                onBlur={handleVerifyBackhalf}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-field--static-value">
                                        {backhalfVerfied ? <div className="icon-check" style={{ color: "green" }}>
                                            <BsCheckCircleFill />
                                        </div> : <div className="icon-cross" style={{ color: "red" }}>
                                            <BsXCircleFill />
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="did-floating-label-content">
                            <select className="did-floating-select" onclick={handelTemplate} onChange={handelTemplate}>
                                {templateData && templateData.map((item) => {

                                    return (
                                        <option value={item._id} data-styling={item.styling} data-templateimage={item.thumbnail}>{item.template_name}</option>
                                    )
                                })
                                }
                            </select>
                            <label className="did-floating-label">Apply Brand Template</label>
                        </div>

                        <div className="did-floating-label-content">
                            <select

                                className="did-floating-select"
                                // onclick={handelTemplate}
                                onChange={
                                    (e) => {
                                        setSelectedTag(e.target.value);
                                    }
                                }
                            >
                                <option value="">No Tag</option>

                                {selectedBrandOwnerTags && selectedBrandOwnerTags.map((item) => {

                                    return (
                                        <option value={item._id} >{item.tag_name}</option>
                                    )
                                })
                                }
                            </select>
                            <label className="did-floating-label">Select Tag</label>
                        </div>


                        <div className='col-md-12'>

                            <Form.Group className="mb-3 did-floating-label-content" controlId="formBasicText">
                                <Form.Control className="did-floating-input" type="text" value={title} onChange={
                                    (e) => {
                                        setTitle(e.target.value);
                                    }
                                } placeholder=""
                                />
                                <Form.Label className="did-floating-label">Title</Form.Label>
                            </Form.Group>


                            <Form.Group className="mb-3 did-floating-label-content" controlId="formBasicText">
                                <Form.Control className="did-floating-textarea" as="textarea" rows={3} value={description} onChange={
                                    (e) => {
                                        setDescription(e.target.value);
                                    }
                                } placeholder="" />
                                <Form.Label className="did-floating-label">Description</Form.Label>
                            </Form.Group>




                            <div className="d-flex gap-2">
                                <Form.Group className="mb-4 image-preview-edit" controlId="formBasicEmail">
                                    <Form.Label>Favicon</Form.Label> <br />

                                    <img src={favicon} alt="" className="img-fluid" />
                                </Form.Group>

                                <div className={`qr-code-similer ${frame}`}>
                                    <div ref={divRef}>
                                        <div className="canvas-title2"><h2>{title}</h2></div>
                                        <div className="Qr-image">
                                            <div ref={qrCodeRef} />
                                        </div>
                                        <div className="canvas-title "><h2>{title}</h2></div>
                                        <div className="canvas-title3 "><h2>{title}</h2></div>
                                    </div>
                                </div>
                            </div>


                            {/* <Form.Group className="mb-3" controlId="formBasicURL">
                                <Form.Label>URL Type</Form.Label>
                                <div className="d-flex align-items-center">
                                    <div className="url-type-deisgn me-3 active">
                                        <div className="row">
                                            <div className="col-md-6 ps-0">
                                                <div className="url-img">
                                                    <img src={Urlimg} alt="URL" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 p-0">
                                                <div className="url-type-text">
                                                    <Form.Check label="Simple" name="group1" type="radio" id={`reverse-radio-1`} checked onClick={removeClass} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="url-type-deisgn1 me-3">
                                        <div className="row">
                                            <div className="col-md-6 ps-0">
                                                <div className="url-img">
                                                    <img src={Urlimg} alt="Dynamic" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 p-0">
                                                <div className="url-type-text">
                                                    <Form.Check label="Dynamic" name="group1" type="radio" id={`reverse-radio-2`} onClick={addClass} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group> */}
                            <div className="domain-box">
                                <div className="col-md-12">
                                    <Form.Group className="mb-3" controlId="formBasic">
                                        <Form.Select aria-label="Default select example">
                                            <option>Select Domain Name</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </div>

                            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Campaign Name</Form.Label>
                                <Form.Control type="text" value={campaignName} onChange={
                                    (e) => {
                                        setCampaignName(e.target.value);
                                    }
                                } placeholder="Campaign Name" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Campaign description</Form.Label>
                                <Form.Control as="textarea" rows={3} value={campaignDescription} onChange={
                                    (e) => {
                                        setCampaignDescription(e.target.value);
                                    }
                                } placeholder="Campaign description" />
                            </Form.Group> */}

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal end here */}


            <div className="main-container">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            <div className="container-fulid">
                                <div className="row pd-26">
                                    <div className="ps-0 pe-0">
                                        <div className="card-body table-card-body">
                                            <div className="table-card-header add-btn d-flex">
                                                <h5 className="main-card-title"><TbComponents className="header-icon" />FLEXLINKS</h5>
                                                <div className="card-content-header-right">
                                                    <Button className="btn btn-sm float-right add-user-btn" onClick={
                                                        () => {


                                                            if ((subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
                                                                || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") && smartlinkdata.length >= 1) {
                                                                handleShow06();
                                                            }
                                                            else {

                                                                handleShow03();
                                                                generateUniqueId();
                                                                // getTemplateData();
                                                            }
                                                        }
                                                    } > <TbComponents />New FlexLink</Button>
                                                </div>
                                            </div>
                                            <div className="wrap">
                                                <form role="search" className="search-form form">
                                                    <label>
                                                        <span className="screen-reader-text">Search for...</span>
                                                        <input type="search" className="search-field" placeholder="Search" value={smartlinkSearch} onChange={handleSmartlinkSearch} />
                                                    </label>
                                                    <button class="search-submit button">
                                                        <FaSearch />
                                                    </button>
                                                </form>
                                            </div>

                                            <div>
                                                <Dropdown>
                                                    <Dropdown onSelect={handleTagFilter}>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            Tags Filter
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item eventKey="All">All</Dropdown.Item>
                                                            {tags && tags.map((item) => (
                                                                <Dropdown.Item eventKey={item._id}>{item.tag_name}</Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Dropdown>
                                            </div>






                                            <div className="table-card-body-inner mobile-view-hide">
                                                <div className="Domain-table">
                                                    <ul>
                                                        {
                                                            filteredSmartlinkData && filteredSmartlinkData.map((item, index) => {
                                                                let clicks = item.clicks
                                                                let scans = item.scans
                                                                const createdOnTime = new Date(filteredSmartlinkData[index].created_on).toLocaleTimeString("en-US", { minute: "2-digit", hour: "2-digit", });
                                                                const updatedOnTime = new Date(filteredSmartlinkData[index].updated_on).toLocaleTimeString("en-US", { minute: "2-digit", hour: "2-digit", });
                                                                {/* const formattedUpdatedTime = updatedOnTime.slice(0, 4) + ' ' + updatedOnTime.slice(-2); */ }
                                                                {/* console.log(formattedTime); */ }
                                                                const tag_id = item?.tag_id


                                                                return (

                                                                    <li>
                                                                        <div className="Smartlink01" style={{ position: "relative" }}>

                                                                            {tag_id &&
                                                                                <RectangularTag tag_id={tag_id} />
                                                                            }
                                                                            <div className="SmartLinks-table-view">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="views01">
                                                                                        <h5>{scans + clicks} <br /><span> Visits</span></h5>
                                                                                        <div>
                                                                                            <h6>{clicks} Clicks</h6>
                                                                                            <h6>{scans} Scans</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="SmartLink-list">
                                                                                        <div className="SmartLinks-table-image">
                                                                                            <button className="clr-theme text-decoration-none" onClick={() => {
                                                                                                handleShow();
                                                                                                settemplateImageFront(item.template_image);
                                                                                                setImageName(item.template_image);

                                                                                            }}  >
                                                                                                <img src={API_URL + `qrcodes/` + item.template_image} ealt="" className="img-fluid" />
                                                                                                {/* <img src={Qr} alt="" className="img-fluid" /> */}
                                                                                            </button>
                                                                                        </div>

                                                                                        <div className="Domain-table-content">

                                                                                            <h4>{item.title}</h4>

                                                                                            <div className="d-flex gap-1 align-items-center">
                                                                                                <h4>{item.full_short_url}</h4>
                                                                                                <CopyCat text={item.full_short_url} />
                                                                                            </div>

                                                                                            <div className="brand-Vanity-Tag mt-2">
                                                                                                <p className="d-flex align-items-center">
                                                                                                    <h3 className="text-blue-dark me-2">Destination:</h3>
                                                                                                    <div className="vanity-tag smartlink-destination h4">
                                                                                                        <a href={item.long_url} target="_blank" style={{ color: '#0f28d8', backgroundColor: '#dee5ff' }}><BsLink45Deg /> Dynamic URL</a>
                                                                                                        <div className="long-url-tooltip">
                                                                                                            <p>{item.long_url}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </p>
                                                                                            </div>


                                                                                            <div className="brand-Vanity-Tag d-flex align-items-center mt-2">
                                                                                                {/* <h3 className="text-blue-dark me-2">Features :</h3> */}
                                                                                                <div className="vanity-tag d-flex smartlink-tag">
                                                                                                    {item.schedule_end_date_status === true && <h4 style={{ color: '#3ed599', backgroundColor: '#e5f9ef' }}>< MdOutlineCalendarToday />Schedule Waypoint</h4>}
                                                                                                    {item.password_protection_status === true && <h4 style={{ color: '#ff974a', backgroundColor: '#fff0e5' }}><MdLockOutline />Password Waypoint</h4>}
                                                                                                    {item.redirection_status === true && <h4 style={{ color: '#0261ff', backgroundColor: '#dee7ff' }}>< BsFillImageFill />Content WayPoint</h4>}
                                                                                                    {item.cta_status === true && <h4 style={{ color: '#ffb100', backgroundColor: '#fff4e0' }}>< TbHandClick />Call to Action</h4>}
                                                                                                    {item.utm_status === true && <h4 style={{ color: '#c63ed5', backgroundColor: '#f9e5f5' }}>< MdLocalOffer />UTM Parameters</h4>}
                                                                                                </div>

                                                                                            </div>


                                                                                        </div>

                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div className="Domain-table-action">

                                                                                <div className="details-box-smartlink w-100">
                                                                                    <div key={index} className="d-flex align-items-center w-100">
                                                                                        <div className="profile-user-image">
                                                                                            {filteredSmartlinkData[index].updator_name && (
                                                                                                <img
                                                                                                    src={API_URL + `profile/` + filteredSmartlinkData[index].updator_image}
                                                                                                    alt="Creator Image"
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="user-details w-100">
                                                                                            <h6>{filteredSmartlinkData[index].updator_name}</h6>
                                                                                            <div className="d-flex align-items-center">
                                                                                                <span>{filteredSmartlinkData[index].updator_name
                                                                                                    ? `Updated:${new Date(filteredSmartlinkData[index].updated_on).toLocaleDateString()} ${updatedOnTime}`
                                                                                                    : ''}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="button-group1 justify-content-end">
                                                                                    <div className="d-flex new-M justify-content-end">
                                                                                        <Link to={`/flexlink/metrics/` + item.backhalf}>
                                                                                            <button type="button" className="btn btn-chart me-3">
                                                                                                <TbChartBar />
                                                                                            </button>
                                                                                        </Link>

                                                                                        <Link to={`/editflexlink/` + item._id} >
                                                                                            <button type="button" className="btn btn-edit me-3" onClick={
                                                                                                () => {
                                                                                                    handleSesionName(item.session_name, item.session_description);
                                                                                                }
                                                                                            }>
                                                                                                <TbEdit />
                                                                                            </button>
                                                                                        </Link>
                                                                                        <button type="button" className="btn btn-delete" onClick={
                                                                                            (e) => {
                                                                                                e.preventDefault();
                                                                                                setCurrentSmartlinkId(item._id);
                                                                                                console.log('current smartlink id', currentSmartlinkId);
                                                                                                handleShow04();
                                                                                            }
                                                                                        }>
                                                                                            <TbTrash />
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="smartlink-mobile-show">
                                        {
                                            filteredSmartlinkData && filteredSmartlinkData.map((item) => {
                                                let clicks = item.clicks
                                                let scans = item.scans


                                                {/* console.log("item", item); */ }

                                                return (
                                                    <div className="smartlink-view-card">
                                                        <div className="smartlink-view-card-body">
                                                            <div className="smartlink-view-card-body-inner">
                                                                <div className="brand-link">
                                                                    <h3>{item.title}</h3>
                                                                    <div className="d-flex brand-link-item">
                                                                        <div className="d-flex gap-3 align-items-center">
                                                                            <h4>{item.full_short_url}</h4>
                                                                            <CopyCat text={item.full_short_url} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-between text-center">
                                                                    <h5>{scans + clicks} <br /><span> Visits</span></h5>
                                                                    <h6>{clicks} <br /> Clicks</h6>
                                                                    <h6>{scans} <br /> Scans</h6>
                                                                </div>
                                                                <div className="brand-Vanity-Tag mt-2">
                                                                    <p>
                                                                        <span className="text-blue-dark">Destination: </span>
                                                                        <div className="brand-Vanity-Tag">
                                                                            <h4>Dynamic URL</h4>
                                                                        </div>
                                                                    </p>
                                                                </div>
                                                                <div className="brand-Vanity-Tag d-flex align-items-center mt-2">
                                                                    {/* <h3>Features :</h3> */}
                                                                    <div className="vanity-tag d-flex smartlink-tag">
                                                                        {item.redirection_status === true && <h4 style={{ color: '#0261ff', backgroundColor: '#dee7ff' }}>Content WayPoint</h4>}
                                                                        {item.cta_status === true && <h4 style={{ color: '#ffb100', backgroundColor: '#fff4e0' }}>Call to Action</h4>}
                                                                        {item.password_protection_status === true && <h4 style={{ color: '#ff974a', backgroundColor: '#fff0e5' }}>Password Waypoint</h4>}
                                                                        {item.schedule_end_date_status === true && <h4 style={{ color: '#3ed599', backgroundColor: '#e5f9ef' }}>Schedule</h4>}
                                                                        {item.utm_status === true && <h4 style={{ color: '#c63ed5', backgroundColor: '#f9e5f5' }}>UTM Parameters</h4>}
                                                                    </div>
                                                                </div>
                                                                <div className="qr-code-item">
                                                                    <div className="SmartLinks-table-image">
                                                                        <button className="clr-theme text-decoration-none" onClick={() => {
                                                                            handleShow();
                                                                            settemplateImageFront(item.template_image);
                                                                            setImageName(item.template_image);

                                                                        }}  >
                                                                            <img src={API_URL + `qrcodes/` + item.template_image} ealt="" className="img-fluid" />
                                                                            {/* <img src={Qr} alt="" className="img-fluid" /> */}
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                                <div className="smartlink-btn-list">
                                                                    <div className="Domain-table-action">
                                                                        <div className="button-group1 ">
                                                                            <div className="d-flex">
                                                                                <div className="d-flex">
                                                                                    <Link to={`/smartlink/metrics/` + item.backhalf}>
                                                                                        <button type="button" className="btn btn-chart me-3">
                                                                                            <TbChartBar />
                                                                                        </button>
                                                                                    </Link>

                                                                                </div>
                                                                                <div className="d-flex new-M">
                                                                                    {/* <Link to="/createflexlink"> */}
                                                                                    <Link to={`/createflexlink/` + item._id} >
                                                                                        <button type="button" className="btn btn-edit me-3" onClick={
                                                                                            () => {
                                                                                                handleSesionName(item.session_name, item.session_description);
                                                                                            }
                                                                                        }>
                                                                                            <TbEdit />
                                                                                        </button>
                                                                                    </Link>
                                                                                    <button type="button" className="btn btn-delete" onClick={
                                                                                        (e) => {
                                                                                            e.preventDefault();
                                                                                            setCurrentSmartlinkId(item._id);
                                                                                            console.log('current smartlink id', currentSmartlinkId);
                                                                                            handleShow04();
                                                                                        }
                                                                                    }>
                                                                                        <TbTrash />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </>
    );
}

export default Qrtemplates;