import { createContext, useState, useReducer, useMemo } from "react";

import { eventsDataReducer } from '../reducers/eventsDataReducer'


export const eventsDataContext = createContext();

export const EventDataProvider = ({ children }) => {

    const initialState = {
        isFetching: true,
        events: []
    };

    const [events, eventsDataDispatch] = useReducer(eventsDataReducer, initialState);

    const eventsData = useMemo(() => events, [events]);

    return (
        <eventsDataContext.Provider value={{
            eventsData, eventsDataDispatch
        }}>
            {children}
        </eventsDataContext.Provider>
    );
};

