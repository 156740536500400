import React from 'react'
import { API_URL } from '../../../BackendApi/Api/config'

const EventsGalleryList = (props) => { 
    // console.log("GALLERY", props)
    let images = props.data?.imagefile || [];
    console.log("GALLERY", images)
    return (
        <div className="mb-3" id={props.data.unique_id}>
            {images.length > 0 && (
                <div className="gallery" >
                    <div style={{
                        display: "flex", flexWrap: "wrap", gap: 1, border: "1px solid #ccc", borderRadius: "8px",
                    }}>
                        {images.map((imageData, index) => (
                            <div key={index} style={{ margin: "5px" }}>
                                <img

                                    src={
                                        (imageData?.startsWith("http://") || imageData?.startsWith("https://"))
                                            ? imageData
                                            : API_URL + "flowpage/" + imageData
                                    }
                                    alt={`Uploaded image ${index}`}
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                        borderRadius: "8px",
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default EventsGalleryList
