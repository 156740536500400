// import React from 'react';
// import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
// import { useState, useEffect } from 'react';

// function MapView() {
//     const { isLoaded } = useJsApiLoader({
//         id: 'google-map-script',
//         googleMapsApiKey: "AIzaSyAt5m5uNR4aEfvr9Bq2k3a5rDHg9IT1ml0" // Replace with your Google Maps API key
//     });

//     const center = { lat: 32.7460124, lng: -117.1274174 };
//     const [coordinates, setCoordinates] = useState([]);
//     const [activeMarker, setActiveMarker] = useState(null);
//     const [map, setMap] = useState(null);

//     const onLoad = map => {
//         const bounds = new window.google.maps.LatLngBounds(center);
//         map.fitBounds(bounds);
//         setMap(map);
//     };

//     const onUnmount = () => setMap(null);

//     const handleActiveMarker = marker => setActiveMarker(marker === activeMarker ? null : marker);

//     useEffect(() => {
//         // Example coordinates; replace with your dynamic data
//         setCoordinates([
//             { id: 1, lat: 32.715736, lng: -117.161087, name: "Location 1" },
//             { id: 2, lat: 32.7460124, lng: -117.1274174, name: "Location 2" }
//         ]);
//     }, []);

//     return (
//         <div style={{ width: "100%", height: "500px" }}>
//             {isLoaded ? (
//                 <GoogleMap
//                     mapContainerStyle={{ width: "100%", height: "100%" }}
//                     onLoad={onLoad}
//                     onUnmount={onUnmount}
//                     onClick={() => setActiveMarker(null)}
//                 >
//                     {coordinates.map(marker => (
//                         <Marker
//                             key={marker.id}
//                             position={{ lat: marker.lat, lng: marker.lng }}
//                             onClick={() => handleActiveMarker(marker)}
//                         />
//                     ))}
//                     {activeMarker && (
//                         <InfoWindow
//                             position={{ lat: activeMarker.lat, lng: activeMarker.lng }}
//                             onCloseClick={() => setActiveMarker(null)}
//                         >
//                             <div>
//                                 <h4>{activeMarker.name}</h4>
//                                 <p>Latitude: {activeMarker.lat}</p>
//                                 <p>Longitude: {activeMarker.lng}</p>
//                             </div>
//                         </InfoWindow>
//                     )}
//                 </GoogleMap>
//             ) : (
//                 <p>Loading...</p>
//             )}
//         </div>
//     );
// }

// export default React.memo(MapView);



import React from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";

const SimpleMap = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAt5m5uNR4aEfvr9Bq2k3a5rDHg9IT1ml0", // Replace with your API key
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100vh",
      }}
      center={{
        lat: -34.397,
        lng: 150.644,
      }}
      zoom={8}
    ></GoogleMap>
  );
};

export default SimpleMap;


// import React, { useEffect, useState, useRef } from "react";
// import { useLoadScript } from "@react-google-maps/api";

// // Declare libraries outside the component
// const libraries = ["places"];

// const AddressAutocompleteForm = () => {
//   // State to manage form inputs
//   const [input, setInput] = useState({
//     streetAddress: "",
//     country: "",
//     zipCode: "",
//     city: "",
//     state: "",
//     latitude: null,
//     longitude: null
//   });

//   // Ref for the autocomplete input
//   const inputRef = useRef(null);

//   // Load Google Maps script
//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: 'AIzaSyAt5m5uNR4aEfvr9Bq2k3a5rDHg9IT1ml0',
//     libraries,
//   });

//   // Handle input changes for other fields
//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setInput((prevValues) => ({ 
//       ...prevValues, 
//       [name]: value 
//     }));
//   };

//   // Handle place selection
//   useEffect(() => {
//     if (!isLoaded || loadError) return;

//     const options = {
//       componentRestrictions: { country: "ng" }, // Restrict to Nigeria, modify as needed
//       fields: ["address_components", "geometry"],
//     };

//     const autocomplete = new window.google.maps.places.Autocomplete(
//       inputRef.current, 
//       options
//     );

//     autocomplete.addListener("place_changed", () => handlePlaceChanged(autocomplete));

//     // Cleanup listener
//     return () => {
//       window.google.maps.event.clearInstanceListeners(autocomplete);
//     };
//   }, [isLoaded, loadError]);

//   // Process selected place
//   const handlePlaceChanged = (address) => {
//     if (!isLoaded) return;

//     const place = address.getPlace();

//     if (!place || !place.geometry) {
//       setInput({});
//       return;
//     }

//     processPlaceData(place);
//   };

//   // Extract and set form data from place
//   const processPlaceData = (data) => {
//     const addressComponents = data?.address_components || [];

//     const componentMap = {
//       subPremise: "",
//       premise: "",
//       street_number: "",
//       route: "",
//       country: "",
//       postal_code: "",
//       administrative_area_level_2: "",
//       administrative_area_level_1: "",
//     };

//     addressComponents.forEach((component) => {
//       const componentType = component.types[0];
//       if (componentMap.hasOwnProperty(componentType)) {
//         componentMap[componentType] = component.long_name;
//       }
//     });

//     const formattedAddress = [
//       componentMap.subPremise,
//       componentMap.premise,
//       componentMap.street_number,
//       componentMap.route
//     ].filter(Boolean).join(' ').trim();

//     const latitude = data?.geometry?.location?.lat();
//     const longitude = data?.geometry?.location?.lng();

//     setInput((prevValues) => ({
//       ...prevValues,
//       streetAddress: formattedAddress,
//       country: componentMap.country,
//       zipCode: componentMap.postal_code,
//       city: componentMap.administrative_area_level_2,
//       state: componentMap.administrative_area_level_1,
//       latitude: latitude,
//       longitude: longitude,
//     }));
//   };

//   // Handle form submission
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log("Form Submitted:", input);
//     // Add your form submission logic here
//   };

//   // Show loading or error states
//   if (loadError) return <div>Error loading Google Maps</div>;
//   if (!isLoaded) return <div>Loading...</div>;

//   return (
//     <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4">
//       <div className="mb-4">
//         <label htmlFor="addressInput" className="block mb-2">
//           Address
//         </label>
//         <input
//           id="addressInput"
//           ref={inputRef}
//           type="text"
//           placeholder="Enter your address"
//           className="w-full p-2 border rounded"
//         />
//       </div>

//       <div className="grid grid-cols-2 gap-4">
//         <div>
//           <label htmlFor="streetAddress" className="block mb-2">
//             Street Address
//           </label>
//           <input
//             id="streetAddress"
//             name="streetAddress"
//             value={input.streetAddress}
//             onChange={handleChange}
//             type="text"
//             className="w-full p-2 border rounded"
//             readOnly
//           />
//         </div>

//         <div>
//           <label htmlFor="country" className="block mb-2">
//             Country
//           </label>
//           <input
//             id="country"
//             name="country"
//             value={input.country}
//             onChange={handleChange}
//             type="text"
//             className="w-full p-2 border rounded"
//             readOnly
//           />
//         </div>

//         <div>
//           <label htmlFor="city" className="block mb-2">
//             City
//           </label>
//           <input
//             id="city"
//             name="city"
//             value={input.city}
//             onChange={handleChange}
//             type="text"
//             className="w-full p-2 border rounded"
//             readOnly
//           />
//         </div>

//         <div>
//           <label htmlFor="state" className="block mb-2">
//             State
//           </label>
//           <input
//             id="state"
//             name="state"
//             value={input.state}
//             onChange={handleChange}
//             type="text"
//             className="w-full p-2 border rounded"
//             readOnly
//           />
//         </div>

//         <div>
//           <label htmlFor="zipCode" className="block mb-2">
//             Zip Code
//           </label>
//           <input
//             id="zipCode"
//             name="zipCode"
//             value={input.zipCode}
//             onChange={handleChange}
//             type="text"
//             className="w-full p-2 border rounded"
//             readOnly
//           />
//         </div>
//       </div>

//       <div className="mt-4">
//         <button
//           type="submit"
//           className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
//         >
//           Submit
//         </button>
//       </div>
//     </form>
//   );
// };

// export default AddressAutocompleteForm;