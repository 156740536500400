import React, { useEffect, useState } from "react";
import { MdTitle } from "react-icons/md";
import { FaChevronRight, FaLongArrowAltLeft } from "react-icons/fa";
import { Link, useParams, useLocation } from "react-router-dom";
import { useBuilderContext } from "../../../builderContext";
import { v4 as uuidv4 } from "uuid";
import { uploadSingleImageApi } from "../../../BackendApi/Api/flowpageApi";
import { API_URL } from "../../../BackendApi/Api/config";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { Modal, Spinner } from "react-bootstrap";
import { FaX } from "react-icons/fa6";
import { TbFlagCancel, TbImageInPicture } from "react-icons/tb";
import { BiX } from "react-icons/bi";
import "./../../EventsTab/gallery.css";
import MediaPost from "../../../SocialContent/MediaPost";
import { GoFileMedia } from "react-icons/go";
import { useHistory } from "react-router-dom";


export function GalleryList() {
    return (
        <div className="add_block_list_item">
            <button className="add-block-button-wrapper">
                <div className="d-flex">
                    <div className="add-block-btn-icon">
                        <MdTitle />
                    </div>
                    <div className="add-block-btn-text">
                        <h2 className="add-block-btn-text-title">Gallery</h2>
                        <p className="add-block-btn-text-desc">Add a Gallery to your post</p>
                    </div>
                </div>
                <div className="right_arrow">
                    <FaChevronRight />
                </div>
            </button>
        </div>
    );
}



export function GalleryAdd() {
    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();
    const { addComponent, editComponent, myComponents } = useBuilderContext();
    const [isOpen2, setIsOpen2] = useState(false);
    const unique_id = new URLSearchParams(location.search).get("unique_id");
    const position = new URLSearchParams(location.search).get("position");
    const uniqueId = unique_id || uuidv4();
    const [loading, setLoading] = useState(false);
    const [imageProperties, setImageProperties] = useState({
        imagefile: [],
        videoFile: [],
        unique_id: uniqueId,
        mediaType: "image", // Default media type is image
    });

    // Handle file change (image or video)
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        let mediaType;

        if (!file) return;

        const fileType = file.type.split("/")[0];
        if (fileType === "image") {
            mediaType = "image";
        } else if (fileType === "video") {
            mediaType = "video";
        } else {
            PNotify.error({
                title: "Invalid File",
                text: "Please select a valid image or video file.",
            });
            return;
        }

        const data = new FormData();
        data.append("image", file);
        setLoading(true);

        try {
            const res = await uploadSingleImageApi(data); // Ensure this API call is defined in your project

            if (res.code === 200 && res.data.status) {
                const uploadedFilename = res.data.data.filename;

                setImageProperties((prev) => ({
                    ...prev,
                    [mediaType === "image" ? "imagefile" : "videoFile"]: [
                        ...prev[mediaType === "image" ? "imagefile" : "videoFile"],
                        uploadedFilename,
                    ],
                    mediaType: mediaType,
                }));

                PNotify.success({
                    title: "Upload Successful",
                    text: `${mediaType.charAt(0).toUpperCase() + mediaType.slice(1)} uploaded successfully!`,
                });
            } else {
                PNotify.error({
                    title: "Upload Failed",
                    text: "Upload failed. Please try again.",
                });
            }
        } catch (error) {
            PNotify.error({
                title: "Error",
                text: "An error occurred. Please try again.",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (unique_id) {
            const existingImage = myComponents
                .filter((c) => c.type === "GALLERY")
                .map((c) => c.properties)
                .find((c) => c.unique_id === unique_id);

            if (existingImage) {
                setImageProperties(existingImage);
            }
        }
    }, [myComponents, unique_id]);
    const goBacks = `/editEvent/${id}/${unique_id ? "layout" : "addBlock"}`;

    const saveData = () => {
        if (imageProperties.imagefile.length === 0 && imageProperties.videoFile.length === 0) {
            PNotify.error({
                title: "Error",
                text: "No media to save!",
            });
            return;
        }

        setLoading(true);
        const componentData = {
            ...imageProperties,
        };

        if (unique_id) {
            editComponent(position, "GALLERY", componentData, true);
        } else {
            addComponent("GALLERY", componentData);
        }

        PNotify.success({
            title: unique_id ? "Edited Successfully" : "Added Successfully",
            text: unique_id ? "Gallery updated successfully!" : "Gallery added successfully!",
        });
history.push(goBacks);
        setLoading(false);
    };


    const insertaipostVideo = (data) => {
        const newData = data.map((item) => ({
            unique_id: uniqueId,
            videoFile: item,
        }));

        setImageProperties((prev) => ({
            ...prev,
            mediaType: "video",
            videoFile: [...prev.videoFile, ...newData.map((item) => item.videoFile)],
        }));
        setIsOpen2((prev) => !prev);
    };

    const insertaipostImage = (data) => {
        const newData = data.map((item) => ({
            unique_id: uniqueId,
            imagefile: item,
        }));

        setImageProperties((prev) => ({
            ...prev,
            mediaType: "image",
            imagefile: [...prev.imagefile, ...newData.map((item) => item.imagefile)],
        }));
        setIsOpen2((prev) => !prev);
    };

    const setShowMediaModal = () => {
        setIsOpen2((prev) => !prev);
    };

    const handleClose02 = () => setIsOpen2(false);

    const togglePlayPause = (videoElement) => {
        if (videoElement.paused) {
            videoElement.play();
        } else {
            videoElement.pause();
        }
    };
    let imagefile = imageProperties.imagefile
    let videoFile = imageProperties.videoFile
    return (
        <>
            <Modal show={isOpen2} onHide={handleClose02} centered>
                <Modal.Body className="media-modal-new-event mt-2" style={{ padding: 0 }}>
                    <div className="ai-modal-inner">
                        <MediaPost
                            type="event"
                            insertaipostVideo={insertaipostVideo}
                            insertaipostImage={insertaipostImage}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <div className="add_block" style={{ margin: 20 }}>
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to={goBacks}>
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label
                            htmlFor
                            color="grayDarker"
                            className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM"
                        >
                            {unique_id ? "Edit Media" : "Add Media"}
                        </label>
                    </div>
                </div>

                <div className="form_block iuEvSC py-2">
                    <input
                        id="file-input"
                        type="file"
                        accept="image/*,video/*"
                        multiple
                        className="form-control py-2 me-4"
                        onChange={handleFileChange}
                    />
                    <button
                        className="btn post-btn ai-view-btn my-2"
                        onClick={() => {
                            setShowMediaModal(true);
                        }}
                    >
                        
                        <GoFileMedia />
                    </button>
                </div>

                {loading && <Spinner animation="border" role="status" />}

                {Array.isArray(imagefile) && (imagefile.length > 0 || videoFile.length > 0) ? (
                    <div className="gallery" style={{ marginTop: "30px" }}>
                        <h6>Uploaded Media</h6>
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                backgroundColor: "#f9f9f9",
                            }}
                        >
                            { imagefile && imagefile?.map((imageData, index) => (
                                <div key={index} className="remove-img-event">
                                    <img
                                        src={
                                            imageData?.startsWith("http://") || imageData?.startsWith("https://")
                                                ? imageData
                                                : `${API_URL}flowpage/${imageData}`
                                        }
                                        alt={`Uploaded image ${index + 1}`}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "cover",
                                            borderRadius: "8px",
                                        }}
                                    />
                                    <button
                                        className="remove-btn"
                                        onClick={() => {
                                            setImageProperties((prev) => ({
                                                ...prev,
                                                imagefile: prev.imagefile.filter((_, i) => i !== index),
                                            }));
                                        }}
                                    >
                                        <BiX size={24} />
                                    </button>
                                </div>
                            ))}

                            {videoFile.map((videoData, index) => (
                                <div key={index} className="remove-img-event">
                                    <video
                                        className="video-player"
                                        crossOrigin="anonymous"
                                        autoPlay
                                        muted
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "cover",
                                            borderRadius: "8px",
                                        }}
                                        controls
                                        onClick={(e) => togglePlayPause(e.target)}
                                    >
                                        <source
                                            src={
                                                videoData?.startsWith("http://") || videoData?.startsWith("https://")
                                                    ? videoData
                                                    : `${API_URL}flowpage/${videoData}`
                                            }
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                    <button
                                        className="remove-btn"
                                        onClick={() => {
                                            setImageProperties((prev) => ({
                                                ...prev,
                                                videoFile: prev.videoFile.filter((_, i) => i !== index),
                                            }));
                                        }}
                                    >
                                        <BiX size={24} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}

                <div className="add-block-btn" style={{ marginTop: 20 }}>
                    <button
                        className="btn publish-btn"
                        onClick={saveData}
                        disabled={loading}
                    >
                        {loading ? "Saving..." : unique_id ? "Edit" : "Add"}
                    </button>
                </div>
            </div>
        </>
    );
}


