import { createContext, useMemo, useReducer } from "react";

import { tagReducer } from '../reducers/tagReducer'

export const tagContext = createContext()

export const TagProvider = ({children}) => {

    const initialState = {
        isFetching: true,
        tags: []
    };

    const [tag, tagdispatch] = useReducer(tagReducer, initialState);

    const tagData = useMemo(() => tag, [tag]);

    return (
        <tagContext.Provider value={{
            tagData, tagdispatch
        }}>
            {children}
        </tagContext.Provider>
    );
}