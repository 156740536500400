import React, { useContext } from 'react'
import { eventsDataContext } from '../context/eventsDataContext';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const useEventsDataActions = () => {

    const { eventsDataDispatch } = useContext(eventsDataContext);

    const getNearbyEvents = async (lat, lng) => {

        try {
            eventsDataDispatch({ type: "FETCHING_EVENTS", payload: true });
            const response = await axios.post(`${API_URL}/event/get-nearby-events`,
                {
                    lat,
                    lng
                }
            )
            const data = response.data.data
            eventsDataDispatch({ type: "SET_EVENTS", payload: data });
            eventsDataDispatch({ type: "FETCHING_EVENTS", payload: false });

        } catch (error) {
            console.log(error);
            eventsDataDispatch({ type: "FETCHING_EVENTS", payload: false });
            eventsDataDispatch({ type: "SET_EVENTS", payload: [] });


        }

    }


    return {
        getNearbyEvents
    }
}

export default useEventsDataActions