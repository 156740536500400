// import React, { useState } from 'react';
// import { Container, Button, Form, Card } from 'react-bootstrap';
// import { IoClose, IoSend, IoLogoWechat } from 'react-icons/io5';
// import Draggable from 'react-draggable';
// // import 'bootstrap/dist/css/bootstrap.min.css';

// const ChatBot = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [message, setMessage] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [chatHistory, setChatHistory] = useState([
//     {
//       message: "Hey there! Can I assist you today? Do you want some assistance with brand or social media posts?",
//       role: "bot"
//     }
//   ]);

//   const handleSendMessage = async (e) => {
//     e.preventDefault();
//     if (!message.trim()) return;

//     setLoading(true);
//     setChatHistory([...chatHistory, { message, role: 'user' }]);
//     setMessage('');

//     try {
//       await new Promise(resolve => setTimeout(resolve, 1000));
//       setChatHistory(prev => [...prev, {
//         message: "Thanks for your message! I'm here to help with your brand and social media needs.",
//         role: 'bot'
//       }]);
//     } catch (error) {
//       console.error('Error:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const chatStyle = {
//     position: 'fixed',
//     bottom: '20px',
//     right: '20px',
//     zIndex: 1000,
//     width: isOpen ? '350px' : 'auto'
//   };

//   const gradientBackground = {
//     background: 'linear-gradient(135deg, #3498db, #8e44ad)',
//     border: 'none'
//   };

//   return (
//     <Draggable disabled={!isOpen}>
//       <div style={chatStyle}>
//         {!isOpen ? (
//           <Button
//             onClick={() => setIsOpen(true)}
//             className="rounded-circle p-3"
//             style={{ ...gradientBackground, width: '60px', height: '60px' }}
//           >
//             <IoLogoWechat size={24} color="white" />
//           </Button>
//         ) : (
//           <Card className="shadow" style={{ height: '500px' }}>
//             {/* Header */}
//             <Card.Header 
//               style={gradientBackground} 
//               className="d-flex justify-content-between align-items-center text-white py-3"
//             >
//               <div className="d-flex align-items-center">
//                 <div className="bg-white bg-opacity-25 rounded-circle p-2 me-2">
//                   <IoLogoWechat size={24} />
//                 </div>
//                 <div>
//                   <h5 className="mb-0">Astral AI</h5>
//                   <small className="opacity-75">Always here to help</small>
//                 </div>
//               </div>
//               <Button
//                 variant="link"
//                 className="text-white p-0"
//                 onClick={() => setIsOpen(false)}
//               >
//                 <IoClose size={24} />
//               </Button>
//             </Card.Header>

//             {/* Chat Messages */}
//             <Card.Body 
//               className="p-3 overflow-auto" 
//               style={{ height: '360px' }}
//             >
//               {chatHistory.map((chat, index) => (
//                 <div
//                   key={index}
//                   className={`d-flex mb-3 ${chat.role === 'user' ? 'justify-content-end' : 'justify-content-start'}`}
//                 >
//                   <div
//                     className={`p-3 rounded-3 ${
//                       chat.role === 'user'
//                         ? 'bg-primary text-white'
//                         : 'bg-light'
//                     }`}
//                     style={{ 
//                       maxWidth: '80%',
//                       borderBottomRightRadius: chat.role === 'user' ? '0' : null,
//                       borderBottomLeftRadius: chat.role === 'bot' ? '0' : null
//                     }}
//                   >
//                     {chat.message}
//                   </div>
//                 </div>
//               ))}
//               {loading && (
//                 <div className="d-flex justify-content-start">
//                   <div className="bg-light p-3 rounded-3" style={{ borderBottomLeftRadius: 0 }}>
//                     <div className="d-flex gap-2">
//                       <div className="spinner-grow spinner-grow-sm" role="status" />
//                       <div className="spinner-grow spinner-grow-sm" role="status" />
//                       <div className="spinner-grow spinner-grow-sm" role="status" />
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </Card.Body>

//             {/* Input Area */}
//             <Card.Footer className="bg-white border-top-0 p-3">
//               <Form onSubmit={handleSendMessage}>
//                 <div className="d-flex gap-2">
//                   <Form.Control
//                     type="text"
//                     value={message}
//                     onChange={(e) => setMessage(e.target.value)}
//                     placeholder="Type a message..."
//                     disabled={loading}
//                   />
//                   <Button
//                     type="submit"
//                     disabled={!message.trim() || loading}
//                     style={gradientBackground}
//                   >
//                     <IoSend />
//                   </Button>
//                 </div>
//               </Form>
//             </Card.Footer>
//           </Card>
//         )}
//       </div>
//     </Draggable>
//   );
// };

// export default ChatBot;


import React, { useState } from 'react';
import { Container, Button, Form, Card } from 'react-bootstrap';
import { IoClose, IoSend, IoLogoWechat } from 'react-icons/io5';
import Draggable from 'react-draggable';
import { RiRobot2Fill } from "react-icons/ri";
import { RiRobot3Line } from "react-icons/ri";
import { API_URL } from "../../BackendApi/Api/config";

import axios from 'axios';

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [initialPos, setInitialPos] = useState(null);
  const [ai_preambleData, setAi_preambleData] = useState(null);
  const [chatHistory, setChatHistory] = useState([
    {
      message: "Hey there! Can I assist you today? Do you want some assistance with brand or social media posts?",
      role: "CHATBOT"
    }
  ]);
  let ai_preambleData2 = [
    {
      ai_preamble: "Hey there! Can I assist you today? Do you want some assistance with brand or social media posts?"
    },
    {
      ai_preamble: "Thanks for your message! I'm here to help with your brand and social media needs."
    }]



  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    setLoading(true);
    try {
      const result = await axios.post(
        'https://api.cohere.com/v1/chat',
        {
          message: message,
          chat_history: chatHistory,
          model: "command-r-08-2024",
          preamble: ai_preambleData?.map((item) => item?.ai_preamble).join(' ')
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer fLxSLabc6XoYUnxMV83fVYaz5ZGRzJSO5hA0a7v1`
          }
        }
      );

      setChatHistory([...result.data.chat_history]);
      setMessage("");
    } catch (error) {
      console.error("Error fetching chat response:", error);
      //   setChatHistory([
      //     ...chatHistory, 
      //     { message, role: "USER" },
      //     { message: "Sorry, I encountered an error. Please try again.", role: "CHATBOT" }
      //   ]);
    } finally {
      setLoading(false);
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragStop = (e, data) => {
    setIsDragging(false);
    setPosition({ x: data.x, y: data.y });
  };

  const handleButtonMouseDown = () => {
    setInitialPos(position);
  };

  const handleButtonMouseUp = () => {
    // Check if the position has changed during the drag
    if (initialPos && initialPos.x === position.x && initialPos.y === position.y) {
      setIsOpen(!isOpen);
    }
    setIsDragging(false); // Reset dragging state
  };

  const chatStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1000,
    width: isOpen ? '350px' : 'auto'
  };

  const gradientBackground = {
    background: 'linear-gradient(135deg, #3498db, #8e44ad)',
    border: 'none'
  };


  const getAiPreamble = async () => {
    try {
      const result = await fetch(`${API_URL}user/getAiPreamble`);
      const res = await result.json();
      setAi_preambleData(res.data);

    } catch (error) {
      alert(error.message)
    }
  };
  useState(() => {
    getAiPreamble()
  }, [])
  return (
    <Draggable
      position={position}
      onStart={handleDragStart}
      onStop={handleDragStop}
      bounds="parent"
      handle=".drag-handle"
    >
      <div style={chatStyle}>
        {!isOpen ? (
          <div className="drag-handle">
            <Button
              onMouseDown={handleButtonMouseDown}
              onMouseUp={handleButtonMouseUp}
              className="rounded-circle p-3"
              style={{ ...gradientBackground, width: '60px', height: '60px', cursor: 'move' }}
            >
              {/* <IoLogoWechat size={24} color="white" /> */}
              {/* <RiRobot2Fill size={32} color="cyan" /> */}
              <RiRobot3Line size={32} color="white" />



            </Button>
          </div>
        ) : (
          <Card className="shadow" style={{ height: '500px' }}>
            <Card.Header
              style={gradientBackground}
              className="d-flex justify-content-between align-items-center text-white py-3 drag-handle"
            >
              <div className="d-flex align-items-center">
                <div className="bg-white bg-opacity-25 rounded-circle p-2 me-2">
                  {/* <IoLogoWechat size={24} /> */}
                  <RiRobot3Line size={32} color="black" />

                </div>
                <div>
                  <h5 className="mb-0">Astral AI</h5>
                  <small className="opacity-75">Always here to help</small>
                </div>
              </div>
              <Button
                variant="link"
                className="text-white p-0"
                onClick={() => setIsOpen(false)}
              >
                <IoClose size={24} />
              </Button>
            </Card.Header>

            <Card.Body
              className="p-3 overflow-auto"
              style={{ height: '360px' }}
            >
              {chatHistory.map((chat, index) => (
                <div
                  key={index}
                  className={`d-flex mb-3 ${chat.role === 'USER' ? 'justify-content-end' : 'justify-content-start'}`}
                >
                  <div
                    className={`p-3 rounded-3 ${chat.role === 'USER'
                      ? 'bg-primary text-white'
                      : 'bg-light'
                      }`}
                    style={{
                      maxWidth: '85%',
                      borderBottomRightRadius: chat.role === 'USER' ? '0' : null,
                      borderBottomLeftRadius: chat.role === 'CHATBOT' ? '0' : null
                    }}
                  >
                    {chat.message}
                  </div>
                </div>
              ))}
              {loading && (
                <div className="d-flex justify-content-start">
                  <div className="bg-light p-3 rounded-3" style={{ borderBottomLeftRadius: 0 }}>
                    <div className="d-flex gap-2">
                      <div className="spinner-grow spinner-grow-sm" role="status" />
                      <div className="spinner-grow spinner-grow-sm" role="status" />
                      <div className="spinner-grow spinner-grow-sm" role="status" />
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>

            <Card.Footer className="bg-white border-top-0 p-3 drag-handle">
              <Form onSubmit={handleSendMessage}>
                <div className="d-flex gap-2">
                  <Form.Control
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type a message..."
                    disabled={loading}
                  />
                  <Button
                    type="submit"
                    disabled={!message.trim() || loading}
                    style={gradientBackground}
                  >
                    <IoSend />
                  </Button>
                </div>
              </Form>
            </Card.Footer>
          </Card>
        )}
      </div>
    </Draggable>
  );
};

const style = document.createElement('style');
style.textContent = `
  .drag-handle {
    cursor: move;
    user-select: none;
  }
  
  .drag-handle * {
    cursor: move;
  }
`;
document.head.appendChild(style);

export default ChatBot;













