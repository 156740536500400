import React, { useState, useContext, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { RxDragHandleDots2 } from "react-icons/rx";
import { FaTrash, FaYoutube, FaTextHeight, FaHeading, FaSpotify, FaPhone } from "react-icons/fa";
import { MdOutlineEvent } from "react-icons/md";

import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { HiViewGridAdd } from "react-icons/hi";
import { MdSmartButton } from "react-icons/md";
import { Link } from "react-router-dom";
import { useBuilderContext } from "../../../builderContext";

import { API_URL } from "./../../../../src/BackendApi/Api/config";
import useYouTubeTitle from "../../../Helpers/youtubeTitels";
import { ImPowerCord } from "react-icons/im";
import {UserContext} from "../../../UserContext"


export function Tab2() {
    const { myComponents, rearrangeComponents,setShowPoweredBy,showPoweredBy } = useBuilderContext();

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const startIndex = result.source.index;
        const endIndex = result.destination.index;

        if (startIndex === 0 && endIndex > 0) {
            rearrangeComponents(startIndex, endIndex);
        } else if (endIndex === 0) {
            // Prevent dragging a component above the first option
            return;
        } else {
            rearrangeComponents(startIndex, endIndex);
        }
    };

    return (
        <>
            <div className="styles__TabContent__Header themes_sec_header">
                <h3>Your Site Layout</h3>
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {myComponents.map((component, index) => (
                                <Draggable key={index + 1} draggableId={(index + 1).toString()} index={index} isDragDisabled={index === 0}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {component.type === "HEADER" ? (
                                                <Header data={component.properties} showStatus={component.showStatus} position={index} />
                                            ) : component.type === "TITLE" ? (
                                                <Title data={component.properties} showStatus={component.showStatus} label={component.properties.label} linkUrl={component.properties.link} position={index} />
                                            ) : component.type === "ICONS" ? (
                                                <SocialLink data={component.properties} showStatus={component.showStatus} position={index} />
                                            ) : component.type === "BUTTON" ? (
                                                <ButtonLink data={component.properties} showStatus={component.showStatus} linkUrl={component.properties.link} label={component.properties.lable} position={index} />
                                            ) : component.type === "TEXT" ? (
                                                <Text data={component.properties} showStatus={component.showStatus} position={index} label={component.properties.label} linkUrl={component.properties.link} />
                                            ) : component.type === "IMAGETEXT" ? (
                                                <Image data={component.properties} showStatus={component.showStatus} position={index} />
                                            ) : component.type === "YOUTUBELINK" ? (
                                                <YoutubeVideo data={component.properties} selectedValue={component.selectedValue} showStatus={component.showStatus} linkUrl={component.properties.link} position={index} />
                                            ) : component.type === "SPOTIFY" ? (
                                                <Spotify data={component.properties} showStatus={component.showStatus} linkUrl={component.properties.link} position={index} />
                                            ) : component.type === "IMAGEGRID" ? (
                                                <ImageGrid data={component.properties} showStatus={component.showStatus} position={index} />
                                            ) : component.type === "YOUTUBEIFRAME" ? (
                                                <YoutubeIframe data={component.properties} selectedValue={component.selectedValue} showStatus={component.showStatus} position={index} />
                                            ) : component.type === "CONTACTUS" ? (
                                                <Contact data={component.properties} showStatus={component.showStatus} position={index} />
                                            ) : component.type === "TESTIMONIAL" ? (
                                                <Testimonial data={component.properties} showStatus={component.showStatus} position={index} />
                                            ) : component.type === "EVENT" ? (
                                                <Event data={component.properties} showStatus={component.showStatus} position={index} />
                                            ) : null}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            < PoweredFooter />
        </>
    );
}


export function Header(props) {

    const { removeComponent, hideShow } = useBuilderContext();
    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/header", state: { data: props.data, position: props.position, changeBgclass: props.changeBgclass, showStatus: props.showStatus } }} className="block-link">
                    <div className="block_list">
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon">
                            <img src={require('../../../assets/images/Templates/product2.png')} alt="" />
                        </div>
                        <div className="block_name">
                            <h5>Profile Image / Logo</h5>
                        </div>
                    </div>
                </Link>
                {/* <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item">
                        <BsEyeFill />
                    </button>
                </div> */}
            </div>
        </>
    );
}


export function SocialLink(props) {
    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }

    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/editsocial", state: { data: props.data, position: props.position, showStatus: props.showStatus } }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon sociallink-block">
                            <HiViewGridAdd />
                        </div>
                        <div className="block_name">
                            <h5>Social Link</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }} >
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}

export function ButtonLink(props) {
    const { removeComponent, hideShow } = useBuilderContext();
    const [showEye, setShowEye] = useState(props.showStatus);
    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }


    return (
        <>
            <div className="header_block templates_list edit-block-content">
                {/* <Link to="/editbutton" className="block-link" state={{ from: "occupation" }}> */}
                <Link to={{ pathname: `/editbutton`, state: { data: props.data, position: props.position, showStatus: props.showStatus } }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon buttonlink-block">
                            <MdSmartButton />
                        </div>
                        <div className="block_name">
                            <h5>Button Links</h5>
                            <h5>{props.label}</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}


export function YoutubeVideo(props) {
    const { removeComponent, hideShow } = useBuilderContext();
    const [showEye, setShowEye] = useState(props.showStatus);
    // const [pageTitle, setPageTitle] = useState("");
    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }
    
    const [iframeLink, setIframeLink] = useState('');
    const pageTitle = useYouTubeTitle(iframeLink);


    useEffect(() => {
        if (props.data?.realLink || props?.linkUrl) {
            setIframeLink(props.data?.realLink || props?.linkUrl);  // Set iframe link from props
        }
    }, [props.data?.realLink, props?.linkUrl, iframeLink]); 

    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/edityoutube", state: { data: props.data, position: props.position, showStatus: props.showStatus ,selectedValue:props.selectedValue} }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon youtubevideo-block">
                            <FaYoutube />
                        </div>
                        <div className="block_name">
                            <h5>Youtube Video</h5>
                            <h5>{pageTitle.slice(0, 50)}</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}
export function YoutubeIframe(props) {
    const { removeComponent, hideShow } = useBuilderContext();
    const [showEye, setShowEye] = useState(props.showStatus);
    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }
 

    const [iframeLink, setIframeLink] = useState('');
    const pageTitle = useYouTubeTitle(iframeLink);


    useEffect(() => {
        if (props.data?.Link) {
            setIframeLink(props.data?.Link);  // Set iframe link from props
        }
    }, [props.data?.Link, iframeLink]); 
    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/edityoutubeiframe", state: { data: props.data, position: props.position, showStatus: props.showStatus ,selectedValue:props.selectedValue} }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon youtubevideo-block">
                            <FaYoutube />
                        </div>
                        <div className="block_name">
                            <h5>iframe</h5>
                            <h5>{pageTitle.slice(0, 50)}</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}


export function Image(props) {
    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }


    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/editimage", state: { data: props.data, position: props.position, showStatus: props.showStatus } }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon">
                            <img src={require('../../../assets/images/Templates/product2.png')} alt="" />
                        </div>
                        <div className="block_name">
                            <h5>Image</h5>
                            <h5>{props.data?.imageCaption}</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}


export function Text(props) {

    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const [textContent, setTextContent] = useState("");
    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }
    useEffect(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(props.data.html, "text/html");

        // Extract the text content
        const extractedText = doc.body.textContent;
        setTextContent(extractedText);
    }, [props.data,]);



    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/edittext", state: { data: props.data, position: props.position, showStatus: props.showStatus } }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon text-block">
                            <FaTextHeight />
                        </div>
                        <div className="block_name">
                            <h5>Text</h5>
                            <h5>{textContent.slice(0, 30)}</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}


export function ImageGrid(props) {
    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }
    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/editimagegrid", state: { data: props.data, position: props.position, showStatus: props.showStatus } }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon imagegrid-block">
                            <HiViewGridAdd />
                        </div>
                        <div className="block_name">
                            <h5>Image Links Grid</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}


export function SubHeadding(props) {
    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye((prevShowEye) => !prevShowEye);
        hideShow(id, !showEye);
    };

    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to="/editsubheading" className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`}>
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon subheadding-block">
                            <FaHeading />
                        </div>
                        <div className="block_name">
                            <h5>Sub Heading</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}



export function Title(props) {

    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye((prevShowEye) => !prevShowEye);
        hideShow(id, !showEye);
    };

    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/editsubheading", state: { data: props.data, position: props.position, showStatus: props.showStatus } }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`}>
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon subheadding-block">
                            <FaHeading />
                        </div>
                        <div className="block_name">
                            <h5>Title</h5>
                            <h5>{props.data.headding}</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }
                    }>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}


export function Spotify(props) {

    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }
    const [pageTitle, setPageTitle] = useState("");
    console.log("props1", props);
    useEffect(() => {
        if (props.data) {

            const fetchYouTubeTitle = async () => {
                const youtubeUrl = props.data?.realLink || props?.linkUrl
                let get = youtubeUrl?.replace("/embed/track/", "/track/")
                try {
                    const response = await fetch(`${API_URL}flowpage/getyoutubeurl?keyword=${get}`);
                    if (response.status === 200) {
                        const result = await response.json(); // Parse the JSON response

                        if (result.status) {
                            console.log("youtube details", result.data);
                            setPageTitle(result.data.title || ''); // Use title from data or empty string if not found
                        } else {
                            console.log("youtube details", result.message);
                            setPageTitle(''); // Set to empty string if status is false
                        }
                    }
                } catch (error) {
                    console.error("Error:", error.message);
                }
            };
            fetchYouTubeTitle();
        }
    }, [props.data?.realLink]);
    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/editspotify", state: { data: props.data, position: props.position, showStatus: props.showStatus } }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon youtubevideo-block">
                            <FaSpotify />
                        </div>
                        <div className="block_name">
                            <h5>Spotify Link</h5>
                            <h5>{pageTitle.slice(0, 48)}</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}

export function Contact(props) {

    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye((prevShowEye) => !prevShowEye);
        hideShow(id, !showEye);
    }


    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/editcontact", state: { data: props.data, position: props.position, showStatus: props.showStatus } }} className="block-link">
                    <div className="block_list">
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon contact-block">
                            <FaPhone />
                        </div>
                        <div className="block_name">
                            <h5>Contact</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}

export function Event(props) {

    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye((prevShowEye) => !prevShowEye);
        hideShow(id, !showEye);
    }


    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/editevent", state: { data: props.data, position: props.position, showStatus: props.showStatus } }} className="block-link">
                    <div className="block_list">
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon contact-block">
                            <MdOutlineEvent />

                        </div>
                        <div className="block_name">
                            <h5>Event</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}

export function Testimonial(props) {

    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye((prevShowEye) => !prevShowEye);
        hideShow(id, !showEye);
    }


    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: "/edittestimonials", state: { data: props.data, position: props.position, showStatus: props.showStatus } }} className="block-link">
                    <div className="block_list">
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon contact-block">
                            <img src="https://cdn-icons-png.flaticon.com/512/2013/2013639.png" alt="Testimonial" />
                        </div>
                        <div className="block_name">
                            <h5>Testimonial</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}

export function PoweredFooter(props) {

    const { changePoweredStatus,showPoweredBy } = useBuilderContext();
    const {subscriptionDetails,subscriptionStatus} = useContext(UserContext)


    const [showEye, setShowEye] = useState(true);

    // const eyeChange = (id) => {
    //     setShowEye((prevShowEye) => !prevShowEye);
    //     hideShow(id, !showEye);
    // }


    return (
        <>
            <div className="header_block templates_list edit-block-content">
                
                    <div className="block_list">
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon contact-block">
                            <ImPowerCord />

                        </div>
                        <div className="block_name">
                            <h5>Powered Footer</h5>
                        </div>
                    </div>
                
                <div className="block_btn">
                    {/* <button className="btn block_btn_item" onClick={
                        () => {
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button> */}
                    <button disabled={!(subscriptionStatus == 'active' && subscriptionDetails.items.data[0].plan.product == 'prod_PHhHX8X2q53c9m')} className="btn block_btn_item" 
                    onClick={() => changePoweredStatus()}
                    >
                        {showPoweredBy ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}