import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form,Image  } from 'react-bootstrap';
import './EventCategories.css';

const API_URL = process.env.REACT_APP_API_URL;

function EventCategoriesPage() {
    // State management
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState({
        category_name: '',
        category_description: '',
        category_slug: '',
        banner_image: null,
        category_logo: null
    });
    const [isEditing, setIsEditing] = useState(false);
    const [previewUrls, setPreviewUrls] = useState({
        banner_image: null,
        category_logo: null
    });

    // Fetch categories
    const fetchCategories = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}/event/getAllEventCategories`);
            setCategories(response.data.data);
        } catch (error) {
            console.log(error);
            // alert(`Failed to fetch categories: ${error.response?.data?.message || 'An error occurred'}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Initialize fetch on component mount
    useEffect(() => {
        fetchCategories();
    }, []);

    // Handle input changes
    const generateSlug = (input) => {
        return input
            .toLowerCase()           // Convert to lowercase
            .trim()                  // Remove leading/trailing whitespace
            .replace(/[^\w\s-]/g, '') // Remove special characters except spaces and hyphens
            .replace(/[\s_]+/g, '-') // Replace spaces and underscores with a single hyphen
            .replace(/-+/g, '-')     // Replace multiple hyphens with a single hyphen
            .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'category_name'
            //  && !isEditing
            ) {
            // Automatically generate slug when editing category name
            const slug = generateSlug(value);

            setCurrentCategory(prev => ({
                ...prev,
                category_name: value,
                category_slug: slug
            }));
        } 
        else {
            // For other fields, use standard input handling
            setCurrentCategory(prev => ({
                ...prev,
                [name]: name === 'category_slug' ? generateSlug(value) : value
            }));
        }
    };

    // Handle file input changes
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        setCurrentCategory(prev => ({
            ...prev,
            [name]: file
        }));

        // Create preview URL
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrls(prev => ({
                    ...prev,
                    [name]: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    // Submit form (Create/Update)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Create FormData for file upload
        const formData = new FormData();
        Object.keys(currentCategory).forEach(key => {
            if (currentCategory[key] !== null) {
                formData.append(key, currentCategory[key]);
            }
        });

        try {
            if (isEditing) {
                // Update existing category
                await axios.put(`${API_URL}/event/evetcategory/${currentCategory._id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                alert('Category updated successfully');
            } else {
                // Create new category
                await axios.post(`${API_URL}/event/createEventCategory`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                alert('Category created successfully');
            }

            // Reset form and fetch updated list
            resetForm();
            fetchCategories();
            setIsModalOpen(false);
        } catch (error) {
            alert(isEditing
                ? `Failed to update category: ${error.response?.data?.message || 'An error occurred'}`
                : `Failed to create category: ${error.response?.data?.message || 'An error occurred'}`
            );
        } finally {
            setIsLoading(false);
        }
    };

    // Edit category
    const handleEdit = (category) => {
        setCurrentCategory(category);
        setPreviewUrls({
            banner_image: `${API_URL}/eventcategory/${category.banner_image}` || null,
            category_logo: `${API_URL}/eventcategory/${category.category_logo}` || null
        });
        setIsEditing(true);
        setIsModalOpen(true);
    };

    // Delete category
    const handleDelete = async (slug) => {
        if (!window.confirm('Are you sure you want to delete this category?')) return;

        setIsLoading(true);
        try {
            await axios.delete(`${API_URL}/event/evetcategory/${slug}`);
            alert('Category deleted successfully');
            fetchCategories();
        } catch (error) {
            alert(`Failed to delete category: ${error.response?.data?.message || 'An error occurred'}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Reset form to initial state
    const resetForm = () => {
        setCurrentCategory({
            category_name: '',
            category_description: '',
            category_slug: '',
            banner_image: null,
            category_logo: null
        });
        setPreviewUrls({
            banner_image: null,
            category_logo: null
        });
        setIsEditing(false);
    };

    // Open modal for new category
    const openNewCategoryModal = () => {
        resetForm();
        setIsModalOpen(true);
    };

    return (
        <div className='main-container'>
            <div className="container mt-4">
                <div className="row">
                    <   div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h3 className="mb-0">Event Categories</h3>
                                <Button variant="primary" onClick={openNewCategoryModal}>
                                    Add Category
                                </Button>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Slug</th>
                                                <th>Description</th>
                                                <th>Banner Image</th>
                                                <th>Category Logo</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categories.map(category => (
                                                <tr key={category.category_slug}>
                                                    <td>{category.category_name}</td>
                                                    <td>{category.category_slug}</td>
                                                    <td>{category.category_description}</td>
                                                    <td>
                                                        {category.banner_image && (
                                                            <Image
                                                                src={`${API_URL}/eventcategory/${category.banner_image}`}
                                                                thumbnail
                                                                style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        {category.category_logo && (
                                                            <Image
                                                                src={`${API_URL}/eventcategory/${category.category_logo}`}
                                                                thumbnail
                                                                style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="btn-group" role="group">
                                                            <Button
                                                                variant="warning"
                                                                size="sm"
                                                                onClick={() => handleEdit(category)}
                                                            >
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                size="sm"
                                                                onClick={() => handleDelete(category.category_slug)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Right-side Modal */}
                        <Modal
                            show={isModalOpen}
                            onHide={() => setIsModalOpen(false)}
                        // dialogClassName="modal-right"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {isEditing ? 'Edit Category' : 'Create New Category'}
                                </Modal.Title>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Category Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="category_name"
                                            value={currentCategory.category_name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Category Slug</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="category_slug"
                                            value={currentCategory.category_slug}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="category_description"
                                            value={currentCategory.category_description}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Banner Image</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="banner_image"
                                            onChange={handleFileChange}
                                            accept="image/*"
                                        />
                                        {previewUrls.banner_image && (
                                            <img
                                                src={previewUrls.banner_image}
                                                alt="Banner Preview"
                                                className="img-thumbnail mt-2"
                                                style={{ maxHeight: '200px' }}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Category Logo</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="category_logo"
                                            onChange={handleFileChange}
                                            accept="image/*"
                                        />
                                        {previewUrls.category_logo && (
                                            <img
                                                src={previewUrls.category_logo}
                                                alt="Logo Preview"
                                                className="img-thumbnail mt-2"
                                                style={{ maxHeight: '200px' }}
                                            />
                                        )}
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                                        Close
                                    </Button>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        {isEditing ? 'Update Category' : 'Create Category'}
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        {/* Loading Overlay */}
                        {isLoading && (
                            <div className="loading-overlay">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventCategoriesPage;