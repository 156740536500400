import React, { useRef } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins';
const SunEditors = ({ description, handleParkingChange }) => {
    const editorInstance = useRef(null);
    const handleEditorChange = (content) => {
        handleParkingChange(content);  
    };
    const getSunEditorInstance = (sunEditor) => {
        editorInstance.current = sunEditor;
        if (description && sunEditor.getContents() !== description) {
            sunEditor.setContents(description);
        }
    };
    return (
        <div>
            <SunEditor
                setAllPlugins={true}
                getSunEditorInstance={getSunEditorInstance}
                setContents={description} 
                onChange={handleEditorChange} 
                setOptions={{
                    height: "150px",
                    plugins: plugins,
                    buttonList: [
                        ['undo', 'redo'],
                        ['font', 'fontSize', 'formatBlock'],
                        ['paragraphStyle', 'blockquote'],
                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                        ['fontColor', 'hiliteColor'],
                        ['removeFormat', 'link'],
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                    ],
                }}
            />
        </div>
    );
};

export default SunEditors;
