import React, { useState, useContext } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Pattern1 from "../../assets/images/icons/Pattern1.png";
import Pattern2 from "../../assets/images/icons/Pattern2.webp";
import Pattern3 from "../../assets/images/icons/Pattern3.png";
import Pattern4 from "../../assets/images/icons/Pattern4.png";
import Pattern5 from "../../assets/images/icons/Pattern5.png";
import { TbLayoutGridAdd } from "react-icons/tb";
import { TemplateContext } from "../../TemplateContext";

function Choosepattern() {

    const [pattern, setPattern, eye, setEye, logo, setLogo, frame, setFrame, patternColorType, setPatternColorType, patternDotsColor, setPatternDotsColor, patternGradientColor, setPatternGradientColor, eyeBallColor, setEyeBallColor, EyeCenterColor, setEyeCenterColor, backgroundcolortype, setBackgroundcolortype, backgroundcolorcode, setBackgroundcolorcode, height, setHeight, width, setWidth, eyeinside, setEyeinside] = useContext(TemplateContext);

    return (
        <>
            <Accordion.Header><TbLayoutGridAdd />Choose patterns</Accordion.Header>
            <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicEmail" >
                    <Form.Label>Data Patterns</Form.Label>
                    <div className="d-flex align-items-center flex-wrap ">
                        <div className="me-4">
                            <button className={`Qr-pattern ${pattern === "square" ? 'active' : ''}`}>
                                <img src={Pattern1} onClick={() => setPattern("square")} alt="Qr" className="img-fluid" />
                            </button>
                        </div>
                        <div className="me-4">
                            <button className={`Qr-pattern ${pattern === "dots" ? 'active' : ''}`}>
                                <img src={Pattern2} onClick={() => setPattern("dots")} alt="Qr" className="img-fluid" />
                            </button>
                        </div>
                        <div className="me-4">
                            <button className={`Qr-pattern ${pattern === "rounded" ? 'active' : ''}`}>
                                <img src={Pattern3} onClick={() => setPattern("rounded")} alt="Qr" className="img-fluid" />
                            </button>
                        </div>
                        <div className="me-4">
                            <button className={`Qr-pattern ${pattern === "extra-rounded" ? 'active' : ''}`}>
                                <img src={Pattern4} onClick={() => setPattern("extra-rounded")} alt="Qr" className="img-fluid" />
                            </button>
                        </div>
                        <div className="me-4">
                            <button className={`Qr-pattern ${pattern === "classy" ? 'active' : ''}`}>
                                <img src={Pattern5} onClick={() => setPattern("classy")} alt="Qr" className="img-fluid" />
                            </button>
                        </div>
                    </div>
                </Form.Group>
            </Accordion.Body>
        </>
    )
}

export default Choosepattern;
