import React, { useContext } from 'react'
import { eventCategoryContext } from '../context/eventCategoryContext';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const useEventCategoryActions = () => {

    const { eventCategorydispatch } = useContext(eventCategoryContext);

    const getAllEventCategories = async () => {

        try {
            eventCategorydispatch({ type: "FETCHING_EVENT_CATEGORIES", payload: true });
            const response = await axios.get(`${API_URL}/event/getAllEventCategories`)
            const data = response.data.data
            eventCategorydispatch({ type: "SET_EVENT_CATEGORIES", payload: data });
            eventCategorydispatch({ type: "FETCHING_EVENT_CATEGORIES", payload: false });

        } catch (error) {
            console.log(error);
            eventCategorydispatch({ type: "FETCHING_EVENT_CATEGORIES", payload: false });

        }

    }


    return {
        getAllEventCategories
    }
}

export default useEventCategoryActions