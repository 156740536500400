import React from "react";

export default function EventParking({ data }) {
    const description = data?.parkingDetails || "No description available";  // Default to a string

    return (
        <div style={{ backgroundColor: "transparent", padding: "20px", color: "white" }}>
            <h3 style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#fff",
                marginBottom: "10px"
            }}>
                {data?.heading || "Parking"}
            </h3>
            <div
                style={{
                    color: "#fff",  // Ensure the text is white
                    backgroundColor: "transparent",
                    whiteSpace: "pre-wrap",  // Ensure line breaks are respected
                    wordWrap: "break-word",  // Break long words if needed
                }}
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </div>
    );
}
