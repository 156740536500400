import React from 'react';
import { tagContext } from '../context/tagContext';


const API_URL = process.env.REACT_APP_API_URL;

const useTagsActions = () => {

  const { tagData, tagdispatch } = React.useContext(tagContext);
  const getAllTags = async () => {
    const user = localStorage.getItem('user');
    
    if (!user) {
      console.error("User is not logged in");
      return;
    }
    
    const owner_id = JSON.parse(user)._id;

    try {
      const response = await fetch(`${API_URL}/tags/getalltags`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ owner_id }), // Correctly stringify the body
      });

    
      const result = await response.json();



      if(result.success) {
        tagdispatch({ type: 'SET_TAGS', payload: result.data });
      }
     

    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  return { getAllTags };
};

export default useTagsActions;
