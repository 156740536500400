import React, { useState, useContext, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { RxDragHandleDots2 } from "react-icons/rx";
import { FaTrash, FaYoutube, FaTextHeight, FaHeading, FaSpotify, FaPhone } from "react-icons/fa";
import { MdOutlineEvent } from "react-icons/md";

import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { HiViewGridAdd } from "react-icons/hi";
import { MdSmartButton } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { useBuilderContext } from "../../../builderContext";

import { API_URL } from "./../../../../src/BackendApi/Api/config";
import { HeadingList } from "../AddBlockList/Header";

export function Tab2() {
    const { myComponents, rearrangeComponents } = useBuilderContext();
    const { id } = useParams();
    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const startIndex = result.source.index;
        const endIndex = result.destination.index;

        if (startIndex === 0 && endIndex > 1) {
            rearrangeComponents(startIndex, endIndex);
        } else if (endIndex === 0 || endIndex === 1) {
            // Prevent dragging a component above the first option
            return;
        }   else {
            rearrangeComponents(startIndex, endIndex);
        }
        
    };

    return (
        <>
            <div className="styles__TabContent__Header themes_sec_header">
                <h3>Your Site Layout</h3>
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {myComponents.map((component, index) => (
                                <Draggable key={index + 1} draggableId={(index + 1).toString()} index={index} isDragDisabled={component.type === "EVENT" || component.type === "HEADER"} 
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {component.type === "EVENT" ? (
                                                <Event id={id} data={component.properties} showStatus={component.showStatus} position={index} />
                                            ) :
                                                component.type === "AGENTA" ? (
                                                    <Agenta id={id} data={component.properties} showStatus={component.showStatus} position={index} />
                                                ) :
                                                    component.type === "PARKING" ? (
                                                        <Parking id={id} data={component.properties} showStatus={component.showStatus} position={index} />
                                                    ) :
                                                        component.type === "GALLERY" ? (
                                                            <Gallery id={id} data={component.properties} uniqueId={component.unique_id} showStatus={component.showStatus} position={index} />
                                                        ) :
                                                            component.type === "IMAGES" ? (
                                                                <ImageList id={id} data={component.properties} uniqueId={component.unique_id} showStatus={component.showStatus} position={index} />
                                                            ) :
                                                                    component.type === "HEADER" ? (
                                                                        <Header id={id} data={component.properties}  uniqueId={component.unique_id} showStatus={component.showStatus} position={index} />
                                                            ) :
                                                                null}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}


export function Header(props) {
    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: `/editEvent/${props.id}/Header`, search: `?unique_id=${props.data.unique_id}&&position=${props.position}`, }} className="block-link">
                    <div className="block_list">
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon">
                            <img src={require('../../../assets/images/Templates/product2.png')} alt="" />
                        </div>
                        <div className="block_name">
                            <h5>Event Header</h5>
                        </div>
                    </div>
                </Link>
               
            </div>
        </>
    );
}

export function Event(props) {

    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye((prevShowEye) => !prevShowEye);
        hideShow(id, !showEye);
    }


    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: `/editevent/${props.id}/eventinfo`, state: { data: props.data, position: props.position, showStatus: props.showStatus } }} className="block-link">
                    <div className="block_list">
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon contact-block">
                            <MdOutlineEvent />

                        </div>
                        <div className="block_name">
                            <h5>Event</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}



export function ImageList(props) {
    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }

    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{
                    pathname: `/editEvent/${props.id}/images`,
                    search: `?unique_id=${props.data.unique_id}&&position=${props.position}`,
                }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon">
                            <img src={require('../../../assets/images/Templates/product2.png')} alt="" />
                        </div>
                        <div className="block_name">
                            <h5>Image</h5>
                            {/* <h5>{props.data?.imageCaption}</h5> */}
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    {/* <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button> */}
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}


export function Agenta(props) {

    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const [textContent, setTextContent] = useState({});
    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }
    useEffect(() => {
        let datalist = props.data.agendaItems
        console.log("datalist", datalist);
        setTextContent(datalist);
    }, [props.data,]);



    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: `/editEvent/${props.id}/agenta`, search: `?unique_id=${props.data.unique_id}&&position=${props.position}`, }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon text-block">
                            <FaTextHeight />
                        </div>
                        <div className="block_name">
                            <h5>Agenta</h5>
                            {/* <p
                                style={{
                                    color: "black",  // Ensure the text is white
                                    backgroundColor: "black",
                                    whiteSpace: "pre-wrap",  // Ensure line breaks are respected
                                    wordWrap: "break-word",  // Break long words if needed
                                }}
                                dangerouslySetInnerHTML={{ __html: textContent?.description?.slice(0, 30) }}
                            /> */}
                            {/* <h5>{textContent?.description?.slice(0, 30)}</h5> */}
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    {/* <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button> */}
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}
export function Parking(props) {

    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const [textContent, setTextContent] = useState([]);
    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }
    useEffect(() => {
        let dataList = props.data.parkingDetails
        setTextContent(dataList);
    }, [props.data,]);


    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: `/editEvent/${props.id}/parking`, search: `?unique_id=${props.data.unique_id}&&position=${props.position}`, }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon text-block">
                            <FaTextHeight />
                        </div>
                        <div className="block_name">
                            <h5>Parking</h5>
                            {/* <h5>{textContent?.slice(0, 30)}</h5> */}
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    {/* <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button> */}
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}


export function Gallery(props) {
    const { removeComponent, hideShow } = useBuilderContext();

    const [showEye, setShowEye] = useState(props.showStatus);

    const eyeChange = (id) => {
        setShowEye(!showEye);
        hideShow(id, !showEye);

    }
    return (
        <>
            <div className="header_block templates_list edit-block-content">
                <Link to={{ pathname: `/editEvent/${props.id}/gallery`, search: `?unique_id=${props.data.unique_id}&&position=${props.position}` }} className="block-link">
                    <div className={`block_list ${showEye ? 'opicity' : 'opicity-change'}`} >
                        <div className="block_icon">
                            <RxDragHandleDots2 />
                        </div>
                        <div className="block_image_icon block_svg_icon imagegrid-block">
                            <HiViewGridAdd />
                        </div>
                        <div className="block_name">
                            <h5>Image Links Grid</h5>
                        </div>
                    </div>
                </Link>
                <div className="block_btn">
                    <button className="btn block_btn_item" onClick={
                        () => {
                            console.log(props.position);
                            removeComponent(props.position);

                        }}>
                        <FaTrash />
                    </button>
                    <button className="btn block_btn_item" onClick={() => eyeChange(props.position)}>
                        {showEye ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                </div>
            </div>
        </>
    );
}





