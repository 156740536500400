
export function eventCategoryReducer(state, action) {
    switch (action.type) {
        case 'SET_EVENT_CATEGORIES':
            return {
                ...state,
                categories: [...action.payload],
            }

        case 'FETCHING_EVENT_CATEGORIES':
            return {
                ...state,
                isFetching: action.payload,
        }

        default:

            return state

    }
}
