import React, { useEffect, useState } from "react";
import { MdTitle } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { useBuilderContext } from "../../../builderContext";
import { v4 as uuidv4 } from "uuid";
import { uniqueId } from "react-bootstrap-typeahead/types/utils";
import { uploadSingleImageApi } from "../../../BackendApi/Api/flowpageApi";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import SpinnerLoader from "../../../Components/SpinnerLoader/SpinnerLoader";
import { API_URL } from "../../../BackendApi/Api/config";
export function ImagesList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdTitle />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Images</h2>
                            <p className="add-block-btn-text-desc">Add a Images to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}





export function ImagesAdd() {
    const { id } = useParams();
    const location = useLocation();
    const { addComponent, editComponent, myComponents } = useBuilderContext();

    const unique_id = new URLSearchParams(location.search).get("unique_id");
    const position = new URLSearchParams(location.search).get("position");
    const [loading, setLoading] = useState(false);
    const [imageProperties, setImageProperties] = useState({
        imagefile: null,
        unique_id: "",
    });

    useEffect(() => {
        if (unique_id) {
            const existingImage = myComponents
                .filter((c) => c.type === "IMAGES")
                .map((c) => c.properties)
                .find((c) => c.unique_id === unique_id);

            if (existingImage) {
                setImageProperties((prev) => ({
                    ...prev,
                    imagefile: existingImage.imagefile,
                    unique_id: unique_id,
                }));
            }
        }
    }, [unique_id, myComponents]);



    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            PNotify.error({
                title: "Error",
                text: "No file selected. Please choose an image.",
            });
            return;
        }

        const data = new FormData();
        data.append("image", file);
        setLoading(true);

        uploadSingleImageApi(data)
            .then((res) => {
                if (res.code === 200 && res.data.status === true) {
                    const updatedFilename = res.data.data.filename;

                    setImageProperties((prev) => ({
                        ...prev,
                        imagefile: updatedFilename,
                        unique_id: unique_id || Date.now() + uuidv4(),
                    }));

                    PNotify.success({
                        title: "Success",
                        delay: 3000,
                        text: "Image uploaded successfully",
                    });
                } else {
                    PNotify.error({
                        title: "Error",
                        text: "Image upload failed. Please try again.",
                    });
                }
            })
            .catch(() => {
                PNotify.error({
                    title: "Error",
                    text: "Image upload failed. Please try again.",
                });
            })
            .finally(() => setLoading(false));
    };


    const addImageData = () => {
        if (!imageProperties.imagefile) {
            PNotify.error({
                title: "Error",
                text: "Please upload an image before saving.",
                delay: 3000,
            });
            return;
        }

        if (unique_id) {
            editComponent(position, "IMAGES", imageProperties, true);
        } else {
            addComponent("IMAGES", imageProperties, true);
        }

        PNotify.success({
            title: unique_id ? "Edited Successfully" : "Added Successfully",
            text: unique_id ? "Image updated successfully" : "Image added successfully",
            delay: 3000,
        });
    };
    let goBacks = `/editEvent/${id}/${unique_id ? "layout" : "addBlock"}`;

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to={goBacks}>
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label
                            htmlFor
                            color="grayDarker"
                            className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM"
                        >
                            {unique_id ? "Edit Image" : "Add Image"}
                        </label>
                    </div>
                </div>

                <div className="form_block iuEvSC">
                    <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        onChange={handleFileChange}
                    />
                </div>

                {/* Image Preview */}
                {imageProperties?.imagefile && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            backgroundColor: "#f9f9f9", // Slight background for contrast
                            maxWidth: "100%",
                            width: "400px", // Set a max width for the preview container
                            margin: "0 auto",
                        }}
                    >
                        <img
                            src={
                                (imageProperties.imagefile.startsWith("http")??imageProperties.imagefile.startsWith("https"))
                                    ? imageProperties.imagefile
                                    : API_URL + "flowpage/" + imageProperties.imagefile
                            }
                            alt="Uploaded"
                            style={{
                                maxWidth: "100%",
                                maxHeight: "400px",
                                objectFit: "contain",
                                borderRadius: "8px",
                            }}
                        />
                    </div>
                )}
            </div>

            <div className="add-block-btn" style={{ marginTop: 20 }}>
                {loading ? (
                    <SpinnerLoader />
                ) : (
                    <button
                        className="btn publish-btn"
                        onClick={addImageData}
                        disabled={loading}
                    >
                        {unique_id ? "Edit" : "Add"}
                    </button>
                )}
            </div>
        </>
    );
}

