import React from "react";

const CardLoading = () => {
  return (
    <div className="section-body d-flex align-items-start">
      {/* Left Body - Placeholder for Image */}
      <div className="left-body">
        <div
          className="placeholder-glow"
          style={{
            width: "80px",
            height: "80px",
            backgroundColor: "#e9ecef",
            borderRadius: "50%",
          }}
        ></div>
      </div>

      {/* Right Body */}
      <div className="right-body ms-2 w-100">
        {/* Placeholder for Title */}
        <div className="placeholder-glow">
          <div
            className="placeholder col-8"
            style={{ height: "20px", backgroundColor: "#e9ecef" }}
          ></div>
        </div>

        {/* Placeholder for Subtext */}
        <div className="placeholder-glow mt-2">
          <div
            className="placeholder col-10"
            style={{ height: "15px", backgroundColor: "#e9ecef" }}
          ></div>
          <div
            className="placeholder col-6 mt-1"
            style={{ height: "15px", backgroundColor: "#e9ecef" }}
          ></div>
        </div>

        {/* Placeholder for Badge and Avatars */}
        <div className="d-flex align-items-center gap-2 mt-3">
          <div
            className="placeholder rounded-pill"
            style={{
              width: "60px",
              height: "20px",
              backgroundColor: "#e9ecef",
            }}
          ></div>
          <div className="d-flex gap-2">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className="placeholder-glow"
                style={{
                  width: "16px",
                  height: "16px",
                  backgroundColor: "#e9ecef",
                  borderRadius: "50%",
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardLoading;
