import React from 'react'
import { useBuilderContext } from '../../builderContext';
import BuilderHeader from "../../PageBuilder/Components/TemplateView/BuilderHeader";
import BuilderTitle from "./flexComponents/BuilderTitle";
import BuilderIcon from "./flexComponents/BuilderIcon";
import BuilderButton from "./flexComponents/BuilderButton";
import BuilderText from "./flexComponents/BuilderText";
import ImageText from "./flexComponents/ImageText";
import YoutubeLink from "./flexComponents/YoutubeLink";
import Spotify from "./flexComponents/Spotify";
import ImageGrid from "./flexComponents/ImageGrid";
import Testimonials from "./flexComponents/Testimonials";
import ContactUs from "./flexComponents/ContactUs";
import Event from "./flexComponents/Event";
import YoutubeIframe from "./flexComponents/YoutubeIframe";
import { useParams } from "react-router-dom";
import { AiOutlineMore } from "react-icons/ai";
import SocialSharePage from "../../Components/share/SocialShare";
const Flexpages2 = ({ renderComponent, backgroundStyle, url, smartlink_id, }) => {
    let { color, myComponents, showPoweredBy, fontFamily } = useBuilderContext();
 
    return (
        <div>
            <div
                className={"preview-box-background"}
                style={backgroundStyle}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <SocialSharePage
                    type="portfolio"
                    url={url.href}
                    title="Check out this Page"
                    description="I found this great website and thought you might like it too."
                    />
                    </div>
                <div className={`page_builder_design_body home-preview-body`}  >

                    <div className={`builder-box-design public_flex_design goWHMr ${color}`} style={{fontFamily: fontFamily}}>

                        <div className="builder_content">
                            {myComponents.map((component, index) => (
                                <React.Fragment key={index}>
                                    <div style={{ position: "relative", zIndex: 1, color: "white" }}>
                                        {renderComponent(component, index, false)}
                                    </div>

                                </React.Fragment>
                            ))}
                            {showPoweredBy &&
                                <div className="company_name">
                                    <h5>Powered with Astral</h5>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Flexpages2
