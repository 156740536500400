import React, { useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { EventGridList } from "../AddBlockList/EventGridList";
import { HeadingList } from "../AddBlockList/Header";
import { ImagesList } from "../AddBlockList/images";
import { GalleryList } from "../AddBlockList/Gallery";
import { AgentaList } from "../AddBlockList/Agenta";
import { ParkingList } from "../AddBlockList/Parking";

const Tab3 = () => {
    const location= useLocation();
    let id = location.pathname.split("/")[2]
  

    return (
        <>
            <div className="styles__TabContent__Header themes_sec_header">
                <h3>Add Block</h3>
            </div>
            <div className="templates_list">
                <label
                    htmlFor="essentials"
                    className="style__Label-bufferapp-ui__sc-153wil8-5 dPYltt"
                >
                    Essentials
                </label>
                <div className="add_block_list">
                    {/* <Link to={`/editEvent/${id}/heading`}>
                        <HeadingList />
                    </Link> */}
                    <Link to={`/editEvent/${id}/images`}>
                        <ImagesList />
                    </Link>
                    <Link to={`/editEvent/${id}/gallery`}>
                        <GalleryList />
                    </Link>
                    <Link to={`/editEvent/${id}/parking`}>
                        <ParkingList />
                    </Link>
                    <Link to={`/editEvent/${id}/agenta`}>
                        <AgentaList />
                    </Link>
                </div>
            </div>
            {/* <div className="templates_list">
                <label
                    htmlFor="events"
                    className="style__Label-bufferapp-ui__sc-153wil8-5 dPYltt"
                >
                    Events
                </label> */}
                {/* <div className="add_block_list">
                    <Link to={`/editEvent/${id}/addevent`}>
                        <EventGridList />
                    </Link>
                </div> */}
            {/* </div> */}
        </>
    );
};

export default Tab3;
