import React, { useCallback, memo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const GoogleMaps = ({ 
  center = { lat: -34.397, lng: 150.644 }, 
  zoom = 8, 
  markers = [] 
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "AIzaSyAt5m5uNR4aEfvr9Bq2k3a5rDHg9IT1ml0",
    libraries: ["places"]
  });

  const mapContainerStyle = {
    width: "100%",
    height: "200px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)"
  };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: false
  };

  const renderMap = useCallback(() => {
    return (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={zoom}
        options={mapOptions}
      >
        {markers.map((marker, index) => (
          <MarkerF
            key={index}
            position={{ 
              lat: Number(marker.lat), 
              lng: Number(marker.lng) 
            }}
            title={marker.title || "Event Location"}
          />
        ))}
      </GoogleMap>
    );
  }, [center, zoom, markers]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading map...</div>;

  return renderMap();
};

export default memo(GoogleMaps);