import React, { useState } from "react";
import { Form } from "react-bootstrap";
import SpinnerLoader from "./../../Components/SpinnerLoader/SpinnerLoader";
const WebhookForm = () => {
    const [webhook_url, setWebhook_url] = useState("");
    const [loading, setLoading] = useState(false);
    const [webhooks, setWebhooks] = useState(false);

    const handleAddWebhooks = () => {
        setLoading(true);
        // Simulate an API call
        setTimeout(() => {
            setLoading(false);
            setWebhooks(true);
            alert(`Webhook ${webhooks ? "updated" : "added"}: ${webhook_url}`);
        }, 2000);
    };

    return (
        <div className="styles__TabContent themes_sec" style={{ margin: "0px 20px" }}>
            <div className="col-md-12">
                <div className="content-body-form">
                    <Form.Group
                        className="did-floating-label-content"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Form.Control
                            className="did-floating-input"
                            type="text"
                            value={webhook_url}
                            onChange={(e) => setWebhook_url(e.target.value)}
                            placeholder=""
                        />
                        <Form.Label className="did-floating-label">URL</Form.Label>
                        <div className="col-md-12 mb-3 mt-4">
                            <div className="form-btn">
                                <div className="form-btn-right">
                                    {loading ? (
                                        <SpinnerLoader />
                                    ) : webhooks ? (
                                        <button
                                            type="button"
                                            onClick={handleAddWebhooks}
                                            className="btn bg-green-dark action-btn btn-green"
                                        >
                                            Update URL
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            onClick={handleAddWebhooks}
                                            className="btn bg-green-dark action-btn btn-green"
                                        >
                                            Add URL
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </div>
            </div>
        </div>
    );
};

export default WebhookForm;
