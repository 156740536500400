import React, { useState, useRef, useEffect, useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MdSchedule } from "react-icons/md";
import { Modal, Button } from "react-bootstrap";
import Bulkschedule from "../SocialContent/bulkschedule";
import { scheduledPostsContext } from '../contexts/context/scheduledPostsContext';
import { socialAccountContext } from '../contexts/context/socialAccountContext';
import { completedPostsContext } from '../contexts/context/completedPostsContext';
import { draftedPostsContext } from '../contexts/context/draftedPostsContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import EditPost from "./../SocialContent/EditPost/EditPost"
import {
    TbSocial,
} from "react-icons/tb";
import './demo.css'
import 'swiper/css';
import 'swiper/css/navigation';
import { EffectCreative, Navigation } from 'swiper/modules';
import { GrSchedules } from "react-icons/gr";
import { IoIosCheckboxOutline } from "react-icons/io";
import { RiDraftLine } from "react-icons/ri";

import { postTimeLocale } from '../Helpers/postTimeLocale';
import { useLocation } from 'react-router-dom';

// Import icons for different platforms
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaYoutube } from 'react-icons/fa';
import { BiEditAlt } from 'react-icons/bi';

import moment from 'moment'
import { PiX } from 'react-icons/pi';

import useSocialPermissions from '../Components/CustomHooks/useSocialPermissions';
import useScheduleDataActions from "../contexts/actions/useScheduleDataActions";

import useCompleteDataActions from "../contexts/actions/useCompleteDataActions";
import { useParams } from 'react-router-dom';
import useSocialChannels from '../contexts/actions/useSocialChannels';


const API_URL = process.env.REACT_APP_API_URL;

const MyCalendar = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const brand = queryParams.get('brand');

    const { scheduledPostsData } = useContext(scheduledPostsContext);

    const { connectedAccount } = useContext(socialAccountContext);

    const { completedPostsData } = useContext(completedPostsContext);

    const { draftedPostsData } = useContext(draftedPostsContext);

    const { getScheduledPostsByBrand } = useScheduleDataActions();

    const { getCompletePostsByBrand } = useCompleteDataActions();

    const { getConnectedChannelsByBrand } = useSocialChannels();


    const { checkForSocialContentPermissoins } = useSocialPermissions();

    // const { brand } = useParams();


    const [events, setEvents] = useState([]);

    const [selectedEvent, setSelectedEvent] = useState(null);


    const [show, setShow] = useState(false);
    const handleconfirmClose = () => {
        const shouldClose = window.confirm('Are you sure want to quit all your changes will be lost ?');
        if (shouldClose) {
            setShow(false);
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [postData, setpostData] = React.useState('');
    const [show02, setShow02] = useState(false);
    const [statusType, setStatusType] = useState(false);
    const handleClose02 = () => { setShow02(false) }
    const handleShow02 = (data) => {
        handleClose1()
        setStatusType(data.postdata.status)
        setpostData(data.postdata)
        setShow02(true)
    };
    const user = JSON.parse(localStorage.getItem("user"));
    const startDay = user?.start_day





    React.useEffect(() => {

        getScheduledPostsByBrand(brand)
        getCompletePostsByBrand(brand)
        getConnectedChannelsByBrand(brand)

    }, [])


    useEffect(() => {
        if (scheduledPostsData.posts && connectedAccount && completedPostsData.posts) {
            const scheduledEvents = scheduledPostsData.posts.map(post => {
                const account = getAccountForPost(post);
                if (!account) return null;
                return {
                    title: post.caption,
                    start: postTimeLocale(post.schedule_time).localDateString,
                    platform: post.platform,
                    zone: postTimeLocale(post.schedule_time).timeZoneAbbreviation,
                    accountdata: account,
                    postdata: post,
                    color: '#0BF00F' // Scheduled posts are green
                };

            });

            const completedEvents = completedPostsData.posts.map(post => {
                const account = getAccountForPost(post);
                if (!account) return null;
                return {
                    title: post.caption,
                    start: postTimeLocale(post?.posted_time || post.schedule_time).localDateString,
                    platform: post.platform,
                    zone: postTimeLocale(post.schedule_time).timeZoneAbbreviation,
                    accountdata: account,
                    postdata: post,
                    color: '#0BF00F' // Completed posts are green
                };
            });



            const allEvents = [...scheduledEvents, ...completedEvents].filter(event => event !== null);
            setEvents(allEvents);
        }
    }, [scheduledPostsData, connectedAccount, completedPostsData]);



    const getAccountForPost = (post) => {
        const { accounts } = connectedAccount;
        switch (post.platform) {
            case 'FACEBOOK':
                return accounts.find(account => account.facebook_pageID === post.facebook_pageID);
            case 'TWITTER':
                return accounts.find(account => account.Twitter_userID === post.Twitter_userID);
            case 'INSTAGRAM':
                return accounts.find(account => account.Instagram_userID === post.Instagram_userID);
            case 'LINKEDIN':
                return accounts.find(account => account.LinkedIn_userID === post.LinkedIn_userID);
            case 'YOUTUBE':
                return accounts.find(account => account.youtube_userID === post.youtube_userID);
            default:
                return null;
        }
    };

    const handleDateClick = (arg) => {
        alert('date click! ' + arg.dateStr);
    };

    const handleEventClick = (clickInfo) => {

        const drotaa = {
            caption: clickInfo.event.title,
            schedule_time: clickInfo.event.start,
            platform: clickInfo.event.extendedProps.platform,
            accountdata: clickInfo.event.extendedProps.accountdata,
            postdata: clickInfo.event.extendedProps.postdata,
            zone: clickInfo.event.extendedProps.zone
        }

        console.log("drroooootaaa", drotaa)
        setSelectedEvent(prev => drotaa);

        handleShow1()

    };

    const handleMouseEnter = (eventInfo) => {
        if (eventInfo && eventInfo.eventRange) {
            // Handle hover event
        }
    };

    const handleMouseLeave = (eventInfo) => {
        if (eventInfo && eventInfo.eventRange) {
            // Handle hover leave event
        }
    };
    const [hoveredId, setHoveredId] = useState(null)
    const renderEventContent = (eventInfo) => {

        const platformIcons = {
            'FACEBOOK': <FaFacebook style={{ color: "#0D47A1" }} />,
            'TWITTER': <FaTwitter style={{ color: "#1DA1F2" }} />,
            'LINKEDIN': <FaLinkedin style={{ color: "#0A66C2" }} />,
            'INSTAGRAM': <FaInstagram style={{ color: "#C13584" }} />,
            'YOUTUBE': <FaYoutube style={{ color: "#C62828" }} />,
            // 'SCHEDULED': <GrSchedules />,
            // 'POSTED': <IoIosCheckboxOutline />,
            // 'DRAFTED': <RiDraftLine />

        };

        const colors = {
            'FACEBOOK': '#BBDEFB',
            'TWITTER': '#B3E5FC',
            'LINKEDIN': '#E1F5FE',
            'INSTAGRAM': '#FF8A65',
            'YOUTUBE': '#ff8989eb',
            'SCHEDULED': "#E6D282",
            'POSTED': "#7DDA58",
            'DRAFTED': "#CECECE"
        };
        const textColor = {
            'SCHEDULED': "#333",
            'POSTED': "#28A745",
            'DRAFTED': "#6C757D"
        }


        const platform = eventInfo.event.extendedProps.platform;
        const status = eventInfo.event.extendedProps.postdata.status
            ;
        const postdata = eventInfo.event.extendedProps.postdata;
        const mediaType = postdata?.mediaType;
        const images = postdata?.images;
        const videos = postdata?.videos;
        console.log("status", status)
        const truncatedCaption = eventInfo.event.title.length > 15
            ? eventInfo.event.title.substring(0, 10) + '...'
            : eventInfo.event.title;

        const imageUrl = eventInfo.event.extendedProps.postdata?.
            images[0]?.imgUrl;

        // URL for the API or default handling if imageUrl is not HTTPS
        const finalImageUrl = imageUrl?.startsWith('https')
            ? imageUrl
            : API_URL + '/posts/' + imageUrl;

        let postType = "Single Media Post";

        if (mediaType === 'image' && images?.length > 1) {
            postType = "Carousel Images";
        } else if (mediaType === 'image') {
            postType = "Single Image";
        } else if (mediaType === 'video' && videos?.length > 1) {
            postType = "Carousel Media";
        } else if (mediaType === 'video') {
            postType = "Single Media";
        }
        const platformMap = {
            'LINKEDIN': 'LI',
            'TWITTER': 'X',
            'INSTAGRAM': 'IG',
            'FACEBOOK': 'FB',
            "YOUTUBE": "YT"
        };
        let plat2 = platformMap[platform]

        const startTime = new Date(eventInfo.event.start);
        const formattedStartTime = startTime.toLocaleTimeString("en-US", { minute: "2-digit", hour: "2-digit", });
        const eventInfo2 = eventInfo?.event?.extendedProps || {};
        return (
            <div
                onMouseEnter={(e) => handleMouseEnter(eventInfo2)}
                onMouseLeave={(e) => handleMouseLeave(eventInfo2)}
                style={{
                    width: '100%',
                    height: 'auto',
                    padding: '2px',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    position: "absolute",
                    backgroundColor: colors[status] || "#ffffff" // Directly apply inline background color
                }}>

                {/* Left side icon */}
                <div className='gap-2 d-flex'>
                    <div >
                        {platformIcons[eventInfo.event.extendedProps.platform]}
                    </div>

                    {/* Middle section for time and caption */}
                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        color: '#333'
                        // plat2 === "FB" ? "#0D47A1"
                        //     : plat2 === "YT" ? "#C62828"
                        //         : plat2 === "X" ? "#1DA1F2"
                        //             : plat2 === "LI" ? "#0A66C2"
                        //                 : plat2 === "IG" ? "#C13584"
                        //                     : 'inherit'
                    }}>
                        <b>{` ${postType}`}</b>
                        @{formattedStartTime}
                        <i >{truncatedCaption}</i>
                    </div>

                </div>

                {/* Right side image */}
                {mediaType === 'image' ? (
                    <img src={finalImageUrl} alt="Event" style={{
                        width: 60,
                        padding: 4,
                        borderRadius: 10,
                        objectFit: 'cover',
                        height: 60,
                        marginRight: 2,
                    }} />
                ) : (<video
                    src={videos[0]?.imgUrl.startsWith('https') ? videos[0]?.imgUrl : `${API_URL}/posts/${videos[0]?.imgUrl}`}
                    preload="metadata"
                    muted
                    playsInline
                    style={{
                        width: 60,
                        padding: 4,
                        borderRadius: 10,
                        objectFit: 'cover',
                        height: 60,
                        marginRight: 2,
                    }}
                >
                    Your browser does not support the video tag.
                </video>)}


            </div>

        );
    };



    return (
        <>

            {/* <Modal show={show02} onHide={handleClose02} centered size="lg" className='Sidebar-Modal-end' >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><MdSchedule />Edit Post</Modal.Title>
                </Modal.Header>
                <EditPost handleClose={handleClose02} handleClose1={handleClose1} postData={postData} typeStatus={statusType} />
            </Modal>

            <Modal show={show} onHide={handleconfirmClose} centered size="xl" className='Sidebar-Modal-end' >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><MdSchedule />Create Post</Modal.Title>
                </Modal.Header>
                <Bulkschedule closemodal={handleClose} method="all" />
            </Modal> */}

            <Modal show={show1} onHide={handleClose1} centered size="sm" className='Sidebar-Modal-end' >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><MdSchedule />Post</Modal.Title>
                </Modal.Header>


                <Modal.Body>

                    {selectedEvent && (
                        <div className='modal_post_details'>
                            {/* <h4>{selectedEvent.}</h4>
                            <p>schedule Time : {selectedEvent.start.toLocaleString()} </p> */}

                            {console.log("selected eventwa re", selectedEvent.postdata.status)}


                            <div className="post-lists">
                                {/* <div className="posted-details">
                                <div className="post-day">Today</div>
                                <div className="post-month">November 2022</div>
                                </div> */}
                                <div className="all-post-list">

                                    <div className="post-box-design">
                                        <div className="bg-white rounded-lg shadow-sm">
                                            <div className="post-header-details  d-flex justify-content-between">
                                                <div className="post-header">

                                                    <img src={

                                                        selectedEvent.platform == "FACEBOOK" ? selectedEvent.accountdata.facebook_pageProfilePicture :
                                                            selectedEvent.platform == "TWITTER" ? selectedEvent.accountdata.Twitter_user_profilePicture :
                                                                selectedEvent.platform == "INSTAGRAM" ? selectedEvent.accountdata.profile_picture_url :
                                                                    selectedEvent.platform == "LINKEDIN" ? selectedEvent.accountdata.LinkedIn_picture :
                                                                        selectedEvent.platform == "YOUTUBE" ? selectedEvent.accountdata.youtube_picture :
                                                                            null

                                                    }

                                                        alt="Profile Picture" class="user-profile-img" />
                                                    <div>
                                                        <div className="text-lg font-semibold user-name-text">{

                                                            selectedEvent.platform == "FACEBOOK" ? selectedEvent.accountdata.facebook_pageName :
                                                                selectedEvent.platform == "TWITTER" ? selectedEvent.accountdata.Twitter_displayName :
                                                                    selectedEvent.platform == "INSTAGRAM" ? selectedEvent.accountdata.username :
                                                                        selectedEvent.platform == "LINKEDIN" ? selectedEvent.accountdata.LinkedIn_name :
                                                                            selectedEvent.platform == "YOUTUBE" ? selectedEvent.accountdata.youtube_name :
                                                                                null
                                                        }</div>
                                                        <p>{
                                                            selectedEvent.postdata.status === "SCHEDULED" ? "Scheduled" :
                                                                selectedEvent.postdata.status === "DRAFTED" ? "Drafted" : "Completed"} : {moment(selectedEvent.schedule_time).format("MMMM Do YYYY @ h:mma ") + selectedEvent.zone} </p>
                                                    </div>
                                                </div>
                                                {/* {(selectedEvent.postdata.status === "SCHEDULED" || selectedEvent.postdata.status === "DRAFTED") &&
                                                    <div className='post-header-btn'>
                                                        <button
                                                            disabled={checkForSocialContentPermissoins() === "view-only" || checkForSocialContentPermissoins() === "approval-required"}
                                                            className='btn btn-primary btn-sm post-edit-btn'
                                                            onClick={() => handleShow02(selectedEvent)}
                                                        >
                                                            <BiEditAlt />
                                                        </button>
                                                    </div>
                                                } */}

                                            </div>
                                            <div className="post-body">
                                                <div className="post-body-text">
                                                    <p>{selectedEvent.caption}</p>
                                                </div>
                                                <div className="post-image-grid-custom">
                                                    <div className="social-image-grid-custom">

                                                        <Swiper
                                                            slidesPerView={1}
                                                            spaceBetween={10}
                                                            navigation={true}
                                                            modules={[EffectCreative, Navigation]}
                                                            className="mySwiper2"
                                                        >{
                                                                selectedEvent.postdata.mediaType == "image" ?
                                                                    <>
                                                                        {selectedEvent.postdata.images.map((image, index) => (
                                                                            <SwiperSlide key={image.id}>
                                                                                <div className='image-grid-item'>
                                                                                    <img src={image.imgUrl?.startsWith("https://") ? image.imgUrl : API_URL + '/posts/' + image.imgUrl} alt="Post Image" />
                                                                                    {/* {index === 3 && images.length > 4 && (
                                                                <div className="view-more">
                                                                    <button>
                                                                        +{images.length - 4}
                                                                    </button>
                                                                </div>
                                                            )} */}
                                                                                </div>
                                                                            </SwiperSlide>
                                                                        ))}
                                                                    </>

                                                                    :
                                                                    <>
                                                                        {selectedEvent.postdata.videos.map((video, index) => (

                                                                            <SwiperSlide key={video.id}>
                                                                                <div className='video-grid-item'>
                                                                                    <video src={video.imgUrl?.startsWith("https://") ? video.imgUrl : `${API_URL}/posts/${video.imgUrl}`} controls />
                                                                                </div>
                                                                            </SwiperSlide>

                                                                        ))}
                                                                    </>
                                                            }
                                                        </Swiper>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Add more details as needed */}
                        </div>
                    )}



                </Modal.Body>
            </Modal>

            {/* 
            <div className="add-btn d-flex Add-margin">
                <h5 className="tab-content-title"><div className="tab-content-icon"><MdSchedule /></div>Schedule</h5>
                <div className="tab-content-header-right d-flex">
                    <Button disabled={checkForSocialContentPermissoins() === "view-only"} className="btn btn-sm float-right add-user-btn" onClick={handleShow}><TbSocial />New Post</Button>
                </div>
            </div> */}
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                weekends={true}
                // dateClick={handleDateClick}
                events={events}
                eventContent={renderEventContent}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                navLinks={true}
                views={{
                    dayGridMonth: { dayMaxEventRows: 3 },
                    timeGridDay: { dayMaxEventRows: 4 },


                }}
                eventClick={handleEventClick}
                eventClassNames={'custom-event-class'}
                contentHeight="auto" // Adjust the height of the calendar
                firstDay={startDay}
            />
        </>
    );
};

export default MyCalendar;
