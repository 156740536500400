import React, { createContext, useState } from "react";

export const SmartLinkContext = createContext();

export const SmartLinkProvider = (props) => {


    const [viewType, setViewType] = useState("");
    const [smartlinkeditdata, setSmartlinkEditData] = useState("");
    const [IosTargeting, setIosTargeting] = useState();
    const [AndroidTargeting, setAndroidTargeting] = useState();
    const [campaigntype, setCampaignType] = useState(false);
    const [schedulesdata, setSchedulesData] = useState('');
    const [latest, setLatest] = useState(false);
    const [campaigndata, setCampaignData] = useState(
        {
            "campaign_source": "",
            "campaign_medium": "",
            "campaign_session": "",
            "parameters": [
                {
                    "value": ""
                }
            ]
        }
    );
    const [linkcloakingdata, setLinkCloakingData] = useState('');
    const [passwordprotection, setPasswordProtection] = useState('');
    const [trackingdata, setTrackingData] = useState('');
    const [qrdata, setQrData] = useState('');
    const [flexPages, setFlexPages] = useState([]);


    return (
        <SmartLinkContext.Provider value={[smartlinkeditdata, setSmartlinkEditData, IosTargeting, setIosTargeting, latest, setLatest, AndroidTargeting, setAndroidTargeting, campaigntype, setCampaignType, campaigndata, setCampaignData, schedulesdata, setSchedulesData, linkcloakingdata, setLinkCloakingData, passwordprotection, setPasswordProtection, trackingdata, setTrackingData, qrdata, setQrData, viewType, setViewType, flexPages, setFlexPages]}>
            {props.children}
        </SmartLinkContext.Provider>
    );
}


