import React from 'react'
import { API_URL } from '../../../BackendApi/Api/config'

const EventsImagesList = (props) => {
    return (
        <div className="mb-3" id={props.data.unique_id}>
            <div className="image_with_text_content" >
                <div className="brand_img_set" >
                    {/* <a href={props.data.link}> */}
                    <img
                        className="img-fluid" 
                        style={{ objectFit: props.data.imagePosition }}
                        src={(props.data.imagefile?.startsWith("http://") || props.data.imagefile?.startsWith("https://"))
                            ? props.data.imagefile : API_URL + "flowpage/" + props.data.imagefile}
                        alt={props.data.imageAltText || ""}
                    />
                    {/* </a> */}
                </div>

            </div>
        </div>
    )
}

export default EventsImagesList
