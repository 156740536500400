import React, { useEffect, useState } from "react";
import './PageBuilder.css';
import BuilderLeft from "./BuilderLeft";
import BuilderRight from "./BuilderRight";
import Header from "../Components/Header/Header";
import { getFlowPageData } from "../BackendApi/Api/flowpageApi"
import { useBuilderContext } from "../builderContext";
import { SmartLinkContext } from "../Components/CreateSmartlink/SmartLinkcontext";
import { TbEditCircle } from "react-icons/tb";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'suneditor/dist/css/suneditor.min.css';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
function PageBuilder() {

    const { setMinipage,
        color,
        setColor,
        buttonColor,
        setButtonColor,
        fontFamily,
        setFontFamily,
        addBgclass,
        setBgclass,
        setSmartlink_url,
        setQrImage,
        setCompain_slug,
        setBackgroundType,
        setBackgroundColor,
        setBackgroundColor2,
        setColorPosition,
        setShowPoweredBy } = useBuilderContext();

    const [smartlinkeditdata, setSmartlinkEditData] = React.useContext(SmartLinkContext);

    // const [color, setColor] = useState('');

    const changeColor = (color_white) => {
        setColor(color_white);
    }

    // const [buttonColor, setButtonColor] = useState('');

    const changeButtonColor = (color_white) => {
        setButtonColor(color_white);
    }

    // const [fontFamily, setFontFamily] = useState('');


    const changeFontFamily = (font_family) => {
        setFontFamily(font_family);
    }

    // const { addBgclass, setBgclass } = useState('');

    const changeBgclass = (bg_class) => {
        setBgclass(bg_class);
    }

    const changebackgroundType = (bg_type) => {
        setBackgroundType(bg_type);
    }

    useEffect(() => {

        const url = window.location.href;
        const urlSplit = url.split('/');

        console.log("id from url", urlSplit);

        const withhash = urlSplit[urlSplit.length - 2]

        const id = withhash.slice(0, -1);

        const data = {
            id: id
        }

        getFlowPageData(data).then((res) => {

            if (res.code === 200) {
                if (res.data.status == true) {
                    const resData = res.data.data;

                    setMinipage(JSON.parse(resData.page_json));
                    changeColor(resData.background_color);
                    changeButtonColor(resData.button_color);
                    changeFontFamily(resData.font_family);
                    setSmartlink_url(resData.full_short_url);
                    setQrImage(resData.template_image);
                    setCompain_slug(resData.flexpageLink);

                    setBackgroundType(resData.background_type);
                    setBackgroundColor(resData.background_color1);
                    setBackgroundColor2(resData.background_color2);
                    setColorPosition(resData.background_Position);

                    if ('showPoweredBy' in resData) {

                        setShowPoweredBy(resData.showPoweredBy);
                    }



                    setSmartlinkEditData(resData);

                }
            }

        }).catch((err) => {
            console.log("error in minipage data by id", err);
            PNotify.error({
                title: 'Error',
                text: err.message
            })
        })




    }, []);



    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>

            <Modal show={show} onHide={handleClose} className="page_builder_modal">
                <Modal.Body className="p-0">
                    <div className="bulider_right">
                        <BuilderRight changeColor={changeColor} changeButtonColor={changeButtonColor} changeFontFamily={changeFontFamily} changeBgclass={changeBgclass} />
                    </div>
                </Modal.Body>
            </Modal>





            <Header />

            <div className="main-container mobile-hide">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            <div className="page_builder_app">
                                <div className="bulider_right fOCfTk">
                                    <BuilderRight changeColor={changeColor} changeButtonColor={changeButtonColor} changeFontFamily={changeFontFamily} changeBgclass={changeBgclass} changebackgroundType={changebackgroundType} />
                                </div>
                                <div className="bulider_left kzboard">
                                    <BuilderLeft color={color} buttonColor={buttonColor} fontFamily={fontFamily} addBgclass={addBgclass} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="main-container mobile-show">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            <div className="page_builder_app">
                                {/* <div className="bulider_right fOCfTk">
                                            <BuilderRight changeColor={changeColor} changeButtonColor={changeButtonColor} changeFontFamily={changeFontFamily} changeBgclass={changeBgclass} />
                                        </div> */}
                                <div className="bulider_left kzboard">
                                    <BuilderLeft color={color} buttonColor={buttonColor} fontFamily={fontFamily} addBgclass={addBgclass} />
                                </div>

                                <div className="fix_button">
                                    <button className="edit_builder_btn" onClick={handleShow}>
                                        <TbEditCircle />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default PageBuilder;