// import React, { useState } from 'react';
// import { Button, Modal } from 'react-bootstrap';
// import { FaFacebookF, FaTwitter, FaShareAlt, FaLinkedinIn, FaEnvelope, FaWhatsapp, FaInstagram, FaSnapchatGhost, FaRedditAlien, FaPinterestP, FaDiscord, FaTelegramPlane, FaTwitch, FaViber, FaSkype } from 'react-icons/fa';
// import { MdMessage } from 'react-icons/md';
// import { FaXTwitter } from "react-icons/fa6";

// // Make sure to import the Bootstrap CSS in your main app file:
// // import 'bootstrap/dist/css/bootstrap.min.css';

// const SocialShareModal = ({ url, title, description }) => {
//   const [show, setShow] = useState(false);
//   const [hovered, setHovered] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const encodedUrl = encodeURIComponent(url);
//   const encodedTitle = encodeURIComponent(title);
//   const encodedDescription = encodeURIComponent(description);

//   const shareLinks = [
//     { name: 'Facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`, icon: <FaFacebookF size={24} />, color: '#1877f2' },
//     { name: 'X (Twitter)', url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`, icon: <FaXTwitter size={24} />, color: '#000000' },
//     { name: 'LinkedIn', url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`, icon: <FaLinkedinIn size={24} />, color: '#0a66c2' },
//     { name: 'Email', url: `mailto:?subject=${encodedTitle}&body=${encodedDescription}%0A%0A${encodedUrl}`, icon: <FaEnvelope size={24} />, color: '#ea4335' },
//     { name: 'WhatsApp', url: `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`, icon: <FaWhatsapp size={24} />, color: '#25D366' },
//     { name: 'Instagram', url: 'https://www.instagram.com/', icon: <FaInstagram size={24} />, color: '#E4405F' },
//     { name: 'Snapchat', url: 'https://www.snapchat.com/', icon: <FaSnapchatGhost size={24} />, color: '#FFFC00' },
//     { name: 'Reddit', url: `https://reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`, icon: <FaRedditAlien size={24} />, color: '#FF4500' },
//     { name: 'Pinterest', url: `https://pinterest.com/pin/create/button/?url=${encodedUrl}&media=&description=${encodedTitle}`, icon: <FaPinterestP size={24} />, color: '#E60023' },
//     { name: 'Telegram', url: `https://t.me/share/url?url=${encodedUrl}&text=${encodedTitle}`, icon: <FaTelegramPlane size={24} />, color: '#0088cc' },
//     { name: 'Discord', url: 'https://discord.com/', icon: <FaDiscord size={24} />, color: '#5865F2' },
//     { name: 'Twitch', url: 'https://www.twitch.tv/', icon: <FaTwitch size={24} />, color: '#9146FF' },
//     { name: 'Skype', url: 'https://web.skype.com/', icon: <FaSkype size={24} />, color: '#00AFF0' },
//     { name: 'Viber', url: 'https://www.viber.com/', icon: <FaViber size={24} />, color: '#665CAC' },
//     { name: 'Teams', url: 'https://teams.microsoft.com/', icon: <MdMessage size={24} />, color: '#6264A7' },
//   ];

//   return (
//     <>
//       <Button
//         variant="outline-primary"
//         onClick={handleShow}
//         className="d-flex align-items-center"
//         onMouseEnter={() => setHovered(true)}
//         onMouseLeave={() => setHovered(false)}
//       >
//         <FaShareAlt size={16} className="me-2" />
//         {hovered && <span>Share</span>}
//       </Button>

//       <Modal show={show} onHide={handleClose} centered size="lg">
//         <Modal.Body>
//           <div className="d-flex flex-wrap justify-content-center">
//             {shareLinks.map((link) => (
//               <a
//                 key={link.name}
//                 href={link.url}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="m-2 d-flex flex-column align-items-center"
//                 style={{ color: link.color, textDecoration: 'none', width: '60px' }}
//               >
//                 {link.icon}
//                 <span className="mt-1 text-center" style={{ fontSize: '0.7rem', color: '#333' }}>{link.name}</span>
//               </a>
//             ))}
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default SocialShareModal;

import React, { useState } from 'react';
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaShareAlt, FaLinkedinIn, FaEnvelope, FaWhatsapp, FaInstagram, FaSnapchatGhost, FaRedditAlien, FaPinterestP, FaDiscord, FaTelegramPlane, FaTwitch, FaViber, FaSkype, FaExclamationTriangle } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { MdMessage } from 'react-icons/md';
import { CSSTransition } from 'react-transition-group';
import "./demo.css"
import { AiOutlineMore } from 'react-icons/ai';
import { reportEvent } from '../../BackendApi/Api/Event';
const SocialShareModal = ({ type, url, title, description }) => {
  const [show, setShow] = useState(false);
  const [isReportView, setIsReportView] = useState(false);
  const [reportContent, setReportContent] = useState('');
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const encodedDescription = encodeURIComponent(description);

  const handleShowShare = () => {
    setShow(true);
    setIsReportView(false);
  }
  const shareLinks = [
    { name: 'Facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`, icon: <FaFacebookF size={24} />, color: '#1877f2' },
    { name: 'X (Twitter)', url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`, icon: <FaXTwitter size={24} />, color: '#000000' },
    { name: 'LinkedIn', url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`, icon: <FaLinkedinIn size={24} />, color: '#0a66c2' },
    { name: 'Email', url: `mailto:?subject=${encodedTitle}&body=${encodedDescription}%0A%0A${encodedUrl}`, icon: <FaEnvelope size={24} />, color: '#ea4335' },
    { name: 'WhatsApp', url: `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`, icon: <FaWhatsapp size={24} />, color: '#25D366' },
    { name: 'Instagram', url: 'https://www.instagram.com/', icon: <FaInstagram size={24} />, color: '#E4405F' },
    { name: 'Snapchat', url: 'https://www.snapchat.com/', icon: <FaSnapchatGhost size={24} />, color: '#FFFC00' },
    { name: 'Reddit', url: `https://reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`, icon: <FaRedditAlien size={24} />, color: '#FF4500' },
    { name: 'Pinterest', url: `https://pinterest.com/pin/create/button/?url=${encodedUrl}&media=&description=${encodedTitle}`, icon: <FaPinterestP size={24} />, color: '#E60023' },
    { name: 'Telegram', url: `https://t.me/share/url?url=${encodedUrl}&text=${encodedTitle}`, icon: <FaTelegramPlane size={24} />, color: '#0088cc' },
    { name: 'Discord', url: 'https://discord.com/', icon: <FaDiscord size={24} />, color: '#5865F2' },
    { name: 'Twitch', url: 'https://www.twitch.tv/', icon: <FaTwitch size={24} />, color: '#9146FF' },
    { name: 'Skype', url: 'https://web.skype.com/', icon: <FaSkype size={24} />, color: '#00AFF0' },
    { name: 'Viber', url: 'https://www.viber.com/', icon: <FaViber size={24} />, color: '#665CAC' },
    { name: 'Teams', url: 'https://teams.microsoft.com/', icon: <MdMessage size={24} />, color: '#6264A7' },
  ];

  const handleReportSubmit = async () => {
    if(!reportContent){
      alert('Please enter a report message.');
      return;
    }
    const dataResult = {
      message: reportContent,
      // Event_id: Event_id,
    }
    try {
     let response = await reportEvent(dataResult);
      console.log("response", response);
      console.log('Report Submitted:', reportContent);
      setReportContent('');
      alert('Your report has been submitted.');
      setIsReportView(false); // Switch back to Share view after submission
      
    } catch (error) {
      alert('Error: ' + error.message);
    }
    
  };

  return (
    <>
      {/* Share button with tooltip */}
      <div className="d-flex align-items-center" style={{ zIndex: '9999' }}>
        {type == "portfolio" ? (
          <OverlayTrigger placement="top" overlay={<Tooltip>Share</Tooltip>}>
            <Button variant="" onClick={handleShowShare} className="d-flex jusify-content-end">
              <AiOutlineMore size={40} color='white' fontWeight={'bold'} />
            </Button>
          </OverlayTrigger>) :
          <OverlayTrigger placement="top" overlay={<Tooltip>Share</Tooltip>}>
            <Button variant="outline-primary" onClick={handleShowShare} className="d-flex align-items-center">
              <FaShareAlt size={20} />
            </Button>
          </OverlayTrigger>}
      </div>

      {/* Modal for social media links */}
      <Modal show={show} onHide={() => setShow(false)} centered size="md">
        <Modal.Body>
          {/* Share Links Section */}
          <div className="d-flex flex-wrap justify-content-center">
            {shareLinks.map((link) => (
              <a
                key={link.name}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="m-2 d-flex flex-column align-items-center"
                style={{ color: link.color, textDecoration: 'none', width: '60px' }}
              >
                {link.icon}
                <span className="mt-1 text-center" style={{ fontSize: '0.7rem', color: '#333' }}>{link.name}</span>
              </a>
            ))}
          </div>
          <hr style={{ width: '100%', backgroundColor: "#000" }} />

          {/* Report Button */}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="outline btn btn-sm float-right add-user-btn me-2" className="d-flex justify-content-end" onClick={() => setIsReportView(true)}>
              <FaExclamationTriangle size={20} className="me-2" />
              <span style={{ color: "white" }}>Report</span>
            </Button>
          </div>

          {/* Report Form */}
          <CSSTransition in={isReportView} timeout={300} classNames="fade" unmountOnExit>
            <Form>
              <Form.Group>
                <Form.Label>Describe the issue:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={reportContent}
                  onChange={(e) => setReportContent(e.target.value)}
                  placeholder="Please provide details about the issue..."
                />
              </Form.Group>
              <div className="d-flex justify-content-end mt-3">
                <Button variant="danger" className="me-2" onClick={() => setIsReportView(false)}>Cancel</Button>
                <Button variant="btn btn-sm float-right add-user-btn me-2" onClick={handleReportSubmit} style={{ color: "white" }}>Submit Report</Button>
              </div>
            </Form>
          </CSSTransition>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SocialShareModal;
