import React, { useState,useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { getsocialpreview, updateSocialPreview } from '../../BackendApi/Api/flowpageApi';
import { useParams } from "react-router-dom"
import { useBuilderContext } from '../../builderContext';
import { API_URL } from '../../BackendApi/Api/config';
import './EventInfo.css'
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css'; 
import '@pnotify/core/dist/PNotify.css';
// SpinnerLoader component for loading state
const SpinnerLoader = () => {
    return <div className="spinner-border text-light" role="status"><span className="visually-hidden">Loading...</span></div>;
};

const SocialPreviewForm = () => {
    const {  title, setTitle, setDescription, description } = useBuilderContext();

    const [loading, setLoading] = useState(false);
    const [favicon, setFavicon] = useState(null);
    const [openGraphImage, setOpenGraphImage] = useState(null);
    const [socialPreviewData, setSocialPreviewData] = useState({})
    const [imageUrls, setImageUrls] = useState({ favicon: '', openGraphImage: '' });

    const {id} = useParams();
    const handleSelectFavicon = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageUrls((prev) => ({ ...prev, favicon: URL.createObjectURL(file) }));
        }
    };

    const handleSelectOG = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageUrls((prev) => ({ ...prev, openGraphImage: URL.createObjectURL(file) }));
        }
    };
    const getsocialpreviewData = () => {
        const data = {
            "smartlink_id": id
        }
        getsocialpreview(data).then((res) => {
            if (res.code === 200) {
                let previewData = res.data.data
                setSocialPreviewData(previewData);
                setTitle(previewData?.title);
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    openGraphImage: previewData?.favicon,
                }));
                setDescription(previewData?.description);
                setImageUrls({
                    openGraphImage: API_URL + 'ogimages/' + previewData?.og_image,
                    favicon: previewData?.favicon,
                });
            } else {
                PNotify?.error({
                    title: 'Error',
                    text: "Something went wrong",
                });
            }
        }).catch((err) => {
            PNotify?.error({
                title: 'Error',
                text: err,
            });
        })
    }


    const handleUpdateSocialPreview = (e) => {
        e.preventDefault();
        setLoading(true);

        const formdata = new FormData();
        formdata.append("smartlinkId", id);
        formdata.append("description", description);
        formdata.append("title", title);
        formdata.append("favicon", favicon);
        formdata.append("og_image", openGraphImage);

        updateSocialPreview(formdata)
            .then((res) => {
                setLoading(false);

                if (res.code === 200) {
                    PNotify.success({
                        title: 'Success',
                        text: "Social Preview Updated Successfully",
                    });
                } else {
                    PNotify?.error({
                        title: 'Error',
                        text: "Something Went Wrong",
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                PNotify?.error({
                    title: 'Error',
                    text: err,
                });
            });
    };


    useEffect(() => {
        getsocialpreviewData();
    }, [id])

    return (
        <div className="styles__TabContent themes_sec" style={{ margin: '0px 20px' }}>
            <div className="content-body-form">
                <div className="col-md-12">
                    <div className="content-body-form">
                        {/* Title Input */}
                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                            <div className="d-flex">
                                <Form.Control
                                    className="did-floating-input"
                                    type="text"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder=""
                                />
                                <Form.Label className="did-floating-label">Title</Form.Label>
                            </div>
                        </Form.Group>

                        {/* Description Input */}
                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea2">
                            <Form.Control
                                className="did-floating-textarea"
                                as="textarea"
                                rows={3}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder=""
                            />
                            <Form.Label className="did-floating-label ">Description</Form.Label>
                        </Form.Group>
                    </div>

                    {/* File Uploads */}
                    {/* <div className="row"> */}
                        {/* Favicon */}
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control
                                    className="did-floating-file"
                                    type="file"
                                    placeholder="Company Logo"
                                    onChange={handleSelectFavicon}
                                />
                                <Form.Label className="did-floating-label ">Favicon</Form.Label>
                                {imageUrls.favicon && <img src={imageUrls.favicon} alt="Favicon" className="preview-logo-image" style={{ height: '100px', width: '100px' }} />}
                            </Form.Group>
                        </div>

                        {/* Social Network Image (Open Graph) */}
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control
                                    className="did-floating-file"
                                    type="file"
                                    placeholder="Company Logo"
                                    onChange={handleSelectOG}
                                />
                                <Form.Label className="did-floating-label">Social Network Image (Open Graph)</Form.Label>
                                {imageUrls.openGraphImage && <img src={imageUrls.openGraphImage} className="preview-logo-image" style={{ height: '100px', width: '100px' }} />}
                            </Form.Group>
                        </div>
                    {/* </div> */}

                </div>

                {/* Submit Button */}
                <div className=" mt-4">
                    <div className="col-md-12 ">
                        <div className="form-btn">
                            <div className="form-btn-right">
                                {loading ? (
                                    <SpinnerLoader />
                                ) : (
                                    <button
                                        type="button"
                                        onClick={handleUpdateSocialPreview}
                                        className="col-12 py-3 btn bg-green-dark action-btn btn-green"
                                    >
                                        Update 
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialPreviewForm;
