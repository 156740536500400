import { createContext, useState, useReducer, useMemo } from "react";

import { eventCategoryReducer } from '../reducers/eventCategoryReducer'


export const eventCategoryContext = createContext();

export const EventCategoryProvider = ({ children }) => {

    const initialState = {
        isFetching: true,
        categories: []
    };

    const [eventCategory, eventCategorydispatch] = useReducer(eventCategoryReducer, initialState);

    const eventCategoryData = useMemo(() => eventCategory, [eventCategory]);

    return (
        <eventCategoryContext.Provider value={{
            eventCategoryData, eventCategorydispatch
        }}>
            {children}
        </eventCategoryContext.Provider>
    );
};

