
import React, { useState, useEffect } from 'react';
import { MdOutlineVideoLibrary } from "react-icons/md";
import { IoImages } from "react-icons/io5";
import { HiGif } from "react-icons/hi2";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import axios from 'axios'

const Consumer_Key = process.env.REACT_APP_LINKEDIN_CONSUMER_KEY;
const Consumer_Secret = process.env.REACT_APP_LINKEDIN_CONSUMER_SECRET;
const Tebor_API_KEY = process.env.REACT_APP_LINKEDIN_TENOR_API_KEY;
const Ghipy_Api_Key = process.env.REACT_APP_LINKEDIN_GHIPY_API_KEY;
const UNSPLASH_KEY = process.env.REACT_APP_LINKEDIN_UNSPLASH_KEY;
const PIXELS_KEY = "hXpk0x3bxdBbzsXZaXbZtC39vp4bSjFXJTU6ZDARrLRAcJvpVtgk7exj";

const useFetchData = (searchTerm, selectedAPI, tabIndex, everyEventCallaApi, setEveryEventCallaApi) => {
    const [loading, setLoading] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);
    const [gifUrls, setGifUrls] = useState([]);
    const [videoUrls, setVideoUrls] = useState([]);
    const [dataAvailable, setDataAvailable] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const apiUrl = getApiUrl(selectedAPI, searchTerm, tabIndex);
                const headers = {
                    Authorization: await getAuthorizationHeader(selectedAPI),
                };
                const params = selectedAPI === 'shutterstock' ? { query: searchTerm, sort: 'popular', view: 'minimal' } : {};
                const { data } = await axios.get(apiUrl, { params, headers });

                if (data) {
                    handleApiResponse(selectedAPI, data, tabIndex);
                    setEveryEventCallaApi(true);
                    setDataAvailable(true);
                } else {
                    setDataAvailable(false);
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
                setDataAvailable(false);
            }
        };

        fetchData();
    }, [searchTerm, selectedAPI, tabIndex, everyEventCallaApi]);

    const getApiUrl = (api, query, tab) => {
        switch (api) {
            case 'unsplash':
                return `https://api.unsplash.com/search/photos?query=${query}`;
            case 'pexels':
                return tab === 'video'
                    ? `https://api.pexels.com/videos/search?query=${query}`
                    : `https://api.pexels.com/v1/search?query=${query}`;
            case 'tenor':
                return `https://tenor.googleapis.com/v2/search?key=${Tebor_API_KEY}&q=${encodeURIComponent(query)}`;
            case 'giphy':
                return `https://api.giphy.com/v1/gifs/search?api_key=${Ghipy_Api_Key}&q=${encodeURIComponent(query)}`;
            case 'storyblocks':
                return `https://api.storyblocks.com/v1/search?query=${query}`;
            case 'shutterstock':
                return tab === 'video'
                    ? `https://api.shutterstock.com/v2/videos/search`
                    : `https://api.shutterstock.com/v2/images/search`;
            default:
                return `https://api.unsplash.com/search/photos?query=${query}`;
        }
    };

    const getAuthorizationHeader = async (api) => {
        switch (api) {
            case 'unsplash':
                return `Client-ID ${UNSPLASH_KEY}`;
            case 'pexels':
                return `${PIXELS_KEY}`;
            case 'storyblocks':
                return 'Bearer test_102e5a978715f8613a027e8addd2962f5ea5637794d5fcad47562e9a18a';
            case 'shutterstock': {
                const response = await axios.post(
                    'https://api.shutterstock.com/v2/oauth/access_token',
                    {
                        client_id: Consumer_Key,
                        client_secret: Consumer_Secret,
                        grant_type: 'client_credentials'
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );

                return `Bearer ${response.data.access_token}`;
            }
            default:
                return '';
        }
    };

    const handleApiResponse = (api, data, tab) => {
        switch (tab) {
            case 'Gif':
                handleGifResponse(api, data);
                break;
            case 'video':
                handleVideoResponse(api, data);
                break;
            default:
                handleImageResponse(api, data);
        }
    };

    const handleGifResponse = (api, data) => {
        let fetchedGifUrls = [];
        switch (api) {
            case 'tenor':
                fetchedGifUrls = data.results.map(gif => gif.media_formats.gif.url);
                break;
            case 'giphy':
                fetchedGifUrls = data.data.map(gif => gif.images.original.url);
                break;
        }
        setGifUrls(fetchedGifUrls);
        setVideoUrls([]);
        setImageUrls([]);
    };

    const handleVideoResponse = (api, data) => {
        let fetchedVideoUrls = [];
        switch (api) {
            case 'pexels':
                fetchedVideoUrls = data.videos.map(video => video.video_files[0].link);
                break;
            case 'shutterstock':
                fetchedVideoUrls = data.data.map(video => video.assets.preview_mp4.url);
                break;
            case 'tenor':
                fetchedVideoUrls = data.results.map(gif => gif.media_formats.mp4.url);
                break;
        }
        setVideoUrls(fetchedVideoUrls);
        setGifUrls([]);
        setImageUrls([]);
    };

    const handleImageResponse = (api, data) => {
        let fetchedImageUrls = [];
        switch (api) {
            case 'pexels':
                fetchedImageUrls = data.photos.map(photo => photo.src.medium);
                break;
            case 'unsplash':
                fetchedImageUrls = data.results.map(photo => photo.urls.regular);
                break;
            case 'shutterstock':
                fetchedImageUrls = data.data.map(photo => photo.assets.preview.url);
                break;
            case 'tenor':
                fetchedImageUrls = data.results.map(gif => gif.media_formats.gifpreview.url);
                break;
        }
        setImageUrls(fetchedImageUrls);
        setGifUrls([]);
        setVideoUrls([]);
    };

    const isUnsupportedCombination = (api, tab) => {
        return (
            (api === 'unsplash' && (tab === 'video' || tab === "Gif")) || // Unsplash does not provide videos or GIFs
            (api === 'giphy' && (tab === 'video' || tab === "image")) || // Giphy does not provide videos or images
            (api === 'pexels' && tab === 'Gif') || // Pexels does not provide GIFs
            (api === 'shutterstock' && tab === 'Gif') // Shutterstock does not provide GIFs
        );
    };

    return { loading, imageUrls, gifUrls, videoUrls, isUnsupportedCombination, dataAvailable };
};







function MediaPost({ insertaipostVideo, insertaipostImage, type }) {

    const [searchTerm, setSearchTerm] = useState('dogs');
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [selectedGifs, setSelectedGifs] = useState([]);

    const [tabIndex, setTabIndex] = useState('image');
    const [everyEventCallaApi, setEveryEventCallaApi] = useState(false)
    const [selectedAPI, setSelectedAPI] = useState('unsplash');

    const { loading, imageUrls, gifUrls, videoUrls, isUnsupportedCombination, dataAvailable } = useFetchData(searchTerm, selectedAPI, tabIndex, everyEventCallaApi, setEveryEventCallaApi);

    const handleAPISelection = (api) => {
        setSelectedAPI(api);
    };




    const toggleImageSelection = (index) => {
        setSelectedImages(prevSelectedImages => {
            if (prevSelectedImages.includes(index)) {
                return prevSelectedImages.filter(i => i !== index);
            } else {
                return [...prevSelectedImages, index];
            }
        });
    };



    const toggleVideoSelection = (index) => {
        setSelectedVideos(prevSelectedVideos => {
            if (prevSelectedVideos.includes(index)) {
                return prevSelectedVideos.filter(i => i !== index);
            } else {
                return [...prevSelectedVideos, index];
            }

        });
    };


    const onAddPost = async () => {
        // Check if there are selected videos
        if (selectedVideos.length > 0) {
            const selectedVideoUrls = selectedVideos.map(index => videoUrls[index]);
            insertaipostVideo(selectedVideoUrls);
            setSelectedVideos([])
            if (selectedAPI == "pexels" || selectedAPI == "giphy" || selectedAPI == "tenor" || selectedAPI == "shutterstock") {
                setSelectedVideos([])
            }
        }

        // Check if there are selected images
        if (selectedImages.length > 0) {
            const selectedImageUrls = selectedImages.map(index => imageUrls[index]);
            insertaipostImage(selectedImageUrls);
            setSelectedImages([])
            if (selectedAPI == "pexels" || selectedAPI == "giphy" || selectedAPI == "tenor" || selectedAPI == "shutterstock") {
                setSelectedImages([])
            }

        }
        if (selectedGifs.length > 0) {
            const selectedGifUrls = selectedGifs.map(index => gifUrls[index]);
            insertaipostImage(selectedGifUrls);
            setSelectedGifs([])
            if (selectedAPI == "pexels" || selectedAPI == "giphy" || selectedAPI == "tenor" || selectedAPI == "shutterstock") {
                setSelectedGifs([])
            }

        }
    };



    const toggleGifSelection = (index) => {
        setSelectedGifs(prevSelectedGifs => {
            if (prevSelectedGifs.includes(index)) {
                return prevSelectedGifs.filter(i => i !== index);
            } else {
                return [...prevSelectedGifs, index];
            }
        });
    };


    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const [file, setFile] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileImageSrc, setFileImageSrc] = useState('');
    const [fileUploadMessage, setFileUploadMessage] = useState('');
    const [progress, setProgress] = useState(0);
    const fileSizeLimit = 1024; // 1GB

    useEffect(() => {
        // Init function equivalent
        const fileSelect = document.getElementById('file-upload');
        const fileDrag = document.getElementById('file-drag');

        const xhr = new XMLHttpRequest();
        if (xhr.upload) {
            fileDrag.addEventListener('dragover', handleFileDragHover, false);
            fileDrag.addEventListener('dragleave', handleFileDragHover, false);
            fileDrag.addEventListener('drop', handleFileSelect, false);
        }

        return () => {
            if (xhr.upload) {
                fileDrag.removeEventListener('dragover', handleFileDragHover);
                fileDrag.removeEventListener('dragleave', handleFileDragHover);
                fileDrag.removeEventListener('drop', handleFileSelect);
            }
        };
    }, []);

    const handleFileDragHover = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsDragging(e.type === 'dragover');
    };

    const handleFileSelect = (e) => {
        const files = e.target.files || e.dataTransfer.files;
        handleFileDragHover(e);

        for (let i = 0; i < files.length; i++) {
            parseFile(files[i]);
            uploadFile(files[i]);
        }
    };

    const parseFile = (file) => {
        setFileName(encodeURI(file.name));
        const isGood = /\.(?=gif|jpg|png|jpeg)/gi.test(file.name);
        if (isGood) {
            setFile(file);
            setFileImageSrc(URL.createObjectURL(file));
        } else {
            resetFileUpload();
            setFileUploadMessage('Please select an image');
        }
    };

    const resetFileUpload = () => {
        setFile(null);
        setFileName('');
        setFileImageSrc('');
        setFileUploadMessage('');
        setProgress(0);
    };

    const uploadFile = (file) => {
        const xhr = new XMLHttpRequest();
        if (xhr.upload) {
            if (file.size <= fileSizeLimit * 1024 * 1024) {
                xhr.upload.addEventListener('loadstart', (e) => setProgressMaxValue(e), false);
                xhr.upload.addEventListener('progress', (e) => updateFileProgress(e), false);

                xhr.onreadystatechange = function (e) {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            // Success
                        } else {
                            // Failure
                        }
                    }
                };

                xhr.open('POST', '/', true);
                xhr.setRequestHeader('X-File-Name', file.name);
                xhr.setRequestHeader('X-File-Size', file.size);
                xhr.setRequestHeader('Content-Type', 'multipart/form-data');
                xhr.send(file);
            } else {
                setFileUploadMessage(`Please upload a smaller file (< ${fileSizeLimit} MB).`);
            }
        }
    };

    const setProgressMaxValue = (e) => {
        if (e.lengthComputable) {
            setProgress(e.total);
        }
    };

    const updateFileProgress = (e) => {
        if (e.lengthComputable) {
            setProgress(e.loaded);
        }
    };

    useEffect(() => {
        // Reset selected media when selectedAPI changes
        setSelectedImages([]);
        setSelectedVideos([]);
        setSelectedGifs([]);
    }, [selectedAPI]);


    return (
        <>
            <div class={type != "event" ? "modal-header" : "gallery-header"}>
                <div class="delete-modal-title modal-title h4">Add Media Content</div>
            </div>

            <div class={type != "event" ? "modal-body" : "gallery-body-model"}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="second">
                    <div className="content-generation-enabled">
                        <div className="cwfpBk">
                            <div className="sc-eLtQCx fLziUH">
                                <div className="tab-card-body">
                                    <div className="tab-content">
                                        <Nav variant="pills" className="flex-column media-post-tab">
                                            <Nav.Item >
                                                {type != "event" && <Nav.Link
                                                    eventKey="first"
                                                    className="social-tab-link"
                                                >

                                                    <span className="tab-user-name">
                                                        My Uploads
                                                    </span>
                                                </Nav.Link>}
                                                <Nav.Link
                                                    eventKey="second"
                                                    className="social-tab-link"
                                                    onClick={() => handleAPISelection('unsplash')}

                                                >
                                                    {/* <div className="channel-list-box-icon facebook-profile">
                                                        <div className="user-profile-box"><FaUnsplash /></div>
                                                    </div> */}
                                                    <span className="tab-user-name"  >
                                                        Unsplash
                                                    </span>
                                                </Nav.Link>
                                                <Nav.Link
                                                    eventKey="third"
                                                    className="social-tab-link"
                                                    onClick={() => handleAPISelection('pexels')}

                                                >
                                                    {/* <div className="channel-list-box-icon facebook-profile">
                                                        <div className="user-profile-box"><CgPexels /></div>
                                                    </div> */}
                                                    <span className="tab-user-name">
                                                        Pexels
                                                    </span>
                                                </Nav.Link>
                                                <Nav.Link
                                                    eventKey="fourth"
                                                    className="social-tab-link"
                                                    onClick={() => handleAPISelection('shutterstock')}

                                                >
                                                    {/* <div className="channel-list-box-icon facebook-profile">
                                                        <div className="user-profile-box"><SiShutterstock /></div>
                                                    </div> */}
                                                    <span className="tab-user-name">
                                                        Shutterstock
                                                    </span>
                                                </Nav.Link>
                                                {/* <Nav.Link
                                                    eventKey="fifth"
                                                    className="social-tab-link"
                                                >
                                                 
                                                    <span className="tab-user-name">
                                                        Storyblocks
                                                    </span>
                                                </Nav.Link> */}
                                                <Nav.Link
                                                    eventKey="sixth"
                                                    className="social-tab-link"
                                                    onClick={() => handleAPISelection('tenor')}
                                                >
                                                    {/* <div className="channel-list-box-icon facebook-profile">
                                                        <div className="user-profile-box"><PiTextTFill /></div>
                                                    </div> */}
                                                    <span className="tab-user-name">
                                                        Tenor
                                                    </span>
                                                </Nav.Link>
                                                <Nav.Link
                                                    eventKey="seventh"
                                                    className="social-tab-link"
                                                    onClick={() => handleAPISelection('giphy')}

                                                >
                                                    {/* <div className="channel-list-box-icon facebook-profile">
                                                        <div className="user-profile-box"><SiGiphy /></div>
                                                    </div> */}
                                                    <span className="tab-user-name">
                                                        Giphy
                                                    </span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="social-tab-content">
                            <div className="hPOmAZ">
                                <div className="cJMQxa">
                                    <div className="d-flex flex-row justify-content-between media-post-tab-top">
                                        <div className="nav-item">
                                            <a href="#" className={`social-tab-link ${tabIndex == "image" && "active"}`} onClick={() => { setTabIndex("image") }}>
                                                <div className="channel-list-box-icon facebook-profile">
                                                    <div className="user-profile-box"><IoImages /></div>
                                                </div>
                                                <span className="tab-user-name">
                                                    Images
                                                </span>
                                            </a>
                                        </div>
                                        <div className="nav-item">
                                            <a href="#" className={`social-tab-link ${tabIndex == "video" && "active"}`} onClick={() => { setTabIndex("video") }}>
                                                <div className="channel-list-box-icon facebook-profile">
                                                    <div className="user-profile-box"><MdOutlineVideoLibrary /></div>
                                                </div>
                                                <span className="tab-user-name">
                                                    Video
                                                </span>
                                            </a>
                                        </div>
                                        <div className="nav-item">
                                            <a href="#" className={`social-tab-link ${tabIndex == "Gif" && "active"}`} onClick={() => { setTabIndex("Gif") }}>
                                                <div className="channel-list-box-icon facebook-profile">
                                                    <div className="user-profile-box"><HiGif /></div>
                                                </div>
                                                <span className="tab-user-name">
                                                    Animations
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="search-box">
                                        <div className="search-box-input">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="search-input"
                                                value={searchTerm}
                                                onChange={handleSearch}
                                            />
                                        </div>
                                        <div className="search-box-button">
                                            <button type="button" onClick={() => onAddPost()} className="search-button">Add to post</button>
                                        </div>
                                    </div>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className="sc-eLtQCx">
                                                <div className="tab-card-body">
                                                    <form id="file-upload-form" className="uploader">
                                                        <input
                                                            id="file-upload"
                                                            type="file"
                                                            name="fileUpload"
                                                            accept="image/*"
                                                            onChange={handleFileSelect}
                                                        />
                                                        <label htmlFor="file-upload" id="file-drag" className={isDragging ? 'hover' : 'modal-body file-upload'}>
                                                            <img id="file-image" src={fileImageSrc} alt="Preview" className={file ? '' : 'hidden'} />
                                                            <div id="start" className={file ? 'hidden' : ''}>
                                                                <i className="fa fa-download" aria-hidden="true"></i>
                                                                <div>Select a file or drag here</div>
                                                                <div id="notimage" className={fileImageSrc ? 'hidden' : ''}>{fileUploadMessage}</div>
                                                                <span id="file-upload-btn" className="btn btn-primary">Select a file</span>
                                                            </div>
                                                            <div id="response" className={file ? '' : 'hidden'}>
                                                                <div id="messages">{fileName}</div>
                                                                <progress className="progress" id="file-progress" value={progress} max="100">
                                                                    <span>{Math.round((progress / fileSizeLimit) * 100)}</span>%
                                                                </progress>
                                                            </div>
                                                        </label>
                                                    </form>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={
                                            selectedAPI === "unsplash" ? "second" :
                                                selectedAPI === "pexels" ? "third" :
                                                    selectedAPI === "shutterstock" ? "fourth" :
                                                        selectedAPI === "tenor" ? "sixth" :
                                                            selectedAPI === "giphy" ? "seventh" :
                                                                "default"
                                        }>
                                            {isUnsupportedCombination(selectedAPI, tabIndex) && (
                                                <h4>{`${selectedAPI.charAt(0).toUpperCase() + selectedAPI.slice(1)} does not provide ${tabIndex}s`}</h4>
                                            )}
                                            {!loading && dataAvailable && !isUnsupportedCombination(selectedAPI, tabIndex) && (

                                                <div className="sc-eLtQCx">
                                                    <div className="tab-card-body">
                                                        {tabIndex === 'image' && (
                                                            <div className="media-post-tab-body">

                                                                <div className="images-list">
                                                                    {imageUrls.map((image, index) => (
                                                                        <div
                                                                            className={`image-box ${selectedImages.includes(index) ? 'active' : ''}`}
                                                                            key={index}
                                                                            onClick={() => toggleImageSelection(index)}
                                                                        >
                                                                            <img src={image} alt={`image-${index}`} />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>)}
                                                        {tabIndex === 'video' && (
                                                            <div className="media-post-tab-body">

                                                                <div className="videos-list">

                                                                    {videoUrls.map((video, index) => (
                                                                        <div
                                                                            className={`video-box-media ${selectedVideos.includes(index) ? 'active' : ''}`}
                                                                            key={index}
                                                                            onClick={() => toggleVideoSelection(index)}
                                                                        >
                                                                            <video width="320" height="240" controls>
                                                                                <source src={video} type="video/mp4" />
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>)}
                                                        {tabIndex === 'Gif' && (
                                                            <div className="media-post-tab-body">
                                                                <div className="gifs-list">
                                                                    {gifUrls.map((gif, index) => (
                                                                        <div
                                                                            className={`gif-box ${selectedGifs.includes(index) ? 'active' : ''}`}
                                                                            key={index}
                                                                            onClick={() => toggleGifSelection(index)}
                                                                        >
                                                                            <img src={gif} alt={`gif-${index}`} />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>)}
                                                    </div>
                                                </div>
                                            )}

                                            {loading && <p>Loading...</p>}

                                            {/* {!loading && !dataAvailable && !isUnsupportedCombination(selectedAPI, tabIndex) && (
                                                <p>No data available for this combination</p>
                                            )} */}

                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default MediaPost;