import React from 'react'
import EventsNearbyMapNav from '../../Components/EventsNearbyMapNav/EventsNearbyMapNav'
import GoogleMap from "./GoogleMap"
import CardLoading from "./CardLoading"
import { eventsDataContext } from '../../contexts/context/eventsDataContext'
import { useHistory } from 'react-router-dom'
const API_URL = process.env.REACT_APP_API_URL;

function EventsNearbyMap() {

    const history = useHistory();


    const { eventsData } = React.useContext(eventsDataContext);


    return (
        <div className='events-nearby'>
            {/* <EventsNearbyMapNav /> */}
            <div className="main-sec d-flex">
                <div className="events-panel custom-scroll" style={{ overflowY: 'scroll' }}>
                    <div className='d-flex flex-column' style={{ height: '100%' }}>
                        <div className="flex-grow-1 ">
                            {eventsData.isFetching ? (
                                <CardLoading />
                            ) : (
                                <>
                                    {
                                        eventsData.events.length > 0 ? (
                                            <>

                                                {
                                                    eventsData.events.map((event, index) => {

                                                        const pageJson = JSON.parse(event.page_json);

                                                        const eventProperties = pageJson.find(item => item.type === "EVENT").properties;

                                                        console.log("eventProperties", eventProperties);

                                                        const eventHeader = pageJson.find(item => item.type === "HEADER").properties;
                                                        let hostName1
                                                        if (Array.isArray(eventProperties.hostName)) {
                                                            hostName1 = eventProperties.hostName.map((i) => i).join(", ");
                                                        } else {
                                                            hostName1 = eventProperties.hostName;
                                                        }
                                                        return (

                                                            <div 
                                                            key={event._id} 
                                                            className="section-body d-flex align-items-start"
                                                            onClick={() => history.push(`/${event.backhalf}`)}
                                                            >
                                                                <div className="left-body">
                                                                    <div className="img-wrapper">
                                                                        {/* <img src={`${API_URL}/flowpage/${eventHeader.backgroundImage}`} alt="" /> */}
                                                                        {Array.isArray(eventHeader.backgroundImage) ? (
                                                                            eventHeader.backgroundImage.map((image, index) => (
                                                                                <img
                                                                                    key={index}
                                                                                    src={API_URL + "flowpage/" + image}
                                                                                    alt={`host-${index}`}
                                                                                    style={{
                                                                                        marginTop: '4px',
                                                                                        width: '24px',
                                                                                        height: '24px',
                                                                                        borderRadius: '50%',
                                                                                        marginRight: '8px'
                                                                                    }}
                                                                                />
                                                                            ))
                                                                        ) : (
                                                                                <img src={`${API_URL}/flowpage/${eventHeader.backgroundImage}`} alt="" /> 
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="right-body ms-2">
                                                                    <div className="head">{eventProperties.eventTitle}</div>
                                                                    <div className="d-flex flex-column p-0">
                                                                        <div className="d-flex align-items-center" style={{ marginTop: '5px' }}>
                                                                            <div className="avatars d-flex align-items-center">
                                                                                <img src="https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=16,height=16/avatars/rw/c4774c11-3d3b-4f23-a1b5-5d4dcbf728be" alt="" />
                                                                                <img src="https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=16,height=16/avatars/2c/69eaedb2-fa10-4fa9-88dd-0e9eec01a338" alt="" />
                                                                                <img src="https://cdn.lu.ma/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=16,height=16/avatars-default/avatar_46.png" alt="" />
                                                                            </div>
                                                                            <div className="d-flex text-ellipsis light-txt ms-2">
                                                                                By {hostName1}
                                                                            </div>
                                                                        </div>
                                                                        <div className="light-txt d-flex align-items-center mt-1">
                                                                            {eventProperties.startDate} - {eventProperties.location}
                                                                        </div>
                                                                        <div className="d-flex align-items-center gap-2 mt-1">
                                                                            <div className="badge rounded-pill">Sold Out</div>
                                                                            <div className="avatars d-flex align-items-center">
                                                                                <img src="https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=16,height=16/avatars/rw/c4774c11-3d3b-4f23-a1b5-5d4dcbf728be" alt="" />
                                                                                <img src="https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=16,height=16/avatars/2c/69eaedb2-fa10-4fa9-88dd-0e9eec01a338" alt="" />
                                                                                <img src="https://cdn.lu.ma/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=16,height=16/avatars-default/avatar_46.png" alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })


                                                }

                                            </>

                                        ) : (

                                            <>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        height: "200px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {/* Sad Face */}
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            width: "80px",
                                                            height: "80px",
                                                            border: "3px solid #6c757d",
                                                            borderRadius: "50%",
                                                        }}
                                                    >
                                                        {/* Eyes */}
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: "25%",
                                                                left: "18%",
                                                                display: "flex",
                                                                gap: "15px",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    width: "10px",
                                                                    height: "10px",
                                                                    backgroundColor: "#6c757d",
                                                                    borderRadius: "50%",
                                                                    display: "inline-block",
                                                                }}
                                                            ></span>
                                                            <span
                                                                style={{
                                                                    width: "10px",
                                                                    height: "10px",
                                                                    backgroundColor: "#6c757d",
                                                                    borderRadius: "50%",
                                                                    display: "inline-block",
                                                                }}
                                                            ></span>
                                                        </div>
                                                        {/* Mouth */}
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                bottom: "20%",
                                                                left: "50%",
                                                                width: "40px",
                                                                height: "20px",
                                                                border: "2px solid #6c757d",
                                                                borderRadius: "50%",
                                                                borderTop: "none",
                                                                transform: "translateX(-50%) rotate(180deg)",
                                                            }}
                                                        ></div>
                                                    </div>
                                                    {/* Text */}
                                                    <p
                                                        style={{
                                                            color: "#6c757d",
                                                            fontSize: "1rem",
                                                            fontWeight: "500",
                                                            marginTop: "15px",
                                                        }}
                                                    >
                                                        Sorry, no events nearby
                                                    </p>
                                                </div>
                                            </>
                                        )


                                    }

                                </>

                            )
                            }


                        </div>
                    </div>
                </div>
                <div className="map-section">
                    <GoogleMap />
                </div>
            </div>
        </div>
    )
}

export default EventsNearbyMap