// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAMe-JS3zCtupBoAxlr6_ZWT2wa5lF8BUU",
  authDomain: "socialprojects-2d6f0.firebaseapp.com",
  projectId: "socialprojects-2d6f0",
  storageBucket: "socialprojects-2d6f0.appspot.com",
  messagingSenderId: "758358811363",
  appId: "1:758358811363:web:5aff094889c3075c941bd6",
  measurementId: "G-XDFN0G03YT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export { app };
// npm install -g firebase-tools
// firebase login
// firebase init
// firebase deploy

// https://socialprojects-2d6f0.firebaseapp.com/__/auth/handler
