import React, { useEffect, useState } from "react";
import { MdTitle } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { useBuilderContext } from "../../../builderContext";
import { v4 as uuidv4 } from "uuid";
import SunEditors from "../../../Components/SunEditors";

export function ParkingList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdTitle />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Parking</h2>
                            <p className="add-block-btn-text-desc">Add a Parking to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}




export function ParkingAdd() {
    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();
    const { addComponent, editComponent, myComponents } = useBuilderContext();

    const unique_id = new URLSearchParams(location.search).get("unique_id");
    const position = new URLSearchParams(location.search).get("position");

    let [parkingInfo, setParkingInfo] = useState({
        heading: "Parking",
        unique_id: unique_id ?? uuidv4(),
        parkingDetails: "",
    });

    useEffect(() => {
        if (unique_id) {
            const existingComponent = myComponents
                .filter((c) => c.type === "PARKING")
                .map((c) => c.properties)
                .find((c) => c.unique_id === unique_id);

            if (existingComponent) {
                setParkingInfo((prevState) => ({
                    ...prevState,
                    heading: existingComponent.heading,
                    unique_id: existingComponent.unique_id,
                    parkingDetails: existingComponent.parkingDetails || "",  
                }));
            }
        }
    }, [unique_id, myComponents]);

    const handleParkingChange = (value) => {
        setParkingInfo((prevState) => ({
            ...prevState,
            parkingDetails: value,  
        }));
    };

    const saveData = () => {
        if (unique_id) {
            editComponent(position, "PARKING", parkingInfo, true);
        } else {
            addComponent("PARKING", parkingInfo, true);
        }
        history.push(`/editEvent/${id}/${unique_id ? "layout" : "addBlock"}`);
    };

    const goBackLink = `/editEvent/${id}/${unique_id ? "layout" : "addBlock"}`;

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to={goBackLink}>
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor="heading" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">
                            {unique_id ? "Edit Parking" : "Add Parking"}
                        </label>
                    </div>
                </div>

                <div className="form_box cvtPmc">
                    <div className="form-group did-floating-label-content">
                        <input
                            disabled
                            className="form-control did-floating-input"
                            id="heading"
                            placeholder=""
                            value={parkingInfo.heading}
                            onChange={(e) => {
                                setParkingInfo((prevState) => ({
                                    ...prevState,
                                    heading: e.target.value,
                                }));
                            }}
                        />
                        <label htmlFor="heading" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label">
                            Heading
                        </label>
                    </div>

                    <div className="form-group did-floating-label-content">
                        <div className="parking-item">
                            <div className="form-group did-floating-label-content">
                                <SunEditors
                                    description={parkingInfo.parkingDetails}
                                    handleParkingChange={handleParkingChange}
                                />
                                <label htmlFor="description" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label">
                                    Description
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={saveData}>
                    {unique_id ? "Update" : "Save"}
                </button>
            </div>
        </>
    );
}



