import React, { useState, useEffect, useContext } from 'react';
import './eventCard.css';
import { getSettings, saveSetting } from '../../BackendApi/Api/Event';
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { EventContext } from '../../EventBuilder/EventsContext';
import { useParams } from 'react-router-dom';

const EventsSettings = () => {
    let { id } = useParams();
    let event_id = id;
    const { formData, setFormData } = useContext(EventContext);
    const [newFromData, setNewFormData] = useState({
        eventType: 0,
        capacityType: 0,
        maxAttendees: 0,
        isWaitlist: false,
        showEventAddress: 0,
        showGuestList: false,
        showGuestCount: false,
        showTicketsLeft: false,
        showHosts: false,
        statusTag: 0
    });
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    useEffect(() => {
        if (newFromData.capacityType == 1 && newFromData.maxAttendees > 0) {
            setFormData((prevData) => ({
                ...prevData,
                statusTag: newFromData.statusTag
            }));
        }
    }, [newFromData.capacityType, newFromData.maxAttendees]);

    const updateEvent = async () => {
        try {
            const result = await saveSetting({ event_id, ...newFromData });
            if (result.data.status) {
                PNotify.success({
                    title: 'Success',
                    text: 'Event settings updated successfully',
                });
                setFormData(result.data.data);
            } else {
                PNotify.error({
                    title: 'Error',
                    text: result.data.message,
                });
            }
        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: error.message,
            });
        }
    };

    const getSettingData = async () => {
        try {
            const result = await getSettings({ event_id });
            if (result.data.status) {
                setFormData(result.data.data);
            } else {
                PNotify.error({
                    title: 'Error',
                    text: 'Failed to fetch settings',
                });
            }
        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: error.message,
            });
        }
    };

    useEffect(() => {
        if (event_id) {
            getSettingData();
        }
    }, [event_id]);

    return (
        <div className="event-card">
            <form>
                <label>Display Event Address:</label>
                <select name="showEventAddress" onChange={handleInputChange} value={newFromData.showEventAddress}>
                    <option value={0}>Everyone</option>
                    <option value={1}>Only Registered</option>
                </select>

                <label>Type of Event:</label>
                <select name="eventType" onChange={handleInputChange} value={newFromData.eventType}>
                    <option value={0}>Free</option>
                    <option value={1}>Paid</option>
                </select>

                <label>Capacity:</label>
                <select name="capacityType" onChange={handleInputChange} value={newFromData.capacityType}>
                    <option value={0}>Unlimited</option>
                    <option value={1}>Max Attendees</option>
                </select>

                {newFromData.capacityType == 1 && (
                    <>
                        <label>Max Attendees:</label>
                        <input
                            type="number"
                            name="maxAttendees"
                            value={newFromData.maxAttendees}
                            onChange={handleInputChange}
                        />
                        <label>Over Capacity Waitlist?</label>
                        <select name="isWaitlist" onChange={handleInputChange} value={newFromData.isWaitlist}>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </>
                )}

                <label>Display Guest List?</label>
                <select name="showGuestList" onChange={handleInputChange} value={newFromData.showGuestList}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>

                <label>Display Guest Count?</label>
                <select name="showGuestCount" onChange={handleInputChange} value={newFromData.showGuestCount}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>

                <label>Display Tickets Left?</label>
                <select name="showTicketsLeft" onChange={handleInputChange} value={newFromData.showTicketsLeft}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>

                <label>Display Hosts Publicly?</label>
                <select name="showHosts" onChange={handleInputChange} value={newFromData.showHosts}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>

                <label>Tags</label>
                <select name="statusTag" onChange={handleInputChange} value={newFromData.statusTag}>
                    <option value={0}>Upcoming</option>
                    <option value={1}>Sold Out</option>
                    <option value={2}>Limited Seat</option>
                </select>

                <button type="button" className="update-btn" onClick={updateEvent}>
                    Update
                </button>
            </form>
        </div>
    );
};

export default EventsSettings;
