import { createContext, useState } from "react";

const EventContext = createContext();

// create a context for the user provider


const EventProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        eventType: 0,
        capacityType: 0,
        maxAttendees: 0,
        isWaitlist: false,
        showEventAddress: 0,
        showGuestList: false,
        showGuestCount: false,
        showTicketsLeft: false,
        showHosts: false,
        statusTag: 0
    });

    return (
        <EventContext.Provider value={{ formData, setFormData }}>
            {children}
        </EventContext.Provider>
    );
};

export { EventContext, EventProvider };