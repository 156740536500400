import React, { useEffect, useContext, useState, useMemo } from "react";
import { Route, Redirect, Link, useHistory } from "react-router-dom";
import { isAuthenticated } from "../BackendApi/Api/auth";
import { IoTimer } from "react-icons/io5";
import { UserContext } from "../UserContext";
import { getOwner } from '../BackendApi/Api/userApi'
import Sidebar from '../Components/Sidebar/Sidebar';
import { brandSelectionContext } from "../contexts/context/brandSelectionContext";
import AuthContext from "../AuthContext";
import UnableUser from "../Components/UnableUser";
import { AiTwotoneCloseSquare } from "react-icons/ai";
import VedankChatbot from "../Components/AstralAi/VedankChatbot";

const WEBSITE = process.env.REACT_APP_WEBSITE;

const PrivateRoute = ({ component: Component, type, ...rest }) => {

  const { sidebarBrands, sidebarBrandsDispatch } = useContext(brandSelectionContext);
  const { setIsAuthenticated, setIsCanvaConnected, setAuthData } = useContext(AuthContext);
  const history = useHistory();
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = React.useState(true);
  const { subscriptionStatus, setSubscriptionStatus, setStripe_customer_id, setOwnerEmail, subscriptionDetails, setSubscriptionDetails } = useContext(UserContext);
  const userIsAuthenticated = isAuthenticated();
  const user = JSON.parse(localStorage.getItem('user'));
  const [remainingDays, setRemainingDays] = React.useState(0);
  const isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
  const Canva_Auth = async () => {
    let state = user?.owner_id
    try {
      const response = await fetch(`${API_URL}/socials/check-auth?owner_id=${state}`, { method: 'GET' });
      const data = await response.json();

      setIsAuthenticated(data.authenticated);
      setIsCanvaConnected(data.canvaConnected);
    } catch (error) {
      console.error('Error checking authentication:', error);
    }

    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) return;
      const { type, message } = event.data;
      if (type === 'CANVA_AUTH') {
        setAuthData(message);
        setIsCanvaConnected(true);
        // openSuccessModal();
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };

  }
  const GetOwnerDetails = async () => {

    try {

      if (!user) {

        setLoading(false);
        history.push('/login');

      }
      else {

        const res = await getOwner({});

        console.log("Owner details:", res);

        if (res.data.status) {

          const hurata = res.data.data;

          if (hurata.ownerBrands.length === 0) {

            setLoading(false);

            history.push('/createbrand');

          }
          else {
            const subscription = JSON.parse(hurata.subscription_details);

            console.log("subscriptionwa", subscription,  subscription.items.data[0].plan.product);
            

            setSubscriptionDetails(subscription);

            setSubscriptionStatus(subscription.status);
            setStripe_customer_id(hurata.stripe_customer_id);
            setOwnerEmail(hurata.email);

            if (subscription.status === "trialing") {
              const trial_end = new Date(subscription.trial_end * 1000);
              const currentDate = new Date();
              setRemainingDays(Math.max(0, Math.round((trial_end - currentDate) / (1000 * 60 * 60 * 24))));
            }

            console.log("hurata brands", hurata.All_Brands);

            sidebarBrandsDispatch({ type: 'SET_BRANDS_DATA', payload: hurata.All_Brands });

            // sidebarBrandsDispatch({ type: 'SET_BRANDS_DATA', payload: hurata.All_Brands });
            const selectedbrand = localStorage.getItem('selectedbrand');
            if (!selectedbrand) {
              localStorage.setItem('selectedbrand', 'all');
            } else {
              // Check if any brand in hurata.brands has an _id that matches selectedbrand
              const selectebrandexists = hurata.All_Brands.some(brand => brand._id === selectedbrand);

              // If no matching brand is found, set selectedbrand to 'all'
              if (!selectebrandexists) {
                localStorage.setItem('selectedbrand', 'all');
              }
              else {
                sidebarBrandsDispatch({ type: 'SET_SELECTED_BRAND', payload: selectedbrand });
              }
            }

          }

        }
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleAdmin = () => {
    let getAdminData = JSON.parse(localStorage.getItem('adminData'))
    const userdata = JSON.parse(localStorage.getItem('user'));
    localStorage.removeItem("user");
    localStorage.removeItem("isAdmin");
    localStorage.setItem("user", JSON.stringify(getAdminData));
    window.location.href = "/admindashboard";
  }
  const renderSubscriptionBanner = () => {

    switch (subscriptionStatus) {
      case "trialing":
        return user.role == "1" ? null : (
          <div className="card plan-card">
            <div className="d-flex justify-content-center align-items-center gap-3">
              <span className="plan-title">
                <IoTimer /> Your Free Trial {remainingDays === 0 ? 'expires today' : `expires in ${remainingDays} days`}!
              </span>
              <div className="plan-price">
                <button className="btn plan-price-btn">
                  <Link to="/setting/price">Upgrade</Link>
                </button>
              </div>
              <div style={{ marginTop: 18 }}>
                {isAdmin == true && (user.role == "0" || user.role == "2") &&
                  <p className="plan-title">You are Login this Account <strong style={{ color: 'gray' }}>{user.fname + "" + user.lname}</strong>  Exit here

                    <AiTwotoneCloseSquare size={25} onClick={handleAdmin} />
                  </p>}
              </div>
            </div>
          </div>
        );
      case "active":
        return null;
      case "past_due":
        return user.role == "1" ? null : (
          <div className="card plan-card">
            <div className="d-flex justify-content-center align-items-center gap-3">
              <span className="plan-title">
                Your payment is past due. Please update your payment method.
              </span>
              <div className="plan-price">
                <button className="btn plan-price-btn">
                  <Link to="/setting/payment">Update Payment</Link>
                </button>
              </div>
            </div>
          </div>
        );
      case "canceled":
      case "unpaid":
      case "incomplete":
      case "incomplete_expired":
      default:
        return user.role == "1" ? null : (
          <div className="card plan-card">
            <div className="d-flex justify-content-center align-items-center gap-3">
              <span className="plan-title">
                Please upgrade your plan to continue enjoying our services!
              </span>
              <div className="plan-price">
                <button className="btn plan-price-btn">
                  <Link to="/setting/price">Upgrade</Link>
                </button>
              </div>
              <div style={{ marginTop: 18 }}>
                {isAdmin == true && (user.role == "0" || user.role == "2") &&
                  <p className="plan-title">You are Login this Account <strong style={{ color: 'gray' }}>{user.fname + "" + user.lname}</strong>  Exit here

                    <AiTwotoneCloseSquare size={25} onClick={handleAdmin} />
                  </p>}
              </div>
            </div>
          </div>
        );
    }
  };

  const memoizedOwnerDetails = useMemo(() => {
    // Make the API call and return the result
    return GetOwnerDetails();
  }, []);
  useEffect(() => {
    Canva_Auth()
  }, [])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userIsAuthenticated) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        }

        if (loading) {
          return <div>Authenticating User .......</div>; // Or a proper loading component
        }

        return (
          <>
            {user?.userLoginDisabled == true && !isAdmin ? <UnableUser /> :
              <>
                {renderSubscriptionBanner()}

                <div className="main-area">
                  <div className="dashboard-container d-flex">
                    <div className="sidebar-container">
                      <Sidebar />

                    </div>
                    <Component {...props} type={type} />
                  </div>
                </div>
                <VedankChatbot />
              </>
            }

          </>
        );
      }}
    />
  );
};

export default PrivateRoute;


