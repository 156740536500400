import React, { useEffect } from "react";
import { FiCopy } from "react-icons/fi";
import { useState, useRef } from "react";
import { BsArrowsFullscreen } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import { MdOutlineDesktopMac } from "react-icons/md";
import { FaTabletAlt, FaMobileAlt } from "react-icons/fa";

import { useBuilderContext } from "../builderContext";
import BuilderHeader from "./Components/TemplateView/BuilderHeader";

import { publishMinipage } from "../BackendApi/Api/Event";

import { toJpeg, toBlob, toPng } from 'html-to-image';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { v4 as uuidv4 } from "uuid";


import html2pdf from 'html2pdf.js';

import domToImage from 'dom-to-image';


// import domtoimage from 'dom-to-image';

import html2canvas from 'html2canvas';

import { saveAs } from 'file-saver';

// import { useScreenshot } from 'use-react-screenshot'
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import CopyCat from "../Components/CopyCat/CopyCat";
import SpinnerLoader from "../Components/SpinnerLoader/SpinnerLoader";
import 'suneditor/dist/css/suneditor.min.css';
import Event from "./Components/TemplateView/Event";

import { useParams } from 'react-router-dom';
import EventAgenta from "./Components/TemplateView/EventAgenta";
import EventParking from "./Components/TemplateView/EventParking";
import EventsImagesList from "./Components/TemplateView/EventsImagesList";
import EventsGalleryList from "./Components/TemplateView/EventsGalleryList";
import { HeadingList } from "./Components/AddBlockList/Header";
import { AdvancedMotionGraphicsBackground, AdvancedMotionGraphicsBackgroundspam, AnimatedBackground,  CosmicContainer, DynamicBackground, GalaxySpiral, GlitterBackground,  ParticleContainer, ProfessionalMotionBackground,    } from "./Components/demo";



function EventBuilderLeft({ color, buttonColor, fontFamily }) {

    const { id } = useParams();

    const { myComponents, smartlink_url, backgroundType, BackgroundColor, backgroundColor2, colorPosition, imagePosition, smartlinkeditdata, company_slug, setCompain_slug } = useBuilderContext();

    const [deviceView, setDeviceView] = useState('view-desktop');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loding, setLoding] = useState(false);
    const imageRef = useRef(null);


    const publish = () => {
        var element = document.getElementById("capture");
        element.classList.remove("page_builder_design_body");
        setLoding(true);
        const elementToCapture = imageRef.current;

        // Check if elementToCapture exists
        if (!elementToCapture) {
            PNotify.error({
                title: 'Error',
                text: 'Unable to publish page. Double check if any images are broken',
            });
            setLoding(false);
            return;
        }

        const scrollY = window.scrollY;
        const originalOverflow = elementToCapture.style.overflow;

        elementToCapture.style.overflow = 'hidden';

        // create image from toPng
        toJpeg(elementToCapture, { scrollY, quality: 3.0 })
            .then(dataUrl => {
                if (!dataUrl) {
                    throw new Error('Unable to publish page. Double check if any images are broken');
                }

                // Restore the UI state
                element.classList.add("page_builder_design_body");
                elementToCapture.style.overflow = originalOverflow;

                const base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, '');

                // Convert base64 string to Blob object
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], { type: 'image/jpeg' });

                console.log("blob", blob.type);



                const jsonData = myComponents;
                const componentsData = [];



                const formData = new FormData();
                formData.append('id', id);
                formData.append('page_json', JSON.stringify(jsonData));
                formData.append('page_thumbnail', blob);
                formData.append('background_color', color);
                formData.append('button_color', buttonColor);
                formData.append('font_family', fontFamily);
                formData.append('components', JSON.stringify(componentsData));
                formData.append('background_type', backgroundType);
                formData.append('background_color2', backgroundColor2);
                formData.append('background_color1', BackgroundColor);
                formData.append("background_Position", colorPosition);

                publishMinipage(formData).then((res) => {
                    if (res.data.status === true) {
                        PNotify.success({
                            title: 'Success',
                            text: 'Page Published Successfully',
                        });
                    } else {
                        PNotify.error({
                            title: 'Error',
                            text: res.data.message
                        });
                    }
                    setLoding(false);
                }).catch((err) => {
                    PNotify.error({
                        title: 'Error',
                        text: err.message
                    });
                    setLoding(false);
                });
            })
            .catch((error) => {
                console.error('Error capturing screenshot:', error);

                // Restore the UI state
                element.classList.add("page_builder_design_body");
                elementToCapture.style.overflow = originalOverflow;

                PNotify.error({
                    title: 'Error',
                    text: 'Unable to publish page. Double check if any images are broken',
                });
                setLoding(false);
            });
    };

    let urlWithoutProtocol = company_slug.split('://')[1];
    const renderAnimations = () => {
        switch (BackgroundColor) {
       
            case "ProfessionalMotionBackground":
                return <ProfessionalMotionBackground />;
            case "ParticleContainer":
                return <ParticleContainer />;
            case "AdvancedMotionGraphicsBackgroundspam":
                return <AdvancedMotionGraphicsBackgroundspam />;
            case "GlitterBackground":
                return <GlitterBackground />;
            case "DynamicBackground":
                return <DynamicBackground />;
            case "CosmicContainer":
                return <CosmicContainer />;
            case "AnimatedBackground":
                return <AnimatedBackground />;
            case "GalaxySpiral":
                return <GalaxySpiral />;
            case "AdvancedMotionGraphicsBackground":
                return <AdvancedMotionGraphicsBackground particleCount={75}
                    backgroundColor="#0f172a"
                    colorPalette={[
                        '#3b82f6',
                        '#10b981',
                        '#f43f5e'
                    ]} />;
            default:
            case "GalaxySpiral":
                return <GalaxySpiral />;
        }
    }


    return (
        <>

            <Modal
                centered
                show={show}
                onHide={handleClose}
                className="modal-center-show"
            >
                <Modal.Body>
                    <div className="show-preview-header">
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-desktop');
                                    }
                                }
                            ><MdOutlineDesktopMac /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-tablet');
                                    }
                                }
                            ><FaTabletAlt /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-mobile');
                                    }
                                }
                            ><FaMobileAlt /></button>
                        </div>
                    </div>
                    <div className="show-preview-body">
                        <div className="show-preview-content-2">
                            <div className={`preview-box-background ${deviceView}`}>
                                <div class="oEUMCt"></div>
                                <div className={`page_builder_design_body ${color}`}  >
                                    <div className={`builder-box-design goWHMr ${color}`} style={{ fontFamily: fontFamily }} ref={imageRef}>
                                        <div className="builder_content"  >



                                            {myComponents && myComponents.map((component, index) => (
                                                <>
                                                    {
                                                        component.type === "HEADER" ? <BuilderHeader data={component.properties} /> :
                                                            null
                                                    }
                                                </>
                                            ))}
                                            <div className="company_name">
                                                <h5>Powered with Astral</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="oEUMCterd"></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="page_builder_design_header">
                <div className="page_builder_design_header_container">
                    <div className="publish_btn_container">
                        <div className="d-flex align-items-center justify-content-between ms-4">

                            <p className="m-0">{urlWithoutProtocol}</p>
                            <CopyCat text={company_slug} />
                        </div>
                        {loding ? <SpinnerLoader /> : <button className="publish-btn" onClick={publish}>Publish Changes</button>}
                    </div>
                </div>
            </div>



            <div className="page_builder_design_body" id="capture">
                <div className=" ">
                    <div className=" " >
                        {renderAnimations()}
                    </div>
                </div>
                <div className="xmpr" style={{ zIndex: 999, background: "transparent" }}>

                    <div className="fill-screen-btn form-btn-right mb-3" >
                        <button type="button" className="btn full-screen-btn bg-green-dark" onClick={handleShow}><BsArrowsFullscreen /> Device Preview</button>
                    </div>
                    <div className={`builder-box-design goWHMr ${color}`} style={{ fontFamily: fontFamily, background: "none" }} ref={imageRef}>
                        <div className="builder_content"  >

                            <div >
                                {myComponents && myComponents.map((component, index) => (
                                    <>
                                        {
                                            component.type === "HEADER" &&
                                                component?.showStatus === true ?
                                                <BuilderHeader newValidate={true} event_id={id} data={component.properties} />
                                                :
                                                component.type === "EVENT" &&
                                                    component?.showStatus === true ?
                                                    <Event event_id={id} data={component.properties} /> :
                                                    component.type === "PARKING" &&
                                                        component?.showStatus === true ?
                                                        <EventParking event_id={id} data={component.properties} /> :
                                                        component.type === "AGENTA" &&
                                                            component?.showStatus === true ?
                                                            <EventAgenta event_id={id} data={component.properties} /> :
                                                            component.type === "IMAGES" &&
                                                                component?.showStatus === true ?
                                                                <EventsImagesList event_id={id} data={component.properties} /> :
                                                                component.type === "GALLERY" &&
                                                                    component?.showStatus === true ?
                                                                    <EventsGalleryList event_id={id} data={component.properties} /> :
                                                                    null
                                        }
                                    </>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default EventBuilderLeft;