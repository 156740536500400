import React from 'react'

const NotFoundpage = () => {
  return (
    <div>
          <div className="page404">
              <div className="page404-box">
                  <div className="page404-content text-center">
                      <h1>Look like you're lost in Space </h1>
                      <div className="position-relative">
                          <div className="page404-img">
                              <img src={require('../../landingassets/Images/404.png')} alt="404" className='img-page404' />
                          </div>
                          <h2>404</h2>
                      </div>
                      <div className="button-404" onClick={() => { window.location.href = "https://withastral.com"; }}>
                          <a>Go Home</a>
                      </div>
                  </div>
              </div>
          </div>
    </div>
  )
}

export default NotFoundpage
