import React, { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';

const IconSelection = ({ icons, currentIcons, handleIconClick }) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedIcons, setSelectedIcons] = useState([]);
    const handleCategoryChange = (e) => {
        const category = e.target.value;
        setSelectedCategory(category);
        setSelectedIcons(icons[category] || []);
    };



    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: "row", position: "relative" }}>
                <select
                    id="categoryDropdown"
                    value={selectedCategory}
                    className="form-control"
                    onChange={handleCategoryChange}
                >
                    <option value="">
                        {currentIcons?.foundCategory
                            ? currentIcons.foundCategory.charAt(0).toUpperCase() +
                            currentIcons.foundCategory.slice(1)
                            : 'Select social icon'}
                    </option>
                    {Object.keys(icons).map((category) => (
                        <option key={category} value={category}>
                            {category.charAt(0).toUpperCase() + category.slice(1)}
                        </option>
                    ))}
                </select>
                <BsChevronDown style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }} />
            </div>
            {selectedCategory && (
                <div style={{ marginTop: '20px' }}>
                    <p className="category-title">
                        {selectedCategory.charAt(0).toUpperCase() +
                            selectedCategory.slice(1)}{' '}
                        Icons:
                    </p>
                    <div style={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", gap: "10px" }}>
                        {selectedIcons.map((icon, index) => (
                            <div
                                key={index}
                                className="icon-item"
                                onClick={() => handleIconClick(icon)}
                            >
                                <img
                                    src={icon.link}
                                    alt={icon.name}
                                    width={50}
                                    height={50}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>

    );
};

export default IconSelection;
