import React from 'react'
import { useBuilderContext } from '../../builderContext';
import SocialSharePage from "../../Components/share/SocialShare";
import BuilderHeader from "../../PageBuilder/Components/TemplateView/BuilderHeader";
import BuilderTitle from "./flexComponents/BuilderTitle";
import BuilderIcon from "./flexComponents/BuilderIcon";
import BuilderButton from "./flexComponents/BuilderButton";
import BuilderText from "./flexComponents/BuilderText";
import ImageText from "./flexComponents/ImageText";
import YoutubeLink from "./flexComponents/YoutubeLink";
import Spotify from "./flexComponents/Spotify";
import ImageGrid from "./flexComponents/ImageGrid";
import Testimonials from "./flexComponents/Testimonials";
import ContactUs from "./flexComponents/ContactUs";
import Event from "./flexComponents/Event";
import YoutubeIframe from "./flexComponents/YoutubeIframe";
import { API_URL, REACT_APP_WEBSITE } from "../../BackendApi/Api/config";
import EventParking from "./flexComponents/EventParking";
import EventAgenta from "./flexComponents/EventAgenta";
import EventsImagesList from "./flexComponents/EventsImagesList";       
import EventsGalleryList from "./flexComponents/EventsGalleryList";


const EventPage = ({ renderComponent, url, renderAnimations, smartlink_id, brand_name, brand_img, }) => {
    let { myComponents, fontFamily, buttonColor, } = useBuilderContext();



    return (
        <div className='event-pub-page'>
            <div
                className="preview-box-background"
            >
                {renderAnimations()}
             
                <style>
                    {`
                        .event-pub-page .builder-box-design{
                            width: 1200px !important;
                        }
                        `}
                </style>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>

                    <SocialSharePage
                        type="portfolio"
                        url={url.href}
                        title="Check out this Page"
                        description="I found this great website and thought you might like it too."
                    />
                </div>
                <div className={`page_builder_design_body home-preview-body`}  >

                    <div className={`builder-box-design public_flex_design goWHMr`}

                        style={{ fontFamily: fontFamily, backgroundColor: "transparent", boxShadow: "none", overflow: "auto", margin: "0 auto", marginBottom: 80 }}
                    >

                        <div className="builder_content">
                            <div className="container">
                                <div className="row">
                                    {/* Header Section */}
                                    <div className="col-12 col-md-4">
                                        {myComponents.map(
                                            (component, index) =>
                                                component.type === "HEADER" && (
                                                    <BuilderHeader
                                                        key={index}
                                                        types={true}
                                                        data={component.properties}
                                                    />
                                                )
                                        )}
                                        <div style={{ display: "flex", gap: 10, width: "60%", marginBottom: "10px" }}>
                                            <div>
                                                <img
                                                    src={(brand_img?.startsWith("http://") || brand_img?.startsWith("https://")) ? brand_img : API_URL + "flowpage/" + brand_img}
                                                    style={{ width: "50px", height: "auto", borderRadius: 4 }} alt={"data.imageAltText"} />
                                            </div>
                                            <div>
                                                <p className="m-0">
                                                    Presented by <br />
                                                    <a href={`${REACT_APP_WEBSITE}brand/${brand_name}`} style={{ color: "white", textDecoration: "none", }} target="_blank" rel="noopener noreferrer">{brand_name}</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Main Section */}
                                    <div className="col-12 col-md-8" style={{color: "white"}}>
                                        {myComponents.map((component, index) =>
                                            component.type !== "HEADER" && component?.showStatus === true ? (
                                                <React.Fragment key={index}>
                                                    {component.type === "EVENT" ? (
                                                        <Event
                                                            brand_img={brand_img}
                                                            brand_name={brand_name}
                                                            types={true}
                                                            smartlink_id={smartlink_id}
                                                            data={component.properties}
                                                        />
                                                    ) : component.type === "AGENTA" ? (
                                                        <EventAgenta
                                                            smartlink_id={smartlink_id}
                                                            data={component.properties}
                                                        />
                                                    ) : component.type === "IMAGES" ? (
                                                        <EventsImagesList
                                                            smartlink_id={smartlink_id}
                                                            data={component.properties}
                                                        />
                                                    ) : component.type === "GALLERY" ? (
                                                        <EventsGalleryList
                                                            smartlink_id={smartlink_id}
                                                            data={component.properties}
                                                        />
                                                    ) : component.type === "PARKING" ? (
                                                        <EventParking
                                                            smartlink_id={smartlink_id}
                                                            data={component.properties}
                                                        />
                                                    ) : null}
                                                </React.Fragment>
                                            ) : null
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventPage

