import React, { useEffect, useRef, useMemo, useState } from "react";
import "./demo.css"
import { motion } from 'framer-motion';
const generateUniqueTransforms = () => {
    const transforms = [
        `translate(${Math.random() * 200 - 100}vw, ${Math.random() * 200 - 100}vh)`,
        `translate(${Math.random() * 150 - 75}vw, ${Math.random() * 150 - 75}vh) rotate(${Math.random() * 360}deg)`,
        `translate(${Math.random() * 180 - 90}vw, ${Math.random() * 180 - 90}vh) scale(${1.5 + Math.random()})`,
        `translate(${Math.random() * 220 - 110}vw, ${Math.random() * 220 - 110}vh) skew(${Math.random() * 30}deg, ${Math.random() * 30}deg)`,
        `translate(${Math.random() * 160 - 80}vw, ${Math.random() * 160 - 80}vh) rotate(${Math.random() * 540}deg) scale(${1.2 + Math.random()})`,
        `translate(${Math.random() * 190 - 95}vw, ${Math.random() * 190 - 95}vh) rotate(${Math.random() * 720}deg)`,
        `translate(${Math.random() * 170 - 85}vw, ${Math.random() * 170 - 85}vh) scale(${1.3 + Math.random()}) skew(${Math.random() * 20}deg)`,
        `translate(${Math.random() * 210 - 105}vw, ${Math.random() * 210 - 105}vh) rotate(${Math.random() * 450}deg) scale(${1.4 + Math.random()})`,
        `translate(${Math.random() * 230 - 115}vw, ${Math.random() * 230 - 115}vh) skew(${Math.random() * 40}deg, ${Math.random() * 40}deg) scale(${1.6 + Math.random()})`,
        `translate(${Math.random() * 240 - 120}vw, ${Math.random() * 240 - 120}vh) rotate(${Math.random() * 630}deg) skew(${Math.random() * 25}deg)`
    ];

    return transforms;
};
// Generate unique animations
const generateUniqueAnimations = () => {
    const transforms = generateUniqueTransforms();

    return transforms.map((transform, index) => `
    @keyframes particleMove${index} {
      0% { 
        transform: translate(0, 0) scale(1); 
        opacity: 1; 
      }
      100% { 
        transform: ${transform} scale(2); 
        opacity: 0; 
      }
    }
    @keyframes particleGlow${index} {
      0% { 
        box-shadow: 0 0 ${5 + Math.random() * 10}px rgba(255,255,255,0.3); 
      }
      100% { 
        box-shadow: 0 0 ${15 + Math.random() * 15}px rgba(255,255,255,${0.6 + Math.random() * 0.4}); 
      }
    }
  `).join('\n');
};

const useParticles = (count) => {
    const uniqueAnimations = useMemo(generateUniqueAnimations, []);

    const particles = useMemo(() => {
        return Array.from({ length: count }, (_, index) => ({
            id: index,
            width: Math.random() * 6 + 4, // 4-10px
            left: Math.random() * 100,
            top: Math.random() * 100,
            delay: Math.random() * 10,
            animationIndex: index % 10 // Cycle through 10 unique animations
        }));
    }, [count]);

    return { particles, uniqueAnimations };
};

export const AnimatedBackground = ({ particleCount = 100 }) => {
    const { particles, uniqueAnimations } = useParticles(particleCount);

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                margin: 0,
                overflow: 'hidden',
                background: 'linear-gradient(135deg, #9b59b6, #2980b9, #e67e22)',
                backgroundSize: '400% 400%',
                animation: 'backgroundShift 15s ease infinite',
                zIndex: -1
            }}
        >
            {particles.map((particle) => (
                <div
                    key={particle.id}
                    style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        borderRadius: '50%',
                        width: `${particle.width}px`,
                        height: `${particle.width}px`,
                        left: `${particle.left}%`,
                        top: `${particle.top}%`,
                        animation: `particleMove${particle.animationIndex} 8s linear infinite, 
                        particleGlow${particle.animationIndex} 3s alternate infinite`,
                        animationDelay: `${particle.delay}s`
                    }}
                />
            ))}
            <style>{`
        @keyframes backgroundShift {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
        ${uniqueAnimations}
      `}</style>
        </div>
    );
};
export const TailStarsAnimation = () => {
    const [stars, setStars] = useState([]);

    useEffect(() => {
        // Generate shooting stars once on component mount
        const generateStars = () =>
            Array.from({ length: 220 }).map((_, index) => ({
                id: index,
                top: Math.random() * 100,
                left: Math.random() * 100,
                size: 2 + Math.random() * 3, // Star size
                duration: `${3 + Math.random() * 4}s`, // Animation duration
                delay: `${Math.random() * 5}s`, // Staggered starts
            }));

        setStars(generateStars());
    }, []);

    const shootingStarsBackgroundStyle = {
        position: "absolute",
        inset: 0,
        background: "linear-gradient(to bottom, #000428, #004e92)", // Night sky gradient
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: -1,
    };

    const shootingStarStyle = {
        position: "absolute",
        background: "radial-gradient(circle, white 20%, transparent 70%)",
        boxShadow: "0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6)",
        borderRadius: "10%",
        opacity: 0,
        animation: "shooting-star-trail linear infinite",
    };

    const keyframesStyle = `
    @keyframes shooting-star-trail {
      0% {
        opacity: 1;
        transform: translate(0, 0) scale(1);
      }
      30% {
        opacity: 1;
        transform: translate(-20vw, 20vh) scale(1.5);
      }
      100% {
        opacity: 0;
        transform: translate(-40vw, 40vh) scale(0.8);
      }
    }
  `;

    return (
        <div style={shootingStarsBackgroundStyle}>
            <style>{keyframesStyle}</style>
            {stars.map((star) => (
                <div
                    key={star.id}
                    style={{
                        ...shootingStarStyle,
                        top: `${star.top}%`,
                        left: `${star.left}%`,
                        width: `${star.size}px`,
                        height: `${star.size}px`,
                        animationDuration: star.duration,
                        animationDelay: star.delay,
                    }}
                />
            ))}
        </div>
    );
};

export const GalaxySpiral = () => {
    return (
        <div className="fixed inset-0 -z-10">
            <style>
                {`
          .galaxy-sky {
            background: radial-gradient(circle at center, #1a1b25 0%, #090a0f 100%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
          .galaxy-star {
            position: absolute;
            border-radius: 50%;
            animation: spiral var(--duration) infinite linear;
          }
          @keyframes spiral {
            from {
              transform: rotate(0deg) translateX(var(--radius)) rotate(0deg);
            }
            to {
              transform: rotate(360deg) translateX(var(--radius)) rotate(-360deg);
            }
          }
        `}
            </style>
            <div className="galaxy-sky">
                {[...Array(150)].map((_, i) => {
                    const radius = Math.random() * 40 + 10;
                    return (
                        <div
                            key={i}
                            className="galaxy-star"
                            style={{
                                top: "50%",
                                left: "50%",
                                width: `${Math.random() * 3}px`,
                                height: `${Math.random() * 3}px`,
                                background: `rgba(255,255,255,${Math.random() * 0.8 + 0.2})`,
                                "--radius": `${radius}vh`,
                                "--duration": `${Math.random() * 20 + 10}s`,
                                animationDelay: `${Math.random() * -20}s`,
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export const AdvancedMotionGraphicsBackground = ({
    particleCount = 50,
    backgroundColor = '#0a0a1a',
    colorPalette = [
        '#3b82f6',
        '#10b981',
        '#6366f1',
        '#f43f5e',
        '#8b5cf6'
    ]
}) => {
    // Generate unique particles
    const generateParticles = () => {
        return Array.from({ length: particleCount }, (_, index) => ({
            id: index,
            size: Math.random() * 10 + 5,
            delay: Math.random() * 10,
            duration: Math.random() * 10 + 10,
            color: colorPalette[Math.floor(Math.random() * colorPalette.length)],
            left: Math.random() * 100,
            top: Math.random() * 100,
            translateX: (Math.random() - 0.5) * 200,
            translateY: (Math.random() - 0.5) * 200,
            rotate: Math.random() * 360
        }));
    };

    const particles = generateParticles();

    return (
        <div
            className="motion-graphics-background"
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: backgroundColor,
                overflow: 'hidden',
                zIndex: -1,
                perspective: '1000px'
            }}
        >
            {particles.map((particle) => (
                <div
                    key={particle.id}
                    style={{
                        position: 'absolute',
                        width: `${particle.size}px`,
                        height: `${particle.size}px`,
                        backgroundColor: particle.color,
                        borderRadius: '50%',
                        left: `${particle.left}%`,
                        top: `${particle.top}%`,
                        animation: `
              particleMove${particle.id} ${particle.duration}s linear infinite,
              particleRotate${particle.id} ${particle.duration * 2}s linear infinite
            `,
                        boxShadow: `0 0 20px 5px ${particle.color}80`
                    }}
                />
            ))}
            <style>{`
        ${particles.map((particle) => `
          @keyframes particleMove${particle.id} {
            0% { 
              transform: 
                translate(0, 0) 
                scale(1);
              opacity: 1;
            }
            50% {
              transform: 
                translate(${particle.translateX}%, ${particle.translateY}%) 
                scale(1.5);
              opacity: 0.7;
            }
            100% { 
              transform: 
                translate(${particle.translateX * 2}%, ${particle.translateY * 2}%) 
                scale(2);
              opacity: 0;
            }
          }
          
          @keyframes particleRotate${particle.id} {
            0% { 
              transform: rotate(0deg);
            }
            100% { 
              transform: rotate(${particle.rotate}deg);
            }
          }
        `).join('')}
      `}</style>
        </div>
    );
};

export const GlitterBackground = () => {
    // Advanced particle generation with randomness
    const renderGlitterParticles = () => {
        return Array.from({ length: 150 }).map((_, index) => {
            // More diverse particle types
            const particleTypes = [
                "star",
                "sparkle",
                "diamond",
                "bubble",
                "cosmic",
            ];
            const particleType =
                particleTypes[Math.floor(Math.random() * particleTypes.length)];

            // Dynamic sizing and positioning
            const baseSize = Math.random() * 15 + 2;
            const size = particleType === "star" ? baseSize * 1.5 : baseSize;
            const dynamicDelay = Math.random() * 5;
            const depth = Math.random();

            return (
                <div
                    key={index}
                    className={`glitter-particle ${particleType}`}
                    style={{
                        left: `${Math.random() * 120 - 10}%`, // Extend slightly beyond 100%
                        top: `${Math.random() * 120 - 10}%`,
                        width: `${size}px`,
                        height: `${size}px`,
                        animationDelay: `${dynamicDelay}s`,
                        zIndex: Math.floor(depth * 10),
                        opacity: depth * 0.8,
                    }}
                />
            );
        });
    };

    // Multiple gradient and texture overlays
    const renderBackgroundLayers = () => {
        const layers = [
            {
                background:
                    "linear-gradient(135deg, rgba(74,20,140,0.6) 0%, rgba(106,27,154,0.4) 50%, rgba(142,36,170,0.6) 100%)",
                mixBlendMode: "overlay",
            },
            {
                background:
                    "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 70%)",
                mixBlendMode: "soft-light",
            },
            {
                background:
                    "repeating-linear-gradient(45deg, rgba(255,255,255,0.02) 0, rgba(255,255,255,0.05) 10px, transparent 10px, transparent 20px)",
                mixBlendMode: "color-dodge",
            },
        ];

        return layers.map((layer, index) => (
            <div
                key={index}
                className="background-layer"
                style={{
                    ...layer,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    pointerEvents: "none",
                }}
            />
        ));
    };

    return (
        <>
            <style>{`
                .glitter-background {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(45deg, #4a148c, #6a1b9a, #8e24aa);
                    overflow: hidden;
                    perspective: 1000px;
                }

                .glitter-particle {
                    position: absolute;
                    pointer-events: none;
                    animation: moveRandomly 5s infinite ease-in-out alternate;
                }

                .sparkle {
                    background: radial-gradient(
                        circle, 
                        rgba(255, 255, 255, 0.9) 0%, 
                        rgba(255, 255, 255, 0) 70%
                    );
                    border-radius: 50%;
                    animation: sparkle 3s infinite alternate;
                }

                .star {
                    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
                    background: linear-gradient(45deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.3) 100%);
                    animation: star-twinkle 4s infinite alternate;
                }

                .diamond {
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    background: linear-gradient(135deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.2) 100%);
                    animation: diamond-pulse 3.5s infinite alternate;
                }

                .bubble {
                    border-radius: 50%;
                    background: radial-gradient(
                        circle, 
                        rgba(255, 255, 255, 0.3) 0%, 
                        rgba(255, 255, 255, 0.1) 70%
                    );
                    animation: bubble-float 4s infinite alternate;
                }

                .cosmic {
                    background: linear-gradient(
                        45deg, 
                        rgba(255,255,255,0.6) 0%, 
                        rgba(255,255,255,0.2) 100%
                    );
                    clip-path: polygon(50% 0%, 80% 25%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 20% 25%);
                    animation: cosmic-spin 5s infinite linear;
                }

                @keyframes moveRandomly {
                    0% { transform: translateX(0px) translateY(0px); }
                    100% { transform: translateX(${Math.random() * 50 - 25}px) translateY(${Math.random() * 50 - 25}px); }
                }

                @keyframes sparkle {
                    0% { transform: scale(0) rotate(0deg); opacity: 0; }
                    50% { transform: scale(1) rotate(180deg); opacity: 1; }
                    100% { transform: scale(0) rotate(360deg); opacity: 0; }
                }

                @keyframes star-twinkle {
                    0% { transform: scale(0.5) rotate(0deg); opacity: 0.5; }
                    50% { transform: scale(1) rotate(180deg); opacity: 1; }
                    100% { transform: scale(0.7) rotate(360deg); opacity: 0.7; }
                }

                @keyframes diamond-pulse {
                    0% { transform: scale(0.6) rotate(-45deg); opacity: 0.4; }
                    50% { transform: scale(1) rotate(45deg); opacity: 1; }
                    100% { transform: scale(0.8) rotate(-45deg); opacity: 0.6; }
                }

                @keyframes bubble-float {
                    0% { transform: translateY(0) scale(0.8); opacity: 0.5; }
                    50% { transform: translateY(-20px) scale(1); opacity: 1; }
                    100% { transform: translateY(0) scale(0.9); opacity: 0.7; }
                }

                @keyframes cosmic-spin {
                    0% { transform: rotate(0deg) scale(0.7); opacity: 0.6; }
                    50% { transform: rotate(180deg) scale(1); opacity: 1; }
                    100% { transform: rotate(360deg) scale(0.8); opacity: 0.7; }
                }
            `}</style>
            <div className="glitter-background">
                {renderBackgroundLayers()}
                {renderGlitterParticles()}
            </div>
        </>
    );
};

export const RefinedWarpSpeed = () => {
    const [lines, setLines] = useState([]);
    const containerRef = useRef(null);

    const colorPalette = [
        { color: 'rgba(0, 255, 255, 0.7)', glow: 'rgba(0, 255, 255, 0.3)' },   // cyan
        { color: 'rgba(255, 0, 255, 0.7)', glow: 'rgba(255, 0, 255, 0.3)' },   // magenta
        { color: 'rgba(100, 240, 100, 0.7)', glow: 'rgba(100, 240, 100, 0.3)' }, // green
        { color: 'rgba(255, 165, 0, 0.6)', glow: 'rgba(255, 165, 0, 0.2)' },   // orange
    ];

    useEffect(() => {
        const generateWarpLines = () => {
            const container = containerRef.current;
            const width = container.clientWidth;
            const height = container.clientHeight;
            const newLines = [];

            const lineCount = 80;
            const centerX = width / 2;
            const centerY = height / 2;

            for (let i = 0; i < lineCount; i++) {
                const spreadFactor = Math.log(i + 2) * 50;
                const angle = (i / lineCount) * Math.PI * 2;

                const line = {
                    id: i,
                    x: centerX + Math.cos(angle) * spreadFactor,
                    y: centerY + Math.sin(angle) * spreadFactor,
                    width: Math.random() * 200 + 30,
                    colorScheme: colorPalette[Math.floor(Math.random() * colorPalette.length)],
                    perspective: Math.random() * 2 + 1,
                    depth: Math.random(),
                    angleVariation: (Math.random() - 0.5) * 0.2
                };

                newLines.push(line);
            }
            setLines(newLines);
        };

        generateWarpLines();
        const interval = setInterval(generateWarpLines, 4000);
        return () => clearInterval(interval);
    }, []);

    const renderWarpLine = (line) => {
        return (
            <div
                key={line.id}
                className="warp-line"
                style={{
                    width: `${line.width}px`,
                    height: '2px',
                    backgroundColor: line.colorScheme.color,
                    top: `${line.y}px`,
                    left: `${line.x}px`,
                    transformOrigin: 'center',
                    boxShadow: `0 0 15px ${line.colorScheme.glow}`,
                }}
            />
        );
    };

    return (
        <>
            {/* Adding the styles directly within the component */}
            <style>
                {`
          /* General Container */
          .warp-container {
            position: absolute;
            width: 100%;
            height: 100vh;
            background: linear-gradient(135deg, #000022, #110022, #002222);
            overflow: hidden;
            perspective: 1000px;
          }

          /* Background Layers */
          .background-layers {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: 
              radial-gradient(circle at 30% 40%, rgba(0, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.9) 70%),
              radial-gradient(circle at 70% 60%, rgba(255, 0, 255, 0.05) 0%, transparent 50%);
            mix-blend-mode: screen;
            pointer-events: none;
          }

          /* Warp Speed Lines */
          .warp-line {
            position: absolute;
            filter: blur(1px);
            animation: moveLine 4s infinite ease-in-out;
          }

          /* Keyframes for Warp Speed Line Movement */
          @keyframes moveLine {
            0% {
              opacity: 0.7;
              transform: scale(1) rotate(0deg);
            }
            50% {
              opacity: 1;
              transform: scale(1.5) rotate(360deg);
            }
            100% {
              opacity: 0.7;
              transform: scale(0) rotate(720deg);
            }
          }

          /* Cosmic Focal Point */
          .cosmic-focal-point {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: white;
            transform: translate(-50%, -50%);
            box-shadow: 0 0 40px 15px rgba(255, 255, 255, 0.2);
            animation: pulse 3s infinite ease-in-out;
          }

          /* Keyframes for Cosmic Focal Point */
          @keyframes pulse {
            0% {
              transform: translate(-50%, -50%) scale(1);
              opacity: 0.5;
            }
            50% {
              transform: translate(-50%, -50%) scale(1.5);
              opacity: 0.8;
            }
            100% {
              transform: translate(-50%, -50%) scale(1);
              opacity: 0.5;
            }
          }
        `}
            </style>

            <div ref={containerRef} className="warp-container">
                <div className="background-layers" />

                {lines.map(renderWarpLine)}

                <div className="cosmic-focal-point" />
            </div>
        </>
    );
};

const EnergyParticle = ({ particle }) => {
    const particleRef = useRef(null);

    useEffect(() => {
        if (particleRef.current) {
            const { size, hue, opacity, x, y, direction } = particle;

            particleRef.current.style.position = "absolute";
            particleRef.current.style.width = `${size}px`;
            particleRef.current.style.height = `${size}px`;
            particleRef.current.style.borderRadius = "50%";
            particleRef.current.style.background = `radial-gradient(
        circle at 30% 30%, 
        hsla(${hue}, 100%, 70%, 0.8), 
        hsla(${hue}, 100%, 50%, 0)
      )`;
            particleRef.current.style.filter = `blur(${size / 10}px)`;
            particleRef.current.style.opacity = opacity;
            particleRef.current.style.transform = `translate(${x}vw, ${y}vh) rotate(${direction}rad)`;
        }
    }, [particle]);

    return <div ref={particleRef}></div>;
};

export const CosmicContainer = () => {
    const containerRef = useRef(null);
    const particles = useRef([]);

    const createParticle = () => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        speed: Math.random() * 0.5 + 0.1,
        direction: Math.random() * Math.PI * 2, // Fixed: Multiply Math.random() by Math.PI * 2
        hue: Math.random() * 360,
        size: Math.random() * 20 + 5,
        opacity: Math.random() * 0.7 + 0.3,
    });

    useEffect(() => {
        const particleCount = 200;

        // Initialize particles
        particles.current = Array.from({ length: particleCount }, createParticle);

        const animate = () => {
            particles.current = particles.current.map((particle) => {
                const x =
                    (particle.x + Math.cos(particle.direction) * particle.speed + 100) %
                    100;
                const y =
                    (particle.y + Math.sin(particle.direction) * particle.speed + 100) %
                    100;
                const direction = particle.direction + (Math.random() - 0.5) * 0.1;

                return { ...particle, x, y, direction };
            });

            containerRef.current?.style.setProperty("--animate", "running");
            requestAnimationFrame(animate);
        };

        animate();
    }, []);

    return (
        <div
            id="cosmic-container"
            ref={containerRef}
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                background:
                    "radial-gradient(ellipse at bottom, #1e2255 0%, #090a1a 100%)",
                overflow: "hidden",
            }}
        >
            {particles.current.map((particle, index) => (
                <EnergyParticle key={index} particle={particle} />
            ))}
        </div>
    );
};

const GeometricParticle = ({ particle }) => {
    const particleRef = useRef(null);

    useEffect(() => {
        if (particleRef.current) {
            const { x, y, rotation, size, shape, hue } = particle;

            particleRef.current.style.position = "absolute";
            particleRef.current.style.width =
                shape === "triangle" ? "0" : `${size}px`;
            particleRef.current.style.height =
                shape === "triangle" ? "0" : `${size}px`;

            switch (shape) {
                case "circle":
                    particleRef.current.style.borderRadius = "50%";
                    particleRef.current.style.backgroundColor = `hsla(${hue}, 70%, 50%, 0.7)`;
                    break;
                case "square":
                    particleRef.current.style.borderRadius = "0";
                    particleRef.current.style.backgroundColor = `hsla(${hue}, 70%, 50%, 0.7)`;
                    break;
                case "triangle":
                    particleRef.current.style.borderLeft = `${size / 2
                        }px solid transparent`;
                    particleRef.current.style.borderRight = `${size / 2
                        }px solid transparent`;
                    particleRef.current.style.borderBottom = `${size}px solid hsla(${hue}, 70%, 50%, 0.7)`;
                    particleRef.current.style.backgroundColor = "transparent";
                    break;
                default:
                    break;
            }

            particleRef.current.style.transform = `translate(${x}vw, ${y}vh) rotate(${rotation}deg) scale(1)`;
        }
    }, [particle]);

    return <div ref={particleRef}></div>;
};

export const ParticleContainer = () => {
    const containerRef = useRef(null);
    const particles = useRef([]);

    const createParticle = () => {
        const shape = ["circle", "square", "triangle"][
            Math.floor(Math.random() * 3)
        ];
        const size = Math.random() * 30 + 10;
        const hue = Math.random() * 360;

        return {
            x: Math.random() * 100,
            y: Math.random() * 100,
            speedX: (Math.random() - 0.5) * 0.5,
            speedY: (Math.random() - 0.5) * 0.5,
            rotation: Math.random() * 360,
            size,
            shape,
            hue,
        };
    };

    useEffect(() => {
        const particleCount = 300;

        // Initialize particles
        particles.current = Array.from({ length: particleCount }, createParticle);

        const animate = () => {
            particles.current = particles.current.map((particle) => {
                const x = (particle.x + particle.speedX + 100) % 100;
                const y =
                    (particle.y +
                        particle.speedY +
                        Math.sin(particle.x * 0.1) * 0.2 +
                        100) %
                    100; // Fixed issue here
                const rotation = particle.rotation + (Math.random() - 0.5) * 5;

                return { ...particle, x, y, rotation };
            });

            containerRef.current.style.transform = "";
            requestAnimationFrame(animate);
        };

        animate();
    }, []);

    return (
        <div
            id="particle-container"
            ref={containerRef}
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                backgroundColor: "#0a0a1a",
            }}
        >
            {particles.current.map((particle, index) => (
                <GeometricParticle key={index} particle={particle} />
            ))}
        </div>
    );
};

export const ProfessionalMotionBackground = ({
    variant = 'corporate',
    intensity = 'medium'
}) => {
    // Professional color palettes
    const colorSchemes = {
        corporate: {
            background: '#0a1128',
            colors: [
                '#1e3a8a',   // Deep Blue
                '#1e40af',   // Intense Blue
                '#2563eb',   // Bright Blue
                '#3b82f6',   // Sky Blue
                '#60a5fa'    // Light Blue
            ]
        },
        tech: {
            background: '#111827',
            colors: [
                '#4338ca',   // Deep Indigo
                '#4f46e5',   // Vibrant Indigo
                '#6366f1',   // Soft Indigo
                '#818cf8',   // Light Indigo
                '#a5b4fc'    // Pastel Indigo
            ]
        },
        minimal: {
            background: '#030712',
            colors: [
                '#374151',   // Dark Gray
                '#4b5563',   // Medium Gray
                '#6b7280',   // Light Gray
                '#9ca3af',   // Soft Gray
                '#d1d5db'    // Lightest Gray
            ]
        }
    };

    // Intensity-based particle configurations
    const intensityConfig = {
        low: { particleCount: 30, maxSize: 15, speed: 0.5 },
        medium: { particleCount: 50, maxSize: 25, speed: 1 },
        high: { particleCount: 75, maxSize: 35, speed: 1.5 }
    };

    // Current theme and intensity
    const currentScheme = colorSchemes[variant] || colorSchemes.corporate;
    const currentIntensity = intensityConfig[intensity] || intensityConfig.medium;

    // Generate professional motion particles
    const generateParticles = useMemo(() => {
        return Array.from({ length: currentIntensity.particleCount }, (_, index) => {
            const isSpecialParticle = index < currentIntensity.particleCount / 10;

            return {
                id: index,
                size: isSpecialParticle
                    ? Math.random() * currentIntensity.maxSize + 20
                    : Math.random() * 10 + 5,
                color: currentScheme.colors[Math.floor(Math.random() * currentScheme.colors.length)],
                left: Math.random() * 100,
                top: Math.random() * 100,
                delay: Math.random() * 5,
                duration: isSpecialParticle
                    ? Math.random() * 15 + 15
                    : Math.random() * 10 + 10,
                translateX: (Math.random() - 0.5) * 200 * currentIntensity.speed,
                translateY: (Math.random() - 0.5) * 200 * currentIntensity.speed,
                opacity: isSpecialParticle ? 0.7 : 0.4
            };
        });
    }, [variant, intensity]);

    return (
        <div
            className="professional-motion-background"
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: currentScheme.background,
                overflow: 'hidden',
                zIndex: -1,
                perspective: '1000px'
            }}
        >
            {generateParticles.map((particle) => (
                <div
                    key={particle.id}
                    style={{
                        position: 'absolute',
                        width: `${particle.size}px`,
                        height: `${particle.size}px`,
                        borderRadius: '50%',
                        backgroundColor: particle.color,
                        left: `${particle.left}%`,
                        top: `${particle.top}%`,
                        boxShadow: `0 0 15px 3px ${particle.color}80`,
                        animation: `
              professionalMotion${particle.id} ${particle.duration}s cubic-bezier(0.42, 0, 0.58, 1) infinite,
              professionalRotate${particle.id} ${particle.duration * 2}s linear infinite
            `,
                        animationDelay: `${particle.delay}s`
                    }}
                />
            ))}
            <style>{`
        ${generateParticles.map((particle) => `
          @keyframes professionalMotion${particle.id} {
            0% { 
              transform: 
                translate(0, 0) 
                scale(1);
              opacity: ${particle.opacity};
            }
            50% {
              transform: 
                translate(${particle.translateX}%, ${particle.translateY}%) 
                scale(1.3);
              opacity: ${particle.opacity * 0.6};
            }
            100% { 
              transform: 
                translate(${particle.translateX * 1.5}%, ${particle.translateY * 1.5}%) 
                scale(1.6);
              opacity: 0;
            }
          }
          
          @keyframes professionalRotate${particle.id} {
            0% { 
              transform: rotate(0deg);
            }
            100% { 
              transform: rotate(${Math.random() * 360}deg);
            }
          }
        `).join('')}
      `}</style>
        </div>
    );
};

export const AdvancedMotionGraphicsBackgroundspam = ({
    particleCount = 100,
    width = '100%',
    height = '100vh',
    backgroundColor = '#0a0a1a',
    colorPalette = [
        'rgba(59, 130, 246, 0.7)',   // Blue
        'rgba(16, 185, 129, 0.7)',   // Green
        'rgba(99, 102, 241, 0.7)',   // Indigo
        'rgba(244, 63, 94, 0.7)',    // Rose
        'rgba(139, 92, 246, 0.7)'    // Purple
    ]
}) => {
    const [particles, setParticles] = useState([]);

    useEffect(() => {
        const generateParticles = () => {
            return Array.from({ length: particleCount }, (_, index) => ({
                id: `particle-${index}`,
                color: colorPalette[Math.floor(Math.random() * colorPalette.length)],
                size: Math.random() * 10 + 2,
                x: Math.random() * 100,
                y: Math.random() * 100,
                delay: Math.random() * 15,   // Shorter delay for more randomness
                duration: Math.random() * 20 + 10,  // Slower duration for movement
                rotationDuration: Math.random() * 40 + 20 // Slower rotation
            }));
        };

        setParticles(generateParticles());
    }, [particleCount, colorPalette]);

    return (
        <div
            className="motion-graphics-background"
            style={{
                backgroundColor,
                width,
                height
            }}
        >
            <style jsx>{`
                .motion-graphics-background {
                    position: absolute;
                    top: 0;
                    left: 0;
                    overflow: hidden;
                    pointer-events: none;
                }

                .particle {
                    position: absolute;
                    border-radius: 50%;
                    filter: blur(0px);
                    mix-blend-mode: screen;
                    opacity: 0.7;
                    animation: 
                        particleMove linear infinite,
                        particlePulse alternate infinite;
                }

                @keyframes particleMove {
                    0% {
                        transform: 
                            translate(-50%, -50%) 
                            rotate(0deg);
                    }
                    100% {
                        transform: 
                            translate(100%, 100%)  // Slow down the movement to 100%
                            rotate(720deg);  // More gradual rotation
                    }
                }

                @keyframes particlePulse {
                    0% {
                        transform: scale(0.8);
                        opacity: 0.5;
                    }
                    100% {
                        transform: scale(1.2);
                        opacity: 0.9;
                    }
                }
            `}</style>

            {particles.map(particle => (
                <div
                    key={particle.id}
                    className="particle"
                    style={{
                        backgroundColor: particle.color,
                        width: `${particle.size}px`,
                        height: `${particle.size}px`,
                        left: `${particle.x}%`,
                        top: `${particle.y}%`,
                        animationDelay: `${particle.delay}s`,
                        animationDuration: `${particle.duration}s`,  // Slower movement
                    }}
                />
            ))}
        </div>
    );
};
export const DynamicBackground = () => {
    const [particles, setParticles] = useState([]);
    const [stars, setStars] = useState([]);
    const [bubbles, setBubbles] = useState([]);
    const [mist, setMist] = useState([]);
    const [lightSpots, setLightSpots] = useState([]);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [ripples, setRipples] = useState([]);

    // Generate elements on component mount
    useEffect(() => {
        setParticles(generateElements(50, 'particle', 2, 7));
        setStars(generateElements(100, 'star', 1, 4));
        setBubbles(generateElements(20, 'bubble', 10, 60));
        setMist(generateElements(10, 'mist', 100, 300));
        setLightSpots(generateElements(5, 'light-spot', 100, 400));
    }, []);

    // Generate random elements
    const generateElements = (count, className, minSize, maxSize) => {
        return Array.from({ length: count }, (_, i) => ({
            id: `${className}-${i}`,
            width: Math.random() * (maxSize - minSize) + minSize,
            left: Math.random() * 100,
            top: Math.random() * 100,
            delay: Math.random() * 20
        }));
    };

    // Mouse move handler
    const handleMouseMove = (e) => {
        const newX = e.clientX;
        const newY = e.clientY;

        setCursorPosition({ x: newX, y: newY });

        // Add new ripple
        const newRipple = {
            id: `ripple-${Date.now()}`,
            x: newX,
            y: newY
        };

        setRipples(prev => [...prev, newRipple]);

        // Remove old ripples after 4 seconds
        setTimeout(() => {
            setRipples(prev => prev.slice(1));
        }, 4000);
    };

    return (
        <div
            className="background-container"
            onMouseMove={handleMouseMove}
        >
            <style jsx>{`
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        .background-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(135deg, #0f2027, #203a43, #2c5364);
          background-size: 400% 400%;
          animation: gradientShift 15s ease infinite;
          overflow: hidden;
          cursor: none;
        }

        .particle-layer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }

        .particle {
          position: absolute;
          background: rgba(255,255,255,0.7);
          border-radius: 50%;
          opacity: 0.6;
          animation: 
            particleFloat 10s linear infinite,
            particleGlow 3s alternate infinite;
        }

        .star {
          position: absolute;
          background: white;
          border-radius: 50%;
          animation: 
            starTwinkle 2s alternate infinite,
            starMove 20s linear infinite;
        }

        .bubble {
          position: absolute;
          background: rgba(255,255,255,0.1);
          border-radius: 50%;
          border: 1px solid rgba(255,255,255,0.2);
          animation: 
            bubbleRise 15s linear infinite,
            bubblePulse 3s alternate infinite;
        }

        .mist {
          position: absolute;
          background: rgba(255,255,255,0.05);
          border-radius: 50%;
          filter: blur(50px);
          animation: 
            mistFloat 20s linear infinite,
            mistPulse 10s alternate infinite;
        }

        .light-spot {
          position: absolute;
          background: radial-gradient(circle, 
            rgba(255,255,255,0.3) 0%, 
            rgba(255,255,255,0) 70%);
          border-radius: 50%;
          opacity: 0.5;
          animation: lightSpotMove 15s linear infinite;
        }

        .ripple-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          pointer-events: none;
        }

        .ripple {
          position: absolute;
          border: 2px solid rgba(255,255,255,0.2);
          border-radius: 50%;
          transform: scale(0);
          animation: rippleExpand 4s linear infinite;
        }

        .cursor-follower {
          position: fixed;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: rgba(255,255,255,0.2);
          pointer-events: none;
          transform: translate(-50%, -50%);
          transition: transform 0.1s ease-out;
          box-shadow: 0 0 20px rgba(255,255,255,0.3);
        }

        @keyframes gradientShift {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }

        @keyframes particleFloat {
          0% { 
            transform: translateY(0) rotate(0deg);
          }
          100% { 
            transform: translateY(100vh) rotate(360deg);
          }
        }

        @keyframes particleGlow {
          0% { opacity: 0.4; }
          100% { opacity: 0.8; }
        }

        @keyframes starTwinkle {
          0% { opacity: 0.3; }
          100% { opacity: 1; }
        }

        @keyframes starMove {
          0% { transform: translateX(-100vw) translateY(-100vh); }
          100% { transform: translateX(100vw) translateY(100vh); }
        }

        @keyframes bubbleRise {
          0% { 
            transform: translateY(100vh) scale(0.5);
            opacity: 0.3;
          }
          100% { 
            transform: translateY(-10vh) scale(1.2);
            opacity: 0;
          }
        }

        @keyframes bubblePulse {
          0% { transform: scale(0.8); }
          100% { transform: scale(1.2); }
        }

        @keyframes mistFloat {
          0% { transform: translateX(-100vw); }
          100% { transform: translateX(100vw); }
        }

        @keyframes mistPulse {
          0% { opacity: 0.1; }
          100% { opacity: 0.3; }
        }

        @keyframes lightSpotMove {
          0% { transform: translate(-100vw, -100vh); }
          100% { transform: translate(100vw, 100vh); }
        }

        @keyframes rippleExpand {
          0% { 
            transform: scale(0);
            opacity: 0.7;
          }
          100% { 
            transform: scale(5);
            opacity: 0;
          }
        }
      `}</style>

            {/* Particle Layer */}
            <div className="particle-layer">
                {particles.map(particle => (
                    <div
                        key={particle.id}
                        className="particle"
                        style={{
                            width: `${particle.width}px`,
                            height: `${particle.width}px`,
                            left: `${particle.left}%`,
                            top: `${particle.top}%`,
                            animationDelay: `${particle.delay}s`
                        }}
                    />
                ))}

                {stars.map(star => (
                    <div
                        key={star.id}
                        className="star"
                        style={{
                            width: `${star.width}px`,
                            height: `${star.width}px`,
                            left: `${star.left}%`,
                            top: `${star.top}%`,
                            animationDelay: `${star.delay}s`
                        }}
                    />
                ))}

                {bubbles.map(bubble => (
                    <div
                        key={bubble.id}
                        className="bubble"
                        style={{
                            width: `${bubble.width}px`,
                            height: `${bubble.width}px`,
                            left: `${bubble.left}%`,
                            top: `${bubble.top}%`,
                            animationDelay: `${bubble.delay}s`
                        }}
                    />
                ))}

                {mist.map(m => (
                    <div
                        key={m.id}
                        className="mist"
                        style={{
                            width: `${m.width}px`,
                            height: `${m.width}px`,
                            left: `${m.left}%`,
                            top: `${m.top}%`,
                            animationDelay: `${m.delay}s`
                        }}
                    />
                ))}

                {lightSpots.map(spot => (
                    <div
                        key={spot.id}
                        className="light-spot"
                        style={{
                            width: `${spot.width}px`,
                            height: `${spot.width}px`,
                            left: `${spot.left}%`,
                            top: `${spot.top}%`,
                            animationDelay: `${spot.delay}s`
                        }}
                    />
                ))}
            </div>

            {/* Ripple Container */}
            <div className="ripple-container">
                {ripples.map(ripple => (
                    <div
                        key={ripple.id}
                        className="ripple"
                        style={{
                            left: `${ripple.x}px`,
                            top: `${ripple.y}px`,
                            width: '10px',
                            height: '10px'
                        }}
                    />
                ))}
            </div>

            {/* Cursor Follower */}
            <div
                className="cursor-follower"
                style={{
                    left: `${cursorPosition.x}px`,
                    top: `${cursorPosition.y}px`
                }}
            />
        </div>
    );
};



const EasterBackground = () => {
    const eggs = Array.from({ length: 30 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 80 + 80,
    }));

    return (
        <div className="earth-day-background">
            {eggs.map((egg, index) => (
                <motion.div
                    key={index}
                    initial={{ scale: 0 }}
                    animate={{
                        scale: [0, 1, 0],
                        opacity: [0, 1, 0],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        delay: index * 0.2,
                    }}
                    style={{
                        position: "absolute",
                        width: `${egg.size}px`,
                        height: `${egg.size}px`,
                        top: `${egg.y}%`,
                        left: `${egg.x}%`,
                        borderRadius: "50%",
                        background: "linear-gradient(135deg, pink, yellow, lightblue)",
                    }}
                />
            ))}
        </div>
    );
};

const EarthDayBackground = () => {
    const leaves = Array.from({ length: 30 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 40 + 20,
        color: `hsl(${Math.random() * 120}, 50%, 40%)`,
    }));

    return (
        <div className="earth-day-background">
            {leaves.map((leaf, index) => (
                <motion.div
                    key={index}
                    initial={{ scale: 0, rotate: 0 }}
                    animate={{
                        scale: [0, 1, 0],
                        opacity: [0, 1, 0],
                        rotate: [0, 360],
                    }}
                    transition={{
                        duration: 2.5,
                        repeat: Infinity,
                        delay: index * 0.2,
                    }}
                    style={{
                        position: "absolute",
                        width: `${leaf.size}px`,
                        height: `${leaf.size}px`,
                        top: `${leaf.y}%`,
                        left: `${leaf.x}%`,
                        backgroundColor: leaf.color,
                        clipPath:
                            "polygon(50% 0%, 61% 35%, 100% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 0 35%, 39% 35%)",
                    }}
                />
            ))}
        </div>
    );
};

const BirthdayBackground = () => {
    const confetti = Array.from({ length: 100 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        color: `hsl(${Math.random() * 360}, 100%, 50%)`,
        size: Math.random() * 15 + 5,
        delay: Math.random() * 2,
    }));

    return (
        <div className="birthday-background">
            {confetti.map((piece, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, y: -100, rotate: 0 }}
                    animate={{
                        opacity: [0, 1, 0],
                        y: [0, 600],
                        x: [0, Math.random() * 200 - 100],
                        rotate: [0, 360],
                    }}
                    transition={{
                        duration: 3 + piece.delay,
                        repeat: Infinity,
                        delay: piece.delay,
                        ease: "easeInOut",
                    }}
                    style={{
                        position: "absolute",
                        width: `${piece.size}px`,
                        height: `${piece.size}px`,
                        backgroundColor: piece.color,
                        top: `${piece.y}%`,
                        left: `${piece.x}%`,
                        borderRadius: "50%",
                    }}
                />
            ))}
            <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: [0, 1.5, 1], opacity: [0, 1, 0] }}
                transition={{ duration: 1 }}
                className="birthday-message"
            >
                Happy Birthday!
            </motion.div>
        </div>
    );
};

// Diwali Background with Enhanced Motion
const DiwaliBackground = () => {
    const lamps = Array.from({ length: 20 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        color: `hsl(${30 + Math.random() * 30}, 100%, 50%)`,
        size: Math.random() * 50 + 30,
    }));

    return (
        <div className="diwali-background">
            {lamps.map((lamp, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{
                        opacity: [0, 1, 0],
                        scale: [0, 1.2, 0],
                        boxShadow: [
                            `0 0 10px ${lamp.color}`,
                            `0 0 30px ${lamp.color}`,
                            `0 0 10px ${lamp.color}`,
                        ],
                        rotate: [0, 360],
                    }}
                    transition={{
                        duration: 3,
                        repeat: Infinity,
                        delay: index * 0.2,
                        ease: "easeInOut",
                    }}
                    style={{
                        position: "absolute",
                        width: `${lamp.size}px`,
                        height: `${lamp.size}px`,
                        backgroundColor: lamp.color,
                        top: `${lamp.y}%`,
                        left: `${lamp.x}%`,
                        borderRadius: "50%",
                        filter: "blur(10px)",
                    }}
                />
            ))}
        </div>
    );
};

// New Year Countdown Background with Enhanced Motion
const NewYearBackground = () => {
    const fireworks = Array.from({ length: 15 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        color: `hsl(${Math.random() * 360}, 100%, 50%)`,
        size: Math.random() * 100 + 50,
    }));

    return (
        <div className="newyear-background">
            {fireworks.map((firework, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, scale: 0, rotate: 0 }}
                    animate={{
                        opacity: [0, 1, 0],
                        scale: [0, 2, 0],
                        rotate: [0, 360, 0],
                    }}
                    transition={{
                        duration: 3,
                        repeat: Infinity,
                        delay: index * 0.3,
                    }}
                    style={{
                        position: "absolute",
                        width: `${firework.size}px`,
                        height: `${firework.size}px`,
                        background: `radial-gradient(circle, ${firework.color} 0%, transparent 70%)`,
                        top: `${firework.y}%`,
                        left: `${firework.x}%`,
                        borderRadius: "50%",
                    }}
                />
            ))}
            <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                className="newyear-message"
            >
                Happy New Year!
            </motion.div>
        </div>
    );
};

// Holi Festival Background with Enhanced Motion
const HoliFestivalBackground = () => {
    const colors = Array.from({ length: 100 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        color: `hsl(${Math.random() * 360}, 100%, 50%)`,
        size: Math.random() * 20 + 10,
    }));

    return (
        <div className="holi-background">
            {colors.map((color, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, scale: 0, rotate: 0 }}
                    animate={{
                        opacity: [0, 1, 0],
                        scale: [0, 1.5, 0],
                        x: [0, Math.random() * 200 - 100],
                        y: [0, Math.random() * 200 - 100],
                        rotate: [0, 360],
                    }}
                    transition={{
                        duration: 3,
                        repeat: Infinity,
                        delay: index * 0.1,
                    }}
                    style={{
                        position: "absolute",
                        width: `${color.size}px`,
                        height: `${color.size}px`,
                        backgroundColor: color.color,
                        top: `${color.y}%`,
                        left: `${color.x}%`,
                        borderRadius: "50%",
                    }}
                />
            ))}
        </div>
    );
};

// Wedding Anniversary Background with Enhanced Motion
const WeddingAnniversaryBackground = () => {
    const hearts = Array.from({ length: 50 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 30 + 20,
    }));

    return (
        <div className="wedding-anniversary-background">
            {hearts.map((heart, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, scale: 0, rotate: 0 }}
                    animate={{
                        opacity: [0, 1, 0],
                        scale: [0, 1.2, 0],
                        rotate: [0, 360, 0],
                    }}
                    transition={{
                        duration: 3,
                        repeat: Infinity,
                        delay: index * 0.2,
                    }}
                    style={{
                        position: "absolute",
                        width: `${heart.size}px`,
                        height: `${heart.size}px`,
                        top: `${heart.y}%`,
                        left: `${heart.x}%`,
                        clipPath:
                            'path("M0 10 C0 3, 7 0, 10 0 S20 3, 20 10 S13 25, 10 30 C7 25, 0 17, 0 10")',
                        backgroundColor: "rgba(255,0,0,0.7)",
                    }}
                />
            ))}
        </div>
    );
};

// Halloween Background with Enhanced Motion
const HalloweenBackground = () => {
    const pumpkins = Array.from({ length: 30 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 40 + 20,
        delay: Math.random(),
    }));

    return (
        <div className="halloween-background">
            {pumpkins.map((pumpkin, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, y: -50, scale: 0 }}
                    animate={{
                        opacity: [0, 1, 0],
                        y: [0, 100, 0],
                        scale: [0, 1.5, 0],
                    }}
                    transition={{
                        duration: 2 + pumpkin.delay,
                        repeat: Infinity,
                    }}
                    style={{
                        position: "absolute",
                        width: `${pumpkin.size}px`,
                        height: `${pumpkin.size}px`,
                        backgroundColor: "orange",
                        top: `${pumpkin.y}%`,
                        left: `${pumpkin.x}%`,
                        borderRadius: "50%",
                        boxShadow: "0 0 10px orange",
                    }}
                />
            ))}
        </div>
    );
};

// Christmas Background with Enhanced Motion
const ChristmasBackground = () => {
    const snowflakes = Array.from({ length: 50 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 10 + 5,
        delay: Math.random() * 2,
    }));

    return (
        <div className="christmas-background">
            {snowflakes.map((flake, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, y: -100 }}
                    animate={{
                        opacity: [0, 1, 0],
                        y: [0, 100, 0],
                    }}
                    transition={{
                        duration: 3 + flake.delay,
                        repeat: Infinity,
                        delay: flake.delay,
                        ease: "easeInOut",
                    }}
                    style={{
                        position: "absolute",
                        width: `${flake.size}px`,
                        height: `${flake.size}px`,
                        backgroundColor: "white",
                        top: `${flake.y}%`,
                        left: `${flake.x}%`,
                        borderRadius: "50%",
                        filter: "blur(2px)",
                    }}
                />
            ))}
        </div>
    );
};

// Independence Day Background
const IndependenceDayBackground = () => {
    const fireworks = Array.from({ length: 20 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 50 + 20,
    }));

    return (
        <div className="independence-day-background">
            {fireworks.map((firework, index) => (
                <motion.div
                    key={index}
                    initial={{ scale: 0 }}
                    animate={{
                        scale: [0, 1.5, 0],
                        opacity: [0, 1, 0],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        delay: index * 0.2,
                    }}
                    style={{
                        position: "absolute",
                        width: `${firework.size}px`,
                        height: `${firework.size}px`,
                        top: `${firework.y}%`,
                        left: `${firework.x}%`,
                        background: `radial-gradient(circle, red, white, blue)`,
                        borderRadius: "50%",
                    }}
                />
            ))}
        </div>
    );
};

// Valentine’s Day Background
const ValentinesDayBackground = () => {
    const hearts = Array.from({ length: 50 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 30 + 20,
        delay: Math.random(),
    }));

    return (
        <div className="valentines-day-background">
            {hearts.map((heart, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, y: -50 }}
                    animate={{
                        opacity: [0, 1, 0],
                        y: [0, 100, 0],
                        rotate: [0, 360, 0],
                    }}
                    transition={{
                        duration: 3,
                        repeat: Infinity,
                        delay: heart.delay,
                    }}
                    style={{
                        position: "absolute",
                        width: `${heart.size}px`,
                        height: `${heart.size}px`,
                        top: `${heart.y}%`,
                        left: `${heart.x}%`,
                        clipPath:
                            "polygon(50% 0%, 61% 35%, 100% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 0 35%, 39% 35%)",
                        backgroundColor: "pink",
                        boxShadow: "0 0 5px rgba(255, 105, 180, 0.7)",
                    }}
                />
            ))}
        </div>
    );
};
// Mother's Day Background
const MothersDayBackground = () => {
    const flowers = Array.from({ length: 40 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 50 + 20,
        delay: Math.random() * 2,
    }));

    return (
        <div className="mothers-day-background">
            {flowers.map((flower, index) => (
                <motion.div
                    key={index}
                    initial={{ scale: 0 }}
                    animate={{
                        scale: [0, 1, 0.5],
                        rotate: [0, 360],
                        opacity: [0, 1, 0],
                    }}
                    transition={{
                        duration: 3,
                        repeat: Infinity,
                        delay: flower.delay,
                    }}
                    style={{
                        position: "absolute",
                        width: `${flower.size}px`,
                        height: `${flower.size}px`,
                        top: `${flower.y}%`,
                        left: `${flower.x}%`,
                        background: `radial-gradient(circle, pink, white, violet)`,
                        borderRadius: "50%",
                    }}
                />
            ))}
        </div>
    );
};

// Father's Day Background
const FathersDayBackground = () => {
    const explosionCrackers = Array.from({ length: 20 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 40 + 30,
        delay: Math.random(),
    }));

    return (
        <div className="fathers-day-background">
            {explosionCrackers.map((cracker, index) => (
                <motion.div
                    key={index}
                    initial={{ scale: 0 }}
                    animate={{
                        scale: [0, 1.5, 0],
                        opacity: [0, 1, 0],
                        rotate: [0, 180, 360],
                    }}
                    transition={{
                        duration: 2.5,
                        repeat: Infinity,
                        delay: cracker.delay,
                    }}
                    style={{
                        position: "absolute",
                        width: `${cracker.size}px`,
                        height: `${cracker.size}px`,
                        top: `${cracker.y}%`,
                        left: `${cracker.x}%`,
                        background: `radial-gradient(circle, orange, yellow, transparent)`,
                        borderRadius: "50%",
                        boxShadow: "0 0 10px rgba(255, 165, 0, 0.7)",
                    }}
                />
            ))}
        </div>
    );
};
const ThanksgivingBackground = () => {
    const stars = Array.from({ length: 30 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        delay: Math.random() * 2,
    }));

    return (
        <div className="thanksgiving-background">
            <motion.div
                className="thanksgiving-bg-color"
                animate={{
                    background: [
                        "linear-gradient(to right, #ff7e5f, #feb47b)",
                        "linear-gradient(to right, #ffb347, #ffcc33)",
                        "linear-gradient(to right, #e96443, #904e95)",
                        "linear-gradient(to right, #ff7e5f, #feb47b)",
                    ],
                }}
                transition={{
                    duration: 10,
                    repeat: Infinity,
                }}
            />
            {stars.map((star, index) => (
                <motion.div
                    key={index}
                    initial={{ scale: 0 }}
                    animate={{
                        scale: [0, 1, 0],
                        opacity: [0, 1, 0],
                        backgroundColor: [
                            "#FFD700",
                            "#FF4500",
                            "#ADFF2F",
                            "#1E90FF",
                        ],
                    }}
                    transition={{
                        duration: 3,
                        repeat: Infinity,
                        delay: star.delay,
                    }}
                    style={{
                        position: "absolute",
                        width: "30px",
                        height: "30px",
                        top: `${star.y}%`,
                        left: `${star.x}%`,
                        clipPath:
                            "polygon(50% 0%, 61% 35%, 100% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 0 35%, 39% 35%)",
                    }}
                />
            ))}
        </div>
    );
};

const GraduationDayBackground = () => {
    const stars = Array.from({ length: 30 }, (_, i) => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 4 + 2,
        delay: Math.random() * 3,
        duration: Math.random() * 2 + 1,
    }));

    return (
        <div className="graduation-day-background">
            {stars.map((star, index) => (
                <motion.div
                    key={index}
                    className="shooting-star"
                    initial={{ opacity: 0, x: `${star.x}%`, y: `${star.y}%` }}
                    animate={{
                        opacity: [0, 1, 0],
                        x: `${star.x + 10}%`,
                        y: `${star.y + 10}%`,
                    }}
                    transition={{
                        duration: star.duration,
                        repeat: Infinity,
                        delay: star.delay,
                    }}
                    style={{
                        width: `${star.size}px`,
                        height: `${star.size}px`,
                    }}
                />
            ))}
        </div>
    );
};
export {
    IndependenceDayBackground, EarthDayBackground, EasterBackground, FathersDayBackground, MothersDayBackground, ThanksgivingBackground, GraduationDayBackground,
    ValentinesDayBackground, BirthdayBackground, DiwaliBackground, NewYearBackground, HoliFestivalBackground, WeddingAnniversaryBackground, HalloweenBackground, ChristmasBackground
};

