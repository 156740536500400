
import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaChevronRight, FaLongArrowAltLeft } from "react-icons/fa";
import { useBuilderContext } from "../../../builderContext";
import { uploadHeaderImage } from "../../../BackendApi/Api/flowpageApi";
import { API_URL } from "../../../BackendApi/Api/config";
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { MdTitle } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
export function HeaderList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdTitle />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Heading</h2>
                            <p className="add-block-btn-text-desc">Add a heading to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}
export function HeaderAdd() {
    const { addComponent, myComponents, editComponent } = useBuilderContext();

    const initialHeaderData = myComponents[0]?.properties || {};
    const { id } = useParams();
    const location = useLocation();

    const unique_id = new URLSearchParams(location.search).get("unique_id");
    const position = new URLSearchParams(location.search).get("position");
    const uniqueId = unique_id || uuidv4();
    const [activeFormat, setActiveFormat] = useState(initialHeaderData.headerFormat || "H1");
    const [status, setStatus] = useState(myComponents[0]?.showStatus || "");
    const fileInputRef = useRef(null);
    const profileImageRef = useRef(null);

    const [previewBackgroundImage, setPreviewBackgroundImage] = useState(null);
    const [previewProfileImage, setPreviewProfileImage] = useState(null);

    const [ProfileImage, setProfileImage] = useState(null);
    const [BackgroundImage, setBackgroundImage] = useState(null);

    const [headerProperties, setHeaderProperties] = useState({
        title: initialHeaderData.title || "",
        description: initialHeaderData.description || "",
        headerFormat: initialHeaderData.headerFormat || "",
        profileImage: initialHeaderData.profileImage || "",
        altText: initialHeaderData.altText || "",
        backgroundImage: initialHeaderData.backgroundImage || "",
    });

    useEffect(() => {
        // Set initial preview images from the existing data
        if (initialHeaderData.profileImage) {
            setPreviewProfileImage(`${API_URL}flowpage/${initialHeaderData.profileImage}`);
        }
        if (initialHeaderData.backgroundImage) {
            setPreviewBackgroundImage(`${API_URL}flowpage/${initialHeaderData.backgroundImage}`);
        }

        // Update header properties when myComponents[0] changes
        setHeaderProperties({
            title: initialHeaderData.title || "",
            description: initialHeaderData.description || "",
            headerFormat: initialHeaderData.headerFormat || "H1",
            profileImage: initialHeaderData.profileImage || "",
            altText: initialHeaderData.altText || "",
            backgroundImage: initialHeaderData.backgroundImage || "",
        });

    }, [myComponents]);

    const handleInputChange = (type, file) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (type === "profile") {
                    setPreviewProfileImage(reader.result);
                }
                if (type === "background") {
                    setPreviewBackgroundImage(reader.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const addHeaderImageData = async () => {
        const data = new FormData();
        data.append('profileImage', ProfileImage);
        data.append('backgroundImage', BackgroundImage);

        uploadHeaderImage(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    const updatedHeaderProps = {
                        ...headerProperties,
                        profileImage: res.data.data.profileImage || headerProperties.profileImage,
                        backgroundImage: res.data.data.backgroundImage || headerProperties.backgroundImage,
                    };

                    editComponent(0, "HEADER", updatedHeaderProps, status);
                }
            } else {
                PNotify.error({
                    title: 'Error',
                    text: res.data.message,
                });
            }
        }).catch((err) => {
            PNotify.error({
                title: 'Error',
                text: err.message,
            });
        });
    };

    const handleFormatChange = (field, value) => {
        setHeaderProperties(prev => ({ ...prev, [field]: value }));
        editComponent(0, "HEADER", { ...headerProperties, [field]: value }, status);
    };

    const handleTextChange = (field, value) => {
        setHeaderProperties(prev => ({ ...prev, [field]: value }));
        editComponent(0, "HEADER", { ...headerProperties, [field]: value }, status);
    };

    const goBacks = `/editEvent/${id}/${unique_id ? "layout" : "addBlock"}`;

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to={goBacks}>
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Edit Header</label>
                    </div>
                </div>

                {/* Profile Image Upload */}
                <div className="icons_list_item cvtPmc">
                    <div className="position-relative">
                        <div className="image-input-container mb-4">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    handleInputChange("profile", e.target.files[0]);
                                    setProfileImage(e.target.files[0]);
                                }}
                                ref={profileImageRef}
                                style={{ display: 'none' }}
                            />
                            <div className="preview_image_box">
                                {previewProfileImage ? (
                                    <img src={previewProfileImage} alt="Selected" className="preview-image" />
                                ) : (
                                    <img
                                        src={require('../../../assets/images/image_default.png')}
                                        alt="Default"
                                        className="default-image"
                                    />
                                )}
                            </div>
                            <div className="image-input" onClick={() => profileImageRef.current.click()}>
                                <span className="icon">📷</span>
                                <span className="text">Upload Image (PNG, JPG or GIF)</span>
                            </div>
                        </div>
                        <div className="did-floating-label-content form-group">
                            <input type="text" className="did-floating-input form-control" value={headerProperties.altText}
                                onChange={(e) => handleTextChange("altText", e.target.value)} />
                            <label className="did-floating-label">Alt Text</label>
                        </div>
                    </div>
                </div>

                {/* Title and Description */}
                <div className="form_box cvtPmc">
                    <div className="did-floating-label-content form-group">
                        <textarea className="form-control did-floating-textarea" value={headerProperties.title}
                            rows="3" placeholder="Your business or personal name"
                            onChange={(e) => handleTextChange("title", e.target.value)}></textarea>
                    </div>
                </div>

                <div className="form_box cvtPmc">
                    <div className="did-floating-label-content form-group">
                        <textarea className="form-control did-floating-textarea" value={headerProperties.description}
                            rows="3" placeholder="A few words about you or your business"
                            onChange={(e) => handleTextChange("description", e.target.value)}></textarea>
                    </div>
                </div>

                {/* Header Format Selection */}
                <p aria-label="Header format" className="style__Paragraph-bufferapp-ui__sc-153wil8-1 fcYUrQ styles__Label-sc-m6lsw-0 cJUVbA">Header Format</p>

                <div className="header-format-box cvtPmc">
                    <p className="header-format-title">Without banner</p>
                    <div className="header-format-item">
                        <button type="button" className={`btn format-image-btn ${activeFormat === "H1" ? "active" : ""}`}
                            onClick={() => {
                                setActiveFormat("H1");
                                handleFormatChange("headerFormat", "H1");
                            }}>
                            <img src={require('../../../assets/images/header-layout-no-image-horizontal.png')} alt="Header Format 1" />
                        </button>
                        <button type="button" className={`btn format-image-btn ${activeFormat === "H2" ? "active" : ""}`}
                            onClick={() => {
                                setActiveFormat("H2");
                                handleFormatChange("headerFormat", "H2");
                            }}>
                            <img src={require('../../../assets/images/header-layout-no-image-vertical.png')} alt="Header Format 2" />
                        </button>
                    </div>

                    <p className="header-format-title">With banner</p>
                    <div className="header-format-item">
                        <button type="button" className={`btn format-image-btn ${activeFormat === "H3" ? "active" : ""}`}
                            onClick={() => {
                                setActiveFormat("H3");
                                handleFormatChange("headerFormat", "H3");
                            }}>
                            <img src={require('../../../assets/images/header-layout-image-full-vertical.png')} alt="Header Format 3" />
                        </button>
                        <button type="button" className={`btn format-image-btn ${activeFormat === "H4" ? "active" : ""}`}
                            onClick={() => {
                                setActiveFormat("H4");
                                handleFormatChange("headerFormat", "H4");
                            }}>
                            <img src={require('../../../assets/images/header-layout-image-half-vertical.png')} alt="Header Format 4" />
                        </button>
                    </div>
                </div>

                {/* Background Image Upload */}
                <div className="icons_list_item cvtPmc">
                    <div className="position-relative">
                        <div className="image-input-container mb-4">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    handleInputChange("background", e.target.files[0]);
                                    setBackgroundImage(e.target.files[0]);
                                }}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />
                            <div className="preview_image_box">
                                {previewBackgroundImage ? (
                                    <img src={previewBackgroundImage} alt="Selected" className="preview-image" />
                                ) : (
                                    <img
                                        src={require('../../../assets/images/image_default.png')}
                                        alt="Default"
                                        className="default-image"
                                    />
                                )}
                            </div>
                            <div className="image-input" onClick={() => fileInputRef.current.click()}>
                                <span className="icon">📷</span>
                                <span className="text">Upload Image (PNG, JPG or GIF)</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add-block-btn">
                    <button className="btn publish-btn" onClick={addHeaderImageData}>
                        Save
                    </button>
                </div>
              
            </div>
        </>
    );
}
