
export function eventsDataReducer(state, action) {
    switch (action.type) {
        case 'SET_EVENTS':
            return {
                ...state,
                events: [...action.payload],
            }

        case 'FETCHING_EVENTS':
            return {
                ...state,
                isFetching: action.payload,
        }

        default:

            return state

    }
}
