// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './assets/Css/Style.css';
import './assets/Css/darktheam.css';
import './Components/DataTable OffTime/table.css'
import 'react-tooltip/dist/react-tooltip.css'
import reportWebVitals from './reportWebVitals';
import ParentProvider from './ParentProvider';
import MainRouter from './Router/MainRouter';
import AstralAIButton from './Components/AstralAi/AstralAIButton';
import VedankChatbot from './Components/AstralAi/VedankChatbot';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <ParentProvider>
        <MainRouter />
        {/* <AstralAIButton /> */}
        {/* <VedankChatbot /> */}
      </ParentProvider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
