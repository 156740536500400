import { apiCall2 } from "./auth.js";
import { API_URL } from "./config.js";
import {multipartApiCall} from "./auth";


export const applyEvent = (formData) => apiCall2(`${API_URL}event/applyEvent`, "POST", formData);

// setting
export const saveSetting = (formData) => apiCall2(`${API_URL}event/saveSetting`, "POST", formData);
export const getSettings = (formData) => apiCall2(`${API_URL}event/getSettings`, "POST", formData);
export const contactToHost = (formData) => apiCall2(`${API_URL}event/contactToHost`, "POST", formData);
export const reportEvent = (formData) => apiCall2(`${API_URL}event/reportEvent`, "POST", formData);

//ceventscities
export const getEventsCities = (formData) => apiCall2(`${API_URL}event/getEventsCities`, "POST", formData);
export const getCityEvents = (formData) => apiCall2(`${API_URL}event/getCityEvents`, "POST", formData);


export const getBrandsEvents = (formData) => apiCall2(`${API_URL}event/getBrandsEvents`, "POST", formData);
export const getMembersByEventId = (formData) => apiCall2(`${API_URL}event/getMembersByEventId`, "POST", formData);
export const getEventsByCategory = (formData) => apiCall2(`${API_URL}event/getEventsByCategory`, "POST", formData);
export const publishMinipage = (data) => multipartApiCall(`${API_URL}event/pblishminipage`, "POST", data);
