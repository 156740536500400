// import React from "react";
// import { GoogleMap, useLoadScript } from "@react-google-maps/api";

// const SimpleMap = () => {
//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: "AIzaSyAt5m5uNR4aEfvr9Bq2k3a5rDHg9IT1ml0", // Replace with your API key
//   });

//   if (loadError) return <div>Error loading maps</div>;
//   if (!isLoaded) return <div>Loading...</div>;

//   return (
//     <GoogleMap
//       mapContainerStyle={{
//         width: "100%",
//         height: "100vh",
//       }}
//       center={{
//         lat: -34.397,
//         lng: 150.644,
//       }}
//       zoom={8}
//     ></GoogleMap>
//   );
// };

// export default SimpleMap;



import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import useEventsDataActions from "../../contexts/actions/useEventsDataActions";
import { eventsDataContext } from '../../contexts/context/eventsDataContext';
import { use } from 'react';

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

const SimpleMap = () => {

  const { getNearbyEvents } = useEventsDataActions();
  const { eventsData } = React.useContext(eventsDataContext);


  const [currentLocation, setCurrentLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [eventMarkers, setEventMarkers] = useState([]); // State for additional markers


  const { isLoaded, loadError } = useLoadScript({
    // googleMapsApiKey: "AIzaSyAt5m5uNR4aEfvr9Bq2k3a5rDHg9IT1ml0", // Replace with your actual API key
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY, // Replace with your actual API key

  });

  useEffect(() => {
    if (eventsData.events.length > 0) {
      const markers = eventsData.events.map((event) => {

        const pageJson = JSON.parse(event.page_json);

        const eventProperties = pageJson.find(item => item.type === "EVENT").properties;

        const title = eventProperties.eventTitle;

        return {
          lat: event.lat,
          lng: event.lng,
          title: title,
        }

      });
      setEventMarkers(markers);
    }
  }, [eventsData]);

  // Function to get current location
  const getCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const location = {
            lat: latitude,
            lng: longitude
          };

          getNearbyEvents(location.lat, location.lng);

          setCurrentLocation(location);
          setMapCenter(location);
        },
        (error) => {
          console.error("Error getting location:", error);
          // Fallback to a default location if geolocation fails
          setMapCenter({
            lat: -34.397,
            lng: 150.644
          });
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      // Fallback to a default location
      setMapCenter({
        lat: -34.397,
        lng: 150.644
      });
    }
  }, []);

  // Get location when component mounts
  useEffect(() => {
    getCurrentLocation();
  }, [getCurrentLocation]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={15}
      >
        {currentLocation && (
          <Marker
            position={currentLocation}
            title="Your Current Location"
          />
        )}
        {eventMarkers.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            title={marker.title}
            // label={marker.title} // Set the title as a label
            // label={{
            //   text: marker.title, // Set the title as a label
            //   color: "purple",      // Label text color
            //   fontSize: "16px",    // Font size for the label
            //   fontWeight: "bold",  // Font weight for the label (optional)
            //     // Border radius for rounded corners (optional)
            // }}
            icon={{
              // path: './public/eventicon.png',
              url: '/eventicon3.png',
              scaledSize: new window.google.maps.Size(35, 35), // Adjust size (width: 30px, height: 30px)
              // anchor: new window.google.maps.Point(30, 30),
              // scale: 0.1,
              // fillColor: "#4285F4",
              // fillOpacity: 0.8,
              // strokeWeight: 2,
              // strokeColor: "white"
            }}
          />
        ))}
      </GoogleMap>
      {/* <button
        onClick={getCurrentLocation}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 10
        }}
      >
        Refresh Location
      </button> */}
    </div>
  );
};

export default SimpleMap;