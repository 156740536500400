import React, { useState, useEffect } from 'react';
import { MdLocationOn } from 'react-icons/md';

const LocationDisplay = ({ type, eventData }) => {
    const [eventType, setEventType] = useState('');
    const [location, setLocation] = useState('');

    const isValidURL = (str) => {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // Protocol
            '((([a-zA-Z0-9$-_@.&+!*"(),]|[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})|' + // Domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IPv4
            '(\\:\\d+)?(\\/[-a-zA-Z0-9@:%._+~#=]*)*$', // Port and path
            'i'
        );
        return pattern.test(str);
    };

    const eventTypeMapping = {
        'zoom.us': 'Zoom',
        'meet.google.com': 'Google Meet',
        'teams.microsoft.com': 'Microsoft Teams',
        'facetime.apple.com': 'Apple Facetime',
    };

    const getEventType = (url) => {
        for (const [key, value] of Object.entries(eventTypeMapping)) {
            if (url.includes(key)) return value;
        }
        return '[URL]';
    };

    useEffect(() => {
        if (eventData?.locationType === 'Virtual' && isValidURL(eventData?.location)) {
            setLocation(eventData.location);
            setEventType(getEventType(eventData.location));
        }
        else if (eventData?.locationType === 'Physical') {
            setLocation(eventData.location || '');
            setEventType('');
        }
        else {
            setLocation(eventData.location);
            setEventType('');
        }
    }, [eventData]);

    const renderEventIcon = () => {
        const icons = {
            Zoom: <ZoomIcon />,
            'Google Meet': <GoogleMeetIcon />,
            'Microsoft Teams': <TeamsIcon />,
            'Apple Facetime': <FacetimeIcon />,
        };
        return icons[eventType] || null;
    };

    const googleMapsLink = location
        ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`
        : null;

    return (
        <div className="location-type d-flex align-items-center gap-2">
            {(eventData?.locationType === 'Virtual' && type !== 'showUrls') && eventType ? (
                <>
                    {renderEventIcon()}
                    <span className="light-txt text-ellipsis">{eventType}</span>
                </>
            ) : (eventData?.locationType === 'Physical' || eventData.locationType !== 'Virtual') ? (
                <div className="d-flex align-items-center gap-2">
                    <MdLocationOn />
                        <span className="light-txt text-ellipsis" >{location}</span>
                </div>
            ) : (
                        (type == 'showUrls' && eventData?.locationType == 'Virtual') &&
                        (
                    <div className="event-location-sec mt-2">
                        <div className="event-date-inner">
                            <div className="date-icon d-flex align-items-center justify-content-center">
                                {renderEventIcon()}
                            </div>
                            <div className="event-date-text">
                                <div className="event-date">
                                    <a
                                        href={googleMapsLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: 'inherit', textDecoration: 'none' }} // Ensure link doesn't turn blue or underlined
                                    >
                                        {location}
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>

                )
            )}
        </div>
    );
};

// Icon Components (reusable SVGs)
const ZoomIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ width: '20px', height: '20px' }}>
        <rect x="2" y="4" width="16" height="12" rx="2" ry="2" />
        <path d="M22 7l-5 3 5 3V7z" />
    </svg>
);

const GoogleMeetIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ width: '20px', height: '20px' }}>
        <path d="M16 10l-4 4 4 4m-5-8l-4 4 4 4" />
        <rect x="4" y="4" width="16" height="16" rx="2" ry="2" />
    </svg>
);

const TeamsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ width: '20px', height: '20px' }}>
        <path d="M7 9h10v6H7z" />
        <path d="M22 7l-3 2V5a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v4l-3-2v8l3-2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-4l3 2V7z" />
    </svg>
);

const FacetimeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ width: '20px', height: '20px' }}>
        <rect x="2" y="4" width="16" height="16" rx="2" ry="2" />
        <path d="M22 7l-5 3 5 3V7z" />
    </svg>
);

const PhysicalLocationIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ width: '20px', height: '20px' }}>
        <path d="M12 2L2 7l10 5 10-5-10-5z" />
        <path d="M2 17l10 5 10-5" />
        <path d="M2 12l10 5 10-5" />
    </svg>
);

export default LocationDisplay;
