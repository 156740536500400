import React, { useEffect, useState } from 'react'
import DiscoverEventsNav from '../../Components/DiscoverEventsNav/DiscoverEventsNav'
import { CiCalendar } from 'react-icons/ci'
import { SlPeople } from "react-icons/sl";
import { TiArrowRight } from "react-icons/ti";
import { getEventsByCategory } from '../../BackendApi/Api/Event';
import { useParams } from 'react-router-dom';
import { API_URL } from "../../BackendApi/Api/config";
import TimeLine from '../../Components/TimeLine/TimeLine';
import { IoMdAdd } from 'react-icons/io'; 

function CategoricalEvents() {
    const [eventData, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState({});
    const { category_slug } = useParams();
    const getCategoriesdata = async (category_slug) => {
        try {
            const result = await getEventsByCategory({ category_slug });
            if (result.data.status) {
                setCategory(result.data.categories);
                setEvents(result.data.data);
                setLoading(false);
            } else {
                console.error(result.data.message || "Unknown error occurred");
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    }

    useEffect(() => {
        if (category_slug) {
            getCategoriesdata(category_slug)
        }
    }, [category_slug]);
    console.log("cityData", eventData);

    return (
        <div className='categorical-events-climate categorical-events'>
            <DiscoverEventsNav />
            <div className="container">
                <div className="row upper-section d-flex align-items-center py-5">
                    <div className="col-lg-6 left-sec">
                        <div className="header py-2">
                            <h1 className='mb-4 pt-5'>{category?.category_name}</h1>
                            <div className="d-flex align-items-center gap-3">
                                <span className='d-flex align-items-center'>
                                    <CiCalendar className='me-2' />
                                    <span>{category?.totalsCategoryEvents} <span className='light-txt'>Events</span></span>
                                </span>
                                {/* <span className='d-flex align-items-center'>
                                    <SlPeople className='me-2' />
                                    <span>4K <span className='light-txt'>Subscribers</span></span>
                                </span> */}
                            </div>
                        </div>
                        <hr className='divide-hr' />
                        <div className="header-text py-2"> {category?.category_description ? category?.category_description: ` A complete guide to the most important ${category?.category_name} gatherings around the world. Learn about the latest ${category?.category_name} events, learn about zk and layer-2s, and immerse yourself in the world of web3.`}

                        </div>
                        {/* <div className="city-email d-flex align-items-center py-4">
                            <input type="email" className="e-mail custom-placeholder me-3" placeholder="you@gmail.com" id="email" style={{ backgroundColor: 'rgba(255, 255, 255, 0.08', borderRadius: '100px', padding: '10px 34px', border: 'none', outline: 'none', fontSize: '16px' }} />
                            <a style={{ backgroundColor: 'rgba(255, 255, 255, 0.9', borderRadius: '100px', padding: '10px 14px', color: 'black', fontSize: '16px', fontWeight: '600' }}>Subscribe</a>
                        </div> */}
                    </div>
                    <div className="col-lg-6 right-sec d-flex justify-content-end">
                        <div className="header-img">
                            <img src={category?.banner_image ? `${API_URL}eventcategory/${category?.banner_image}` : "https://images.lumacdn.com/discovery/crypto-square.png"} alt="" />
                        </div>
                    </div>
                </div>
                <hr className='divide-hr' />
                <div className="lower-section py-5">
                    
                   
                    <section className='city-events-sec'>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-9 ">
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div>
                                            <h3>Upcoming Major Events</h3>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                            <a href="#" className="city-events-link d-flex align-items-center light-bg-btn"><IoMdAdd className='me-1' />Submit Event</a>
                                            <div className='d-flex align-items-center light-bg-btn small-btn'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"><path d="M4 11a9 9 0 0 1 9 9M4 4a16 16 0 0 1 16 16"></path><circle cx="5" cy="19" r="1"></circle></svg>
                                            </div>
                                            <div className="d-flex align-items-center light-bg-btn small-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="M12 7A5 5 0 1 1 2 7a5 5 0 0 1 10 0m-.965 5.096a6.5 6.5 0 1 1 1.06-1.06l2.935 2.934a.75.75 0 1 1-1.06 1.06z"></path></svg>
                                            </div>
                                        </div>
                                    </div>
                                    <TimeLine type="category" eventData={eventData} />

                                </div>
                                <div className="col-lg-3">
                                    <div className="city-events-search">
                                        <input type="search" placeholder='Search Events' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default CategoricalEvents