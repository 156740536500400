import React, { useState } from 'react';
import ReactQuill from 'react-quill'; // Import Rich Text Editor
import 'react-quill/dist/quill.snow.css'; // Import styles for the editor
const GeneralBlocks = () => {
    const [uploadedImage, setUploadedImage] = useState(null);

    // Handle image upload
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file); // Create a temporary URL for the uploaded image
            setUploadedImage(imageUrl);
        }
    };

    // Handle image delete
    const handleImageDelete = () => {
        setUploadedImage(null); // Clear the uploaded image
    };

    const [aboutContent, setAboutContent] = useState('');
    const [agendaContent, setAgendaContent] = useState('');
    const [parkingContent, setParkingContent] = useState('');

    // Mock BrandKit Templates
    const templates = [
        { name: 'About Template', text: 'Welcome to our event. This is a short description.' },
        { name: 'Agenda Template', text: '10:00 AM - Welcome Speech\n11:00 AM - Keynote' },
        { name: 'Parking Template', text: 'Parking is available near the venue. Follow signs for directions.' },
    ];

    // State for selected template
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    // Apply selected template to a block
    const applyTemplate = (blockSetter) => {
        if (selectedTemplate) {
            blockSetter(selectedTemplate.text);
        }
    };

    return (
        <div style={styles.pageContainer}>
            {/* Header Block */}
            <header style={styles.header}>
                <h1>Annual Tech Meetup 2024</h1>
                <p>Join us for a day of innovation, networking, and insights into the tech industry.</p>
            </header>

            {/* Image Upload Block */}
            <div style={styles.imageBlock}>
                {uploadedImage ? (
                    <>
                        <img src={uploadedImage} alt="Uploaded" style={styles.image} />
                        <button style={styles.button} onClick={handleImageDelete}>
                            Delete Image
                        </button>
                    </>
                ) : (
                    <div style={styles.uploadContainer}>
                        <label htmlFor="imageUpload" style={styles.uploadLabel}>
                            Click to Upload Image
                        </label>
                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            style={styles.uploadInput}
                            onChange={handleImageUpload}
                        />
                    </div>
                )}
            </div>

            {/* Text Blocks */}
            {/* BrandKit Template Selector */}
            <div style={styles.templateSelector}>
                <h3>BrandKit Templates</h3>
                <div style={styles.templateList}>
                    {templates.map((template, index) => (
                        <div
                            key={index}
                            style={styles.templateCard}
                            onClick={() => setSelectedTemplate(template)}
                        >
                            <h4>{template.name}</h4>
                            <p>{template.text.substring(0, 50)}...</p>
                        </div>
                    ))}
                </div>
                {selectedTemplate && (
                    <div style={styles.applyTemplate}>
                        <p>Selected Template: {selectedTemplate.name}</p>
                    </div>
                )}
            </div>

            {/* Text Blocks with Rich Text Editor */}
            <div style={styles.textBlocks}>
                {/* About Section */}
                <section style={styles.textBlock}>
                    <h2>About</h2>
                    <ReactQuill
                        value={aboutContent}
                        onChange={setAboutContent}
                        style={styles.richTextEditor}
                    />
                    <button onClick={() => applyTemplate(setAboutContent)} style={styles.button}>
                        Apply Template
                    </button>
                </section>

                {/* Agenda Section */}
                <section style={styles.textBlock}>
                    <h2>Agenda</h2>
                    <ReactQuill
                        value={agendaContent}
                        onChange={setAgendaContent}
                        style={styles.richTextEditor}
                    />
                    <button onClick={() => applyTemplate(setAgendaContent)} style={styles.button}>
                        Apply Template
                    </button>
                </section>

                {/* Parking Section */}
                <section style={styles.textBlock}>
                    <h2>Parking</h2>
                    <ReactQuill
                        value={parkingContent}
                        onChange={setParkingContent}
                        style={styles.richTextEditor}
                    />
                    <button onClick={() => applyTemplate(setParkingContent)} style={styles.button}>
                        Apply Template
                    </button>
                </section>
        </div>
        </div>
    );
};

// CSS-in-JS styles
const styles = {
    pageContainer: {
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        lineHeight: '1.6',
        color: '#333',
    },
    header: {
        textAlign: 'center',
        backgroundColor: '#f4f4f4',
        padding: '20px',
        borderRadius: '8px',
    },
    imageBlock: {
        textAlign: 'center',
        margin: '20px 0',
    },
    image: {
        width: '100%',
        maxWidth: '600px',
        height: 'auto',
        borderRadius: '8px',
        marginBottom: '10px',
    },
    uploadContainer: {
        border: '2px dashed #ccc',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        cursor: 'pointer',
    },
    uploadLabel: {
        color: '#007bff',
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    uploadInput: {
        display: 'none',
    },
    button: {
        backgroundColor: '#ff4d4d',
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '8px',
        cursor: 'pointer',
        marginTop: '10px',
    },
    textBlocks: {
        marginTop: '20px',
    },
    textBlock: {
        backgroundColor: '#fff',
        padding: '15px',
        borderRadius: '8px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        marginBottom: '20px',
    },

    pageContainer: {
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        lineHeight: '1.6',
        color: '#333',
    },
    header: {
        textAlign: 'center',
        backgroundColor: '#f4f4f4',
        padding: '20px',
        borderRadius: '8px',
    },
    templateSelector: {
        backgroundColor: '#f9f9f9',
        padding: '20px',
        borderRadius: '8px',
        marginBottom: '20px',
    },
    templateList: {
        display: 'flex',
        gap: '10px',
        overflowX: 'auto',
    },
    templateCard: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '10px',
        minWidth: '200px',
        cursor: 'pointer',
    },
    applyTemplate: {
        marginTop: '10px',
    },
    textBlocks: {
        marginTop: '20px',
    },
    textBlock: {
        backgroundColor: '#fff',
        padding: '15px',
        borderRadius: '8px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        marginBottom: '20px',
    },
    richTextEditor: {
        height: '150px',
        marginBottom: '10px',
    },
    button: {
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '8px',
        cursor: 'pointer',
    },
};

export default GeneralBlocks;
